(function applicantTabsActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantTabsActionsController', ApplicantTabsActionsController);

    /**
     * A controller for handling tabs navigation of the applicant page.
     *
     * NOTE: this controller is supposed to work with 1.1.2 version of angular-bootstrap,
     * because newer versions change API for working with tabs. Upgrade of the
     * angular-bootstrap module might require rewriting of this controller.
     */
    function ApplicantTabsActionsController($rootScope, $stateParams, $location, $state, $timeout, $scope) {
        var vm = this;
        var currentTab = null;

        vm.tabsStatus = {};
        vm.tabsList = ['resume', 'interactions', 'experience', 'education', 'skills', 'info', 'interviews', 'history'];
        vm.activeTabIndex = 0;
        // vm.getActiveTab = getActiveTab;
        vm.onTabSelect = onTabSelect;
        $rootScope.$on('$locationChangeSuccess', locationChangeSuccess);

        activate();

        function locationChangeSuccess() {
            activate();
        }

        function activate() {
            currentTab = $stateParams.tab || 'resume';
            vm.tabsStatus[currentTab] = true;
            vm.activeTabIndex = setActiveTabIndex(currentTab);
        }

        function setActiveTabIndex(tabName) {
            return vm.tabsList.findIndex(function (item) {
                return item === tabName;
            });
        }

        function onTabSelect(tab) {
            if (currentTab === tab) {
                return;
            }
            //$location.replace();

            // $location.search('tab', tab) causes problems
            // with navigation to the calendar
            $state.go('.', { tab: tab });

            currentTab = tab;
            vm.activeTabIndex = setActiveTabIndex(currentTab);

            // Wait until DOM is updated
            $timeout(adjustElasticTextareas);

            $scope.$broadcast('applicant-tabs:tab-selected', tab);
        }

        function adjustElasticTextareas() {
            $scope.$broadcast('elastic:adjust');
        }
    }
})();