(function testTasksListReportControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('TestTasksListReportController', TestTasksListReportController);

    function TestTasksListReportController(dateStart, dateEnd, data, $state, dateFilter) {
        var now = null;
        var vm = this;

        vm.STATUSES = [
            'Отправлено',
            'На проверке',
            'Проверено'
        ];

        vm.data = null;
        vm.dates = null;

        vm.build = build;

        activate();

        function activate() {
            now = new Date();
            var sessionStartDate = sessionStorage.getItem('startDate');
            var sessionEndDate = sessionStorage.getItem('endDate');

            vm.data = data.data;
            for (var i = vm.data.length - 1; i >= 0; i--) {
                vm.data[i].history.sort(historyComparator);
            }

            vm.dates = {
                start: sessionStartDate ? new Date(sessionStartDate) : dateStart,
                end: sessionEndDate ? new Date(sessionEndDate) : dateEnd
            };
        }

        function build() {
            sessionStorage.setItem('startDate', vm.dates.start);
            sessionStorage.setItem('endDate', vm.dates.end);

            $state.go('report_test_tasks_list', {
                start: dateFilter(new Date(sessionStorage.getItem('startDate')), 'yyyy-MM-dd'),
                end: dateFilter(new Date(sessionStorage.getItem('endDate')), 'yyyy-MM-dd'),
            }, { reload: true });
        }

        function historyComparator(fst, snd) {
            var dateFst = new Date(fst.date_create);
            var dateSnd = new Date(snd.date_create);
            return dateSnd.getTime() - dateFst.getTime();
        }
    }
})();
