(function applicantInterviewActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantInterviewActionsController', ApplicantInterviewActionsController);

    /**
     * A controller which is responsible for displaying a list of interviews of
     * an applicant
     */
    function ApplicantInterviewActionsController($scope, InteractionsApi, SkillsApi, Notify, ROOMS) {
        var vm = this;
        var mainVM = null;

        vm.interviews = [];
        vm.vacancies = [];
        vm.masters = [];
        vm.skills = [];
        vm.rooms = ROOMS;

        activate();

        $scope.$watch('applicantVM.model', onModelChanged);
        $scope.$watch('applicantVM.model.new_interactions.length', onInteractionsChanged);

        function activate() {
            mainVM = $scope.applicantVM;

            loadSkills();
            mainVM.loadVacancies().then(onVacanciesLoadSuccess);
            mainVM.loadMasters().then(onMastersLoadSuccess);

            vm.interviews = getInterviews();
        }

        function onMastersLoadSuccess(response) {
            vm.masters = response.data;
        }

        function onVacanciesLoadSuccess(response) {
            vm.vacancies = response.data;
        }

        function loadSkills() {
            SkillsApi
                .getList()
                .then(onSkillsLoadSuccess, onSkillsLoadError);
        }

        function getInterviews() {
            return mainVM.model.new_interactions.filter(interviewFilter);
        }

        function onSkillsLoadSuccess(response) {
            vm.skills = response.data;
        }

        function onSkillsLoadError(response) {
            Notify.error('Ошибка', 'Не удалось загрузить список навыков');
        }

        function onInteractionsChanged(newValue, oldValue, scope) {
            if (newValue === oldValue) {
                return;
            }

            // Update the list of interviews if an amount of interviews is changed
            var interviews = getInterviews();
            if (interviews.length !== vm.interviews.length) {
                vm.interviews = interviews;
            }
        }

        function onModelChanged(newValue, oldValue, scope) {
            if (newValue === oldValue) {
                return;
            }

            // Update the list of interviews if the model is changed
            vm.interviews = getInterviews();
        }

        function interviewFilter(element, index, array) {
            return element.type === InteractionsApi.TYPE_INTERVIEW;
        }
    }
})();
