(function notIgnoredVacanciesFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('notIgnoredVacancies', notIgnoredVacancies);

    /**
     * A filter for removing from array all vacancies whose id is present in a
     * list of ignored vacancies. It is possible to keep an ignored vacancy in a
     * list by passing its id in an array of allowed items.
     *
     * It is needed, for example, to hide from drop down lists placeholder
     * vacancies, which were added for repairing data consistency during project
     * migration.
     */
    function notIgnoredVacancies(IGNORE_VACANCIES, notIgnoredItemsFilter) {
        return function filter(input, allowedItems) {
            return notIgnoredItemsFilter(input, IGNORE_VACANCIES, allowedItems);
        };
    }
})();
