(function applicantSkillsModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApplicantSkillsModal', ApplicantSkillsModal);

    /**
     * A service for displaying a modal window with skills of the given
     * applicant. It is needed to prevent possible code duplication and
     * encapsulate parameters of the modal window.
     */
    function ApplicantSkillsModal($uibModal) {
        this.open = function open(applicant) {
            return $uibModal.open({
                templateUrl: 'hrpro/app/applicants/modals/applicant-details/skills-modal.html',
                controller: 'ApplicantDetailsModalController',
                controllerAs: 'modal',
                resolve: {
                    applicant: function resolveApplicant() {
                        return applicant;
                    }
                },
                keyboard: false
            }).result;
        };
    }
})();
