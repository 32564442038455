(function calendarWeekControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('CalendarWeekController', CalendarWeekController);

    /**
     * A controller to handle a page with a calendar for one week
     */
    function CalendarWeekController(
        calendar, $state, $stateParams, CalendarHelper, dateFilter, CalendarApi, InteractionsApi, $scope
    ) {
        var vm = this;
        var initialDate = null;

        vm.date = null;
        vm.weekFirstDate = null;
        vm.weekLastDate = null;
        vm.weekDates = null;
        vm.notification = null;
        vm.matrix = null;
        vm.interviews = [];

        vm.getRoom = getRoom;
        vm.nextWeek = nextWeek;
        vm.previousWeek = previousWeek;
        vm.isActiveWeek = isActiveWeek;

        vm.updateWeek = updateWeek;

        activate();

        function activate() {
            vm.date = new Date();
            if ($stateParams.date) {
                vm.date = new Date($stateParams.date);
            }

            // Notify CalendarController about a new date
            $scope.$emit('calendar:date', vm.date);

            vm.weekFirstDate = CalendarHelper.getWeekFirstDate(vm.date);
            vm.weekLastDate = CalendarHelper.getWeekLastDate(vm.date);
            vm.weekDates = getWeekDates();

            initialDate = new Date(vm.date.getTime());

            vm.notification = calendar.data.notification;

            var interviews = calendar.data.notification.filter(interviewFilter);
            vm.interviews = interviews.filter(not(interviewHeldFilter));
            vm.interviewMatrix = CalendarHelper.getFilledTimeMatrix(vm.interviews, vm.weekDates);
            vm.interviewMatrix = transposeMatrix(vm.interviewMatrix);
            vm.interviewMatrix = splitInterviewRooms(vm.interviewMatrix);

            var interactions = calendar.data.notification.filter(not(interviewFilter));
            vm.matrix = CalendarHelper.getFilledTimeMatrix(interactions, vm.weekDates, true, true);
            vm.matrix = transposeMatrix(vm.matrix);

            vm.interviewsHeld = interviews.filter(interviewHeldFilter);
            vm.interviewsHeld = CalendarHelper.getFilledTimeMatrix(vm.interviewsHeld, vm.weekDates, true, true);
            vm.interviewsHeld = transposeMatrix(vm.interviewsHeld);
        }

        function getWeekDates() {
            var date;
            var dates = [];
            for (var i = 0; i < 5; i++) {
                date = new Date(vm.weekFirstDate.getTime());
                date.setDate(date.getDate() + i);
                dates.push(date);
            }
            return dates;
        }
        function isActiveWeek(date, mode) {
            if (mode !== 'day') {
                return false;
            }

            var currentWeek = CalendarHelper.getWeekNumber(initialDate);
            var targetWeek = CalendarHelper.getWeekNumber(date);

            return currentWeek === targetWeek;
        }

        function updateWeek() {
            if (isActiveWeek(vm.date, 'day')) {
                // Notify CalendarController about a new date
                $scope.$emit('calendar:date', vm.date);
                return;
            }

            $state.go('calendar_week', { date: dateFilter(vm.date, 'yyyy-MM-dd') }, { location: 'replace' });
        }

        function getRoom(row) {
            var matchedDates = row.filter(function(x) { return x.interaction !== null;});
            if(matchedDates.length === 0) {
                return null;
            }
            return matchedDates[0].interaction.room.title;
        }

        function nextWeek() {
            vm.date.setDate(vm.date.getDate() + 7);
            updateWeek();
        }

        function previousWeek() {
            vm.date.setDate(vm.date.getDate() - 7);
            updateWeek();
        }

        function interviewFilter(interaction, index, array) {
            return interaction.type === InteractionsApi.TYPE_INTERVIEW;
        }

        function transposeMatrix(matrix) {
            var m = matrix.length,
                n = matrix[0].length,
                matrixT = [];
            for (var i = 0; i < n; i++) {
                matrixT[i] = [];
                for (var j = 0; j < m; j++) {
                    matrixT[i][j] = matrix[j][i];
                }
            }
            return matrixT;
        }

        function splitInterviewRooms(matrix) {
            var m = matrix.length,
                n = matrix[0].length,
                matrixT = [];
            // i - input hours, k - output hours with room
            for(var i=0, k=0; i < m; i++) {
                // Collect room variants
                var rooms = {};
                var kMin = k;
                for(var j = 0; j<n; j++) { // j - week date
                    var interaction = matrix[i][j].interaction;
                    if(interaction !== null && !rooms.hasOwnProperty(interaction.room.id)) {
                        rooms[interaction.room.id] = k++;
                    }
                }
                if(kMin === k) {
                    rooms[null] = k++;
                }
                for(var room in rooms) {
                    var kRoom = rooms[room];
                    var t = matrixT[kRoom] = [];
                    for(j=0; j < n; j++) { // j - week date
                        var cell = t[j] = matrix[i][j];
                        if(cell.interaction !== null && room !== cell.interaction.room.id.toString()) {
                            t[j] = Object.create(t[j]);
                            t[j].interaction = null;
                        }
                    }
                }
            }
            return matrixT;
        }

        function not(func) {
            return function backward(item) {
                return !func(item);
            };
        }
        function interviewHeldFilter(interview) {
            return interview.held;
        }
    }
})();
