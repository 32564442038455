(function searchChannelsApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('SearchChannelsApi', SearchChannelsApi);

    /**
     * Provides an ability to interact with the tags related endpoints on the API
     */
    function SearchChannelsApi(ApiHelper, $http) {
        this.getList = getList;

        function getList(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('search_channels'),
                context: context
            };
            return $http(config);
        }
    }
})();
