(function authServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('AuthService', AuthService);

    var STORAGE_KEY = 'AuthService:authData';
    var LONG_TERM_DELAY = 30 * 24 * 60 * 60;

    /**
     * A service which handles authentication on the API endpoint
     */
    function AuthService(AuthApi, $cookies) {
        this.clearData = clearData;
        this.getAuthData = getAuthData;
        this.isAuthenticated = isAuthenticated;
        this.login = login;
        this.logout = logout;

        /**
         * Performs login procedure for the given credentials. This method
         * starts a new authentication attempt and will clear all previously
         * saved data, if any.
         * @param  {String} username username of the user
         * @param  {String} password password of the user
         * @param  {Boolean} remember whether to save received access token
         * @return {Promise} a promise which will be resolved when login is done
         */
        function login(username, password, remember) {
            clearData();
            return AuthApi
                .login(username, password, {
                    remember: remember
                })
                .then(onLoginSuccess);
        }

        function onLoginSuccess(response) {
            var authData = response.data;
            var context = response.config.context;

            saveData(authData, context.remember);

            return response;
        }

        /**
         * Performs logout procedure and clears saved access token data.
         */
        function logout() {
            clearData();
            AuthApi.logout();
        }

        /**
         * Check whether the current user is authenticated on the server. True
         * means that the user was successfully authenticated either in the
         * current session or the access token was saved in a previous session.
         * @return {Boolean} an authentication status of the current user
         */
        function isAuthenticated() {
            var data = getAuthData();
            return data !== undefined;
        }

        /**
         * Returns the authentication data retrieved from the server
         * @return {Object} authentication data
         */
        function getAuthData() {
            var data = loadData();
            return data;
        }

        /**
         * Clears authentication data retrieved from the server. Also clears
         * a storage if any data was saved there.
         */
        function clearData() {
            $cookies.remove(STORAGE_KEY);
        }

        /**
         * Saves data to storage
         * @param {Object} data data to save
         * @param {Boolean} longTerm whether to save data for a long term
         */
        function saveData(data, longTerm) {
            var options;

            if (longTerm) {
                var expirationDate = new Date();
                expirationDate.setSeconds(expirationDate.getSeconds() + LONG_TERM_DELAY);
                options = { expires: expirationDate };
            }

            $cookies.putObject(STORAGE_KEY, data, options);
        }

        /**
         * Tries to load previously saved data from storage
         * @return {Object or null}
         */
        function loadData() {
            var data = $cookies.getObject(STORAGE_KEY);
            return data;
        }
    }
})();
