(function calendarHelperIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('CalendarHelper', CalendarHelper);

    var CALENDAR_MIN_HOUR = 8;
    var CALENDAR_MAX_HOUR = 17;
    var CALENDAR_MINUTE_POINTS = [ 0, 30 ];

    var MILISECS_IN_DAY = 86400000;

    function ScheduledInteraction(date, time, interaction) {
        this.date = date;
        this.time = time;
        this.interaction = interaction;
    }

    function getTimeString(hour, minute) {
        return (hour < 10 ? '0' + hour : hour) + ':' + (minute < 10 ? '0' + minute : minute);
    }

    /**
     * A helpers that contains common logic related to a calendar
     */
    function CalendarHelper(dateFilter) {
        this.ScheduledInteraction = ScheduledInteraction;

        this.getTimeArray = getTimeArray;
        this.getTimeSpan = getTimeSpan;
        this.getFilledTimeArray = getFilledTimeArray;
        this.getFilledTimeMatrix = getFilledTimeMatrix;
        this.getWeekFirstDate = getWeekFirstDate;
        this.getWeekLastDate = getWeekLastDate;
        this.getWeekNumber = getWeekNumber;
        this.linkGoogleEvents = linkGoogleEvents;

        function getTimeArray() {
            var time = [];
            var hour, i, len;
            for (hour = CALENDAR_MIN_HOUR; hour <= CALENDAR_MAX_HOUR; hour++) {
                for (i = 0, len = CALENDAR_MINUTE_POINTS.length; i < len; i++) {
                    time.push(new ScheduledInteraction(hour, CALENDAR_MINUTE_POINTS[i], null));
                }
            }
            return time;
        }

        /**
         * Get time slots convered by time range in working hours
         * @param start {Date} time before
         * @param end {Date}
         * @returns {Array}
         */
        function getTimeSpan(start, end) {
            var time = [];
            var hour, i, len, min;
            var startMin = start.getUTCHours() * 60 + start.getUTCMinutes();
            var endMin = end.getUTCHours() * 60 + end.getUTCMinutes();
            console.log('Ranging in:', startMin, endMin);
            for (hour = CALENDAR_MIN_HOUR; hour <= CALENDAR_MAX_HOUR; hour++) {
                for (i = 0, len = CALENDAR_MINUTE_POINTS.length; i < len; i++) {
                    min = hour * 60 + CALENDAR_MINUTE_POINTS[i];
                    if(min < startMin || min >= endMin)
                        continue;
                    time.push(new ScheduledInteraction(hour, CALENDAR_MINUTE_POINTS[i], null));
                }
            }
            return time;
        }

        /**
         * Constructs a schedule for the given list of interactions. All
         * interactions have to belong to the same date.
         * @param  {Interaction[]} interactions an array of interactions
         * @param  {Date} date a target date to build schedule
         * @return {ScheduledInteraction[]} an ordered by list of scheduled
         *   interactions (time gaps are filled with empty interactions).
         *   Sorting is performed by time in ascending order. The returned
         *   array looks like this:
         *   [
         *       object{date:String, time:String, interaction:Interaction}
         *   ]
         */
        function getFilledTimeArray(interactions, date) {
            return getFilledTimeMatrix(interactions, [date])[0];
        }

        /**
         * Construct a schedule for the given list of interactions. This method
         * constructs a matrix, so it allows interactions to belong to
         * different dates.
         * @param  {Interaction[]} interactions an array of interactions
         * @param  {Date[]} dates an array of target dates to build matrix
         * @param  {boolean} skipEmptyTime whether empty time row has to be
         *  skipped or filled with empty interactions
         * @param  {boolean} useUnfixedTime whether time points have to be
         *  fixed or generated based on time of interactions
         * @return {ScheduledInteraction[][]} an ordered matrix of scheduled
         *   interactions. An order of dates in the first dimension is the same
         *   as order of these dates in the given dates array. Sorting of the
         *   second dimension is performed by time in ascending order. The
         *   returned array looks like this:
         *   [
         *       [
         *           object:{date:String, time:String, interaction:Interaction}
         *       ]
         *   ]
         */
        function getFilledTimeMatrix(interactions, dates, skipEmptyTime, useUnfixedTime) {
            if (dates.length === 0) {
                return [];
            }

            var FORMAT_DATE = 'yyyy-MM-dd';
            var FORMAT_TIME = 'HH:mm';
            var i, ilen, j, jlen, k, klen;

            /**
             * Holds information about scheduled interactions. This object has
             * the following structure:
             * {
             *     '2016-01-01': {
             *         '08:30': [
             *             object: ScheduledInteraction
             *         ]
             *     }
             * }
             */
            var schedule = {};

            // Determine target dates for the schedule
            var dateKeys = [];
            var dateKey, timeKey;
            for (i = 0, ilen = dates.length; i < ilen; i++) {
                dateKey = dateFilter(dates[i], FORMAT_DATE);
                dateKeys.push(dateKey);
                schedule[dateKey] = {};
            }

            /**
             * Holds information about maximum amount of rows for each time
             * point. This object has the following structure:
             * {
             *     '08:30': integer
             * }
             */
            var maxTimeRows = {};

            var date, maxRows, scheduledInteraction;
            for (i = 0, ilen = interactions.length; i < ilen; i++) {
                date = new Date(interactions[i].date);
                dateKey = dateFilter(date, FORMAT_DATE);
                timeKey = dateFilter(date, FORMAT_TIME);

                if (dateKeys.indexOf(dateKey) === -1) {
                    // This date doesn't affect the schedule
                    continue;
                }

                if (!schedule[dateKey][timeKey]) {
                    schedule[dateKey][timeKey] = [];
                }

                scheduledInteraction = new ScheduledInteraction(dateKey, timeKey, interactions[i]);
                schedule[dateKey][timeKey].push(scheduledInteraction);

                maxRows = maxTimeRows[timeKey] || 0;
                maxTimeRows[timeKey] = Math.max(maxRows, schedule[dateKey][timeKey].length);
            }

            // Determine target time for the schedule
            var timeKeys = Object.getOwnPropertyNames(maxTimeRows).sort();
            if (!useUnfixedTime) {
                for (i = CALENDAR_MIN_HOUR; i <= CALENDAR_MAX_HOUR; i++) {
                    for (j = 0, jlen = CALENDAR_MINUTE_POINTS.length; j < jlen; j++) {
                        timeKey = getTimeString(i, CALENDAR_MINUTE_POINTS[j]);
                        if (timeKeys.indexOf(timeKey) === -1) {
                            timeKeys.push(timeKey);
                        }
                    }
                }
                timeKeys = timeKeys.sort();
            }

            // Build the schedule
            var timeArray;
            var scheduleArray = [];
            for (i = 0, ilen = dateKeys.length; i < ilen; i++) {
                dateKey = dateKeys[i];

                timeArray = [];
                for (j = 0, jlen = timeKeys.length; j < jlen; j++) {
                    timeKey = timeKeys[j];

                    if (!schedule[dateKey][timeKey]) {
                        schedule[dateKey][timeKey] = [];
                    }

                    // Add real interactions
                    timeArray = timeArray.concat(schedule[dateKey][timeKey]);

                    maxRows = maxTimeRows[timeKey];
                    if (!maxRows && !skipEmptyTime) {
                        // A point of time must have at least one row
                        maxRows = 1;
                    }

                    // Add fake interactions to fill a possible gap
                    for (k = schedule[dateKey][timeKey].length, klen = maxRows; k < klen; k++) {
                        timeArray.push(new ScheduledInteraction(dateKey, timeKey, null));
                    }
                }

                scheduleArray.push(timeArray);
            }

            return scheduleArray;
        }

        function getWeekFirstDate(date) {
            // 7 because week starts on Monday
            var dayIndex = (date.getDay() || 7) - 1;

            var weekStart = new Date(date.getTime());
            weekStart.setDate(weekStart.getDate() - dayIndex);

            return weekStart;
        }

        function getWeekLastDate(date) {
            // 7 because week starts on Monday
            var dayIndex = (date.getDay() || 7) - 1;

            var weekEnd = new Date(date.getTime());
            weekEnd.setDate(weekEnd.getDate() + 6 - dayIndex);

            return weekEnd;
        }

        function getWeekNumber(date) {
            var yearStart = new Date(date.getFullYear(), 0, 1);

            // -1 because week starts on Monday
            var weekOffset = yearStart.getDay() - 1;

            var dayInYear = (date - yearStart) / MILISECS_IN_DAY;
            var weekNumber = Math.ceil((dayInYear + weekOffset) / 7);
            return weekNumber;
        }

        /**
         * Ties google events data to time rows genereated by getFilledTimeArray from interviews data.
         * Adds googleEvents array to each row and skip flag.
         * googleEvents contains entries from google events.
         * skip flag tells when entry should be skipped to not interfere with google data.
         * */



        /**
         * Modify timeRows array entries with data from googleEvents array.
         * Adds googleEvents array to each row and skip flag.
         * timeRows supposed to be generated by getFilledTimeArray.
         * googleEvents array supported to be response from /api/google-calendar/ endpoint.
         * @param  {Object[]} timeRows an array of interviews
         * @param  {Object[]} googleEvents an array of events in google calendar
         * @param  {int?} roomId a room to filter out google events
         */
        function linkGoogleEvents(timeRows, googleEvents, roomId) {
            var halfHour = 1800000; // 1000*60*30 - half of hour in milliseconds

            for (var i = 0, len = timeRows.length; i < len; i++) {
                var row = timeRows[i];
                row.skip = false;
                row.googleEvents = [];
                var rowTime = new Date(row.date + ' ' + row.time + 'Z');

                for(var j=0, k = googleEvents.length; j < k; j++) {
                    var event = googleEvents[j];
                    if(roomId !== undefined && event.room.id !== roomId) {
                        continue;
                    }
                    var start = new Date(event.start.date + ' ' + event.start.time + 'Z');
                    var roundStart = new Date(start.getTime() - (start.getTime() % halfHour));
                    var end = new Date(event.end.date + ' ' + event.end.time + 'Z');
                    var roundEnd = new Date(end.getTime() + halfHour - 1 - ((end.getTime()-1) % halfHour));

                    if(rowTime < roundStart || rowTime >= roundEnd) {
                        continue;
                    }
                    // Skip rows for events taking this time span, but add only one that is start in half-hour period.
                    row.skip = row.interaction === null || row.interaction.held;
                    roundEnd = new Date(roundStart.getTime() + halfHour);
                    if(rowTime < roundStart || rowTime >= roundEnd) {
                        continue;
                    }
                    row.googleEvents.push(event);
                }
            }
        }
    }
})();
