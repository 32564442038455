(function moduleIIFE() {
    'use strict';

    angular.module('hrpro', [
        'ui.router',
        'ngAnimate',
        'angular-loading-bar',
        'ngCookies',
        'toastr',
        'ui.bootstrap',
        'ui.select',
        'ngSanitize',
        'ui.mask',
        'monospaced.elastic',
        'ngFileUpload',
        'angAccordion',
        'ng.group',
        'ds.clock',
        'angular-clipboard',
        'smart-table',
        'ngFileSaver'
    ]);
})();
