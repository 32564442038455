(function currentUserServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('CurrentUser', CurrentUser);

    var EVENT_PROFILE_CHANGED = 'CurrentUser:profileChanged';

    /**
     * A service which is responsible for accessing a profile data of the
     * current user.
     */
    function CurrentUser(AuthService, $q, $rootScope) {
        this.EVENT_PROFILE_CHANGED = EVENT_PROFILE_CHANGED;

        this.getProfile = getProfile;
        this.loadProfile = loadProfile;

        var profile = null;

        /**
         * Retrieves a profile of the current user
         * @return {Object} a profile of the user
         */
        function getProfile() {
            return profile;
        }

        /**
         * Loads a profile of the current user
         * @return {Promise} a promise which will be resolved when a profile
         *   is loaded
         */
        function loadProfile() {
            profile = AuthService.getAuthData();
            $rootScope.$emit(EVENT_PROFILE_CHANGED, profile);
            return $q.when(profile);
        }
    }
})();
