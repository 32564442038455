(function applicantDetailsModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantDetailsModalController', ApplicantDetailsModalController);

    /**
     * A controller of the modal which displays details of the given applicant
     */
    function ApplicantDetailsModalController($uibModalInstance, applicant, $state) {
        var vm = this;

        vm.applicant = applicant;

        vm.close = function close() {
            $uibModalInstance.close();
        };
    }
})();
