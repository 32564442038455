(function returnStateServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ReturnState', ReturnState);

    /**
     * A service to hold information about the state which the user was
     * initially intended to access.
     */
    function ReturnState($state) {
        var stateName;
        var stateParams;

        this.setState = setState;
        this.hasState = hasState;
        this.clearState = clearState;
        this.restoreState = restoreState;


        /**
         * Sets a state which has to be remembered
         * @param {String} name a name of the state
         * @param {Object} params a parameters of the state
         */
        function setState(name, params) {
            stateName = name;
            stateParams = params;
        }

        /**
         * Checks whether the service is holding a state
         * @return {Boolean} true if there is a state remembered
         */
        function hasState() {
            return stateName !== undefined;
        }

        /**
         * Removes a saved state from the service
         */
        function clearState() {
            stateName = undefined;
            stateParams = undefined;
        }

        /**
         * Performs an attempt to restore a saved state
         * @return {Promise} a promise of state transition or undefined
         */
        function restoreState() {
            if (hasState()) {
                return $state.go(stateName, stateParams);
            }
        }
    }
})();
