(function faviconServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('Favicon', Favicon);

    var FAVICON_NOTIFICATION_INTERVAL = 1000;

    /**
     * A service for handling favicon related operations.
     */
    function Favicon($interval, $window) {
        var win = angular.element($window);
        var resetEvents = 'mousemove click keydown touchstart';
        var favicon = new Favico({
            animation: 'pop'
        });

        var animationInterval = null;
        var step = false;
        var badge = ' ';

        this.showNotification = showNotification;
        this.hideNotification = hideNotification;

        function showNotification(notification) {
            badge = notification === undefined ? ' ' : notification;
            animationInterval = $interval(animate, FAVICON_NOTIFICATION_INTERVAL);
            win.on(resetEvents, hideNotification);
        }

        function hideNotification() {
            if (animationInterval) {
                $interval.cancel(animationInterval);
                animationInterval = null;
                favicon.reset();
                win.off(resetEvents, hideNotification);
            }
        }

        function animate() {
            if (step === true) {
                favicon.badge(badge);
            } else {
                favicon.reset();
            }
            step = !step;
        }
    }
})();
