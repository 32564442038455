(function searchHelperServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('SearchHelper', SearchHelper);

    function SearchHelper(InteractionsApi, dateFilter, FORMATS, PATTERNS) {
        var service = this;

        service.CRITERIA_TYPES = [
            {
                name: 'name',
                title: 'ФИО',
                multiUse: true,
                icon: {
                    type: 'font',
                    value: 'fa fa-commenting'
                },
                serialize: serializeValue
            },
            {
                name: 'url_linkedin',
                title: 'LinkedIn URL',
                multiUse: false,
                icon: {
                    type: 'font',
                    value: 'fa fa-linkedin'
                },
                serialize: serializeValue
            },
            {
                name: 'city',
                title: 'Город',
                multiUse: true,
                icon: {
                    type: 'font',
                    value: 'fa fa-map-marker'
                },
                serialize: serializeValue
            },
            {
                name: 'age',
                title: 'Возраст',
                multiUse: false,
                icon: {
                    type: 'font',
                    value: 'fa fa-hourglass'
                },
                serialize: serializeAge
            },
            {
                name: 'work_skills',
                title: 'Навыки',
                multiUse: true,
                icon: {
                    type: 'img',
                    value: 'skills.svg'
                },
                serialize: serializeValue
            },
            {
                name: 'interview',
                title: 'Собеседование',
                multiUse: false,
                icon: {
                    type: 'font',
                    value: 'fa fa-comment'
                },
                serialize: serializeIdWithDates
            },
            {
                name: 'vacancy',
                title: 'Вакансия',
                multiUse: false,
                icon: {
                    type: 'font',
                    value: 'fa fa-universal-access'
                },
                serialize: serializeVacancy
            },
            {
                name: 'tags',
                title: 'Теги',
                multiUse: false,
                icon: {
                    type: 'font',
                    value: 'fa fa-tags'
                },
                serialize: serializeValue
            },
            {
                name: 'interaction',
                title: 'Взаимодействия',
                multiUse: true,
                icon: {
                    type: 'font',
                    value: 'fa file-code-o'
                },
                serialize: serializeIdWithDates
            }
        ];

        service.INTERACTION_TYPES = [
            {
                type: InteractionsApi.TYPE_RESEARCH,
                title: 'Ресерч'
            },
            {
                type: InteractionsApi.TYPE_PRIMARY_SCREENING,
                title: 'Первичный скрининг'
            },
            {
                type: InteractionsApi.TYPE_SELF_EDUCATION,
                title: 'Самообучение'
            },
            {
                type: InteractionsApi.TYPE_TEST_TASK,
                title: 'Тестовое задание'
            },
            {
                type: InteractionsApi.TYPE_INTERVIEW,
                title: 'Приглашен на собеседование'
            },
            {
                type: InteractionsApi.TYPE_STUDY,
                title: 'Принят на обучение'
            },
            {
                type: InteractionsApi.TYPE_IS_WORK,
                title: 'Принят на работу'
            },
            {
                type: InteractionsApi.TYPE_REFUSE,
                title: 'Отказ'
            },
            {
                type: InteractionsApi.TYPE_FIRED,
                title: 'Уволен'
            },
            {
                type: InteractionsApi.TYPE_NOTE,
                title: 'Заметка'
            }
        ];

        service.serializeCriteriaInternal = function serializeCriteriaInternal(criteriaList) {
            var query = [];
            var criteria = null;

            for (var i = 0, len = criteriaList.length; i < len; i++) {
                criteria = angular.copy(criteriaList[i]);
                criteria.type = criteria.type.name;

                if (angular.isArray(criteria.value)) {
                    if (criteria.type === 'interaction') {
                        criteria.value = criteria.value.map(accessorType);
                    } else if (criteria.type === 'interview' || criteria.type === 'vacancy') {
                        criteria.value = criteria.value.map(accessorId);
                    } else if (criteria.type === 'tags') {
                        criteria.value = criteria.value.map(accessorTag);
                    }
                }

                if (/url_/.test(criteria.type)) {
                    criteria.value = encodeURIComponent(criteria.value);
                }

                if (criteria.start) {
                    criteria.start = dateToIso(criteria.start);
                }
                if (criteria.end) {
                    criteria.end = dateToIso(criteria.end);
                }

                query.push(criteria);
            }
            return angular.toJson(query);
        };

        service.serializeCriteriaApi = function serializeCriteriaApi(criteria) {
            var criteriaList = angular.fromJson(criteria || '[]');
            var criteriaType = null;
            var serializedValue = null;
            criteria = null;

            var query = {};
            for (var i = 0, len = criteriaList.length; i < len; i++) {
                criteria = criteriaList[i];

                criteriaType = findInArray(service.CRITERIA_TYPES, 'name', criteria.type);
                serializedValue = criteriaType.serialize(criteria);
                if (!serializedValue ||
                        (angular.isArray(serializedValue) && serializedValue.length === 0)) {
                    continue;
                }

                // Если пользователь установил в форме Advanced_search "не собеседовался",
                // то не создавать в возвращаемом объекте свойсво interview.
                if (!query[criteria.type]) {
                    var cond = criteria.type === 'interview' &&
                        criteria.without_interview;
                    if (!cond) {
                        query[criteria.type] = [];
                    }
                }

                if (angular.isArray(serializedValue)) {
                    Array.prototype.push.apply(query[criteria.type], serializedValue);
                } else {
                    if (!serializedValue.without_interview) {
                        query[criteria.type].push(serializedValue);
                    } else {
                        // Если пользователь установил в форме Advanced_search "не собеседовался",
                        // то в возвращаемый объект прописываем кастомное свойство
                        query['without_interview'] = [true];
                    }
                }
            }

            if (/url_/.test(criteria.type)) {
                query[criteria.type] = [decodeURIComponent(criteria.value)];
            }

            if (query.without_interview && query.without_interview.length > 1) {
                query.without_interview = query.without_interview.slice(0, 1);
            }

            return angular.toJson(query);
        };

        service.parseCriteriaInternal = function parseCriteriaInternal(criteria, vacancies, tags) {
            var query = angular.fromJson(criteria || '[]');
            var criteriaList = [];
            criteria = null;

            var accessorVacancyById = buildAccessorEntityByProperty(vacancies, 'id');
            var accessorTagByTag = buildAccessorEntityByProperty(tags, 'tag');

            for (var i = 0, len = query.length; i < len; i++) {
                criteria = query[i];

                if (angular.isArray(criteria.value)) {
                    if (criteria.type === 'interaction') {
                        criteria.value = criteria.value.map(accessorInteractionByType);
                    } else if ((criteria.type === 'interview' || criteria.type === 'vacancy') &&
                                vacancies && vacancies.length) {
                        criteria.value = criteria.value.map(accessorVacancyById);
                    } else if (criteria.type === 'tags' && tags && tags.length) {
                        criteria.value = criteria.value.map(accessorTagByTag);
                    }
                }


                criteria.type = findInArray(service.CRITERIA_TYPES, 'name', criteria.type);
                criteria.start = isValidDate(criteria.start) ? new Date(criteria.start) : undefined;
                criteria.end = isValidDate(criteria.end) ? new Date(criteria.end) : undefined;

                if (/url_/.test(criteria.type.name)) {
                    criteria.value = decodeURIComponent(criteria.value);
                }
                criteriaList.push(criteria);
            }

            return criteriaList;
        };

        service.updateCriteriaExternalData = function updateCriteriaExternalData(criteriaList, targetType, array) {
            var criteria = null;
            var accessor = null;

            var targetProperty = 'id';
            if (targetType === 'tags') {
                targetProperty = 'tag';
            }

            for (var i = criteriaList.length - 1; i >= 0; i--) {
                criteria = criteriaList[i];

                if (criteria.type.name !== targetType) {
                    continue;
                }

                if (criteria.value && criteria.value.id !== undefined) {
                    accessor = buildAccessorEntityByProperty(array, targetProperty, targetProperty);
                } else {
                    accessor = buildAccessorEntityByProperty(array, targetProperty);
                }

                if (angular.isArray(criteria.value)) {
                    criteria.value = criteria.value.map(accessor);
                }
            }
        };

        function findInArray(array, property, value) {
            for (var i = array.length - 1; i >= 0; i--) {
                if (array[i][property] === value) {
                    return array[i];
                }
            }
        }

        function isValidDate(dateString) {
            return angular.isString(dateString) && PATTERNS.dateIso.test(dateString);
        }

        function dateToIso(date) {
            return dateFilter(date, FORMATS.dateIso);
        }

        function accessorId(entity) {
            return entity.id;
        }

        function accessorTag(entity) {
            return entity.tag;
        }

        function accessorType(entity) {
            return entity.type;
        }

        function accessorInteractionByType(interactionType) {
            var interaction = findInArray(service.INTERACTION_TYPES, 'type', interactionType);
            return interaction;
        }

        function buildAccessorEntityByProperty(array, entityProperty, valueProperty) {
            return function accessor(value) {
                if (valueProperty) {
                    return findInArray(array, entityProperty, value[valueProperty]);
                } else {
                    return findInArray(array, entityProperty, value);
                }
            };
        }

        function serializeValue(entity) {
            return entity.value;
        }

        function serializeAge(entity) {
            if (!entity.min && !entity.max) {
                return null;
            }

            var minAge = entity.min || 0;
            var maxAge = entity.max || 999;
            return minAge + '-' + maxAge;
        }

        function serializeVacancy(entity) {
            if (!entity.value || !entity.value.length) {
                return null;
            }

            var result = [];

            for (var i = 0, len = entity.value.length; i < len; i++) {
                result.push(entity.value[i]);
            }
            return result;
        }

        function serializeIdWithDates(entity) {
            var result = {
                ids: [],
                start_date: isValidDate(entity.start) ? entity.start : '1900-01-01',
                end_date: isValidDate(entity.end) ? entity.end : '9999-01-01',
                without_interview: Boolean(entity.without_interview),
                not_attempted_interview: Boolean(entity.not_attempted_interview)
            };

            if (entity.value) {
                for (var i = 0, len = entity.value.length; i < len; i++) {
                    result.ids.push(entity.value[i]);
                }
            }

            return result;
        }
    }
})();
