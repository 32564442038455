(function calendarControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('CalendarController', CalendarController);

    /**
     * A main controller for calendar
     */
    function CalendarController($scope, dateFilter) {
        var vm = this;

        vm.date = null;

        activate();

        function activate() {
            $scope.$on('calendar:date', onDateChange);
        }

        function onDateChange(evt, date) {
            evt.stopPropagation();
            vm.date = dateFilter(date, 'yyyy-MM-dd');
        }
    }
})();
