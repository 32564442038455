(function calendarDayControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('CalendarDayController', CalendarDayController);

    /**
     * A controller to handle a page with a calendar for one day
     */
    function CalendarDayController(calendar, $state, $stateParams, CalendarHelper, InteractionsApi, dateFilter,
        $scope
    ) {
        var vm = this;

        vm.date = null;
        vm.interviewsHeld = [];
        vm.notifications = [];
        vm.timeRows = [];

        vm.nextDay = nextDay;
        vm.previousDay = previousDay;
        vm.updateDay = updateDay;

        activate();

        function activate() {
            vm.date = new Date();
            if ($stateParams.date) {
                vm.date = new Date($stateParams.date);
            }

            // Notify CalendarController about a new date
            $scope.$emit('calendar:date', vm.date);

            vm.notifications = calendar.data.notification.filter(not(interviewFilter));

            var interviews = calendar.data.notification.filter(interviewFilter);
            vm.interviewsHeld = interviews.filter(interviewHeldFilter);
            vm.timeRows = CalendarHelper.getFilledTimeArray(interviews.filter(not(interviewHeldFilter)), vm.date);
        }

        function updateDay() {
            $state.go('calendar_day', { date: dateFilter(vm.date, 'yyyy-MM-dd') }, { location: 'replace' });
        }

        function not(func) {
            return function backward(item) {
                return !func(item);
            };
        }

        function interviewFilter(interaction, index, array) {
            return interaction.type === InteractionsApi.TYPE_INTERVIEW;
        }

        function interviewHeldFilter(interview) {
            return interview.held;
        }

        function nextDay() {
            vm.date.setDate(vm.date.getDate() + 1);
            updateDay();
        }

        function previousDay() {
            vm.date.setDate(vm.date.getDate() - 1);
            updateDay();
        }
    }
})();
