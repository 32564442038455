(function applicantsApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApplicantsApi', ApplicantsApi);

    /**
     * Provides an ability to interact with the applicant related endpoints of
     * the API
     */
    function ApplicantsApi(ApiHelper, $http, UrlParser) {
        this.CONTACT_TYPE_PHONE = 0;
        this.CONTACT_TYPE_SKYPE = 1;
        this.CONTACT_TYPE_EMAIL = 2;
        this.CONTACT_TYPE_PHONE_FOREIGN = 3;

        this.remove = remove;
        this.getList = getList;
        this.getModel = getModel;
        this.getOne = getOne;
        this.getTotalCount = getTotalCount;
        this.getHistory = getHistory;
        this.save = save;
        this.export = exportList;

        /**
         * Sends a request to the Applicants API endpoint in order to remove
         * an applicant with the given ID.
         * @param  {Integer} id a unique identifier of the applicant to remove
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function remove(id, context) {
            var config = {
                method: 'delete',
                url: ApiHelper.buildUrl('applicants/' + id),
                context: context
            };
            return $http(config);
        }

        /**
         * Sends a request to the Applicants API endpoint in order to retrieve
         * a list of applicants
         * @param  {Object} context optional context object to pass to config
         * @param  {Object} page optional information about a page of applicants
         *  list to retrieve from server. By default a server will send only first
         *  page. This object has to have the following structure:
         *  { offset:integer, limit:integer }
         * @param  {String} searchGeneric optional simple search criteria
         * @param  {Object} searchExtended optional complex search criteria
         * @return {Promise} a promise returned by $http service
         */
        function getList(context, page, searchGeneric, searchExtended) {
            if (!page) {
                page = {};
            }

            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('applicants'),
                context: context,
                params: {
                    generic: searchGeneric,
                    extended: searchExtended,
                    offset: page.offset,
                    limit: page.limit
                }
            };
            return $http(config)
                .then(onGetListSuccess);
        }

        /**
         * Performs post-processing of received list of applicants. It parses
         * links to previous and next pages.
         * @param  {Response} response a response object returned by $http
         * @return {Response} a modified version of the response object
         */
        function onGetListSuccess(response) {
            response.data.prevPage = getPageDefinition(response.data.previous);
            response.data.nextPage = getPageDefinition(response.data.next);
            return response;
        }

        function getPageDefinition(link) {
            if (!link) {
                return {};
            }

            var data = UrlParser.parse(link);

            var limit = parseInt(data.searchParams.limit);
            if (limit > 20) {
                limit = 20;
            }

            return {
                offset: parseInt(data.searchParams.offset),
                limit: limit
            };
        }

        /**
         * Constructs a new applicant model
         * @return {Object}
         */
        function getModel() {
            return {
                first_name: null,
                last_name: null,
                middle_name: null,
                birthday: null,
                salary: null,
                city: null,
                work_skills: null,
                language_skills: null,
                additional_info: null,
                photo: [],
                education: null,
                work: null,
                url_linkedin: null,
                contacts: [],
                attachments: [],
                tags: [],
                search_channels: null,
            };
        }

        /**
         * Sends a request to the Applicants API endpoint in order to retrieve
         * one applicant
         * @param  {Integer} id a unique identifier of the applicant to load
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function getOne(id, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('applicants/' + id),
                context: context
            };
            return $http(config).then(onGetOneSuccess);
        }

        function onGetOneSuccess(response) {
            var modelDates = ['date_create', 'birthday'];
            var interactionDates = ['date_create', 'date', 'date_start', 'date_end'];
            var feedbackDates = ['date_create', 'date'];

            ApiHelper.massConversion(response.data, modelDates, ApiHelper.fromDateIso8601);

            var interactions = response.data.new_interactions;
            for (var i = interactions.length - 1; i >= 0; i--) {
                ApiHelper.massConversion(interactions[i], interactionDates, ApiHelper.fromDateIso8601);
                if (angular.isObject(interactions[i].feedback)) {
                    ApiHelper.massConversion(interactions[i].feedback, feedbackDates, ApiHelper.fromDateIso8601);
                }
            }
            return response;
        }

        /**
         * Sends a request to the Applicants API endpoint in order to receive
         * a total amount of applicants on the server
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function getTotalCount(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('count_applicant'),
                context: context
            };
            return $http(config);
        }

        /**
         * Sends a request to the Applicants API endpoint in order to save
         * a given applicant model. It will either create a new entity or
         * update the existing one depending on existence of the "id" property
         * in the given model.
         * @param {Object} model entity which has to be saved
         * @param {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function save(model, context) {
            var url, method;

            if (model.id) {
                url = ApiHelper.buildUrl('applicants/' + model.id);
                method = 'patch';
            } else {
                url = ApiHelper.buildUrl('applicants');
                method = 'post';
            }

            var data = angular.merge({}, model);
            data.birthday = ApiHelper.toDateIso8601(data.birthday);

            ApiHelper.normalizeEmptyStrings(data);

            for (var i = data.contacts.length - 1; i >= 0; i--) {
                if (!data.contacts[i].value) {
                    data.contacts.splice(i, 1);
                }
            }

            var config = {
                method: method,
                url: url,
                context: context,
                data: data
            };

            return $http(config).then(onGetOneSuccess);
        }

        function getHistory(id, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('activity/' + id),
                context: context
            };
            return $http(config);
        }

        function exportList(context, searchGeneric, searchExtended) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('applicants_export'),
                context: context,
                data: {
                    generic: searchGeneric,
                    extended: searchExtended
                }
            };
            return $http(config);
        }
    }
})();
