(function applicantHistoryActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantHistoryActionsController', ApplicantHistoryActionsController);

    function ApplicantHistoryActionsController($scope, ApplicantsApi) {
        var vm = this;
        var mainVM = null;

        vm.TYPE_CREATE = ['Создано взаимодействие', 'Создана карточка', 'Создан контакт', 'Добавлено фото'];
        vm.TYPE_UPDATE = ['Обновлено взаимодействие', 'Обновлён контакт', 'Обновлена карточка'];
        vm.TYPE_DELETE = ['Удалено взаимодействие', 'Удален контакт', 'Удалено фото'];

        vm.history = [];
        vm.loader = false;

        vm.getIconClass = getIconClass;

        activate();

        function activate() {
            mainVM = $scope.applicantVM;

            $scope.$on('applicant-tabs:tab-selected', onTabSelected);

            onHistoryLoadSuccess(mainVM.historyResponse);
        }

        function onTabSelected(event, tab) {
            if (tab !== 'history') {
                return;
            }

            loadHistory();
        }

        function getIconClass(active) {
            if (vm.TYPE_CREATE.indexOf(active.verb) >= 0 || (active.data && active.data.type === 1)) {
                return 'fa-plus';
            } else if (vm.TYPE_UPDATE.indexOf(active.verb) >= 0 || (active.data && active.data.type === 2)) {
                return 'fa-refresh';
            } else if (vm.TYPE_DELETE.indexOf(active.verb) >= 0 || (active.data && active.data.type === 3)) {
                return 'fa-trash';
            }
        }

        function loadHistory() {
            vm.loader = true;
            ApplicantsApi
                .getHistory(mainVM.model.id)
                .then(onHistoryLoadSuccess);
        }

        function onHistoryLoadSuccess(response) {
            vm.history = response.data;
            vm.history.forEach(historyItemDateFixer);
            vm.loader = false;
        }

        function historyItemDateFixer(elem, index, arr) {
            elem.timestamp = new Date(elem.timestamp);
            elem.fullDate = elem.timestamp.toISOString().slice(0, 10);
        }
    }
})();
