(function applicantHelperIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApplicantHelper', ApplicantHelper);

    /**
     * A helper service that contains a common logic related to applicants
     */
    function ApplicantHelper(InteractionsApi) {
        var api = InteractionsApi;

        this.getLastInteractionWithVacancy = getLastInteractionWithVacancy;
        this.sortInteractionsByDate = sortInteractionsByDate;

        function getLastInteractionWithVacancy(interactions) {
            var interactionsCopy = interactions.slice();
            sortInteractionsByDate(interactionsCopy);

            for (var i = interactionsCopy.length - 1; i >= 0; i--) {
                if (interactionsCopy[i].vacancy && interactionsCopy[i].type !== api.TYPE_REFUSE &&
                    interactionsCopy[i].type !== api.TYPE_INFORMATION && interactionsCopy[i].type !== api.TYPE_FIRED) {
                    return interactionsCopy[i];
                }
            }
            return null;
        }

        function sortInteractionsByDate(interactions) {
            interactions.sort(interactionsIdComparator);
        }

        function interactionsIdComparator(fst, snd) {
            return fst.date_create - snd.date_create;
        }
    }
})();

