(function dropdownMaxHeightFixerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('dropdownMaxHeightFixer', dropdownMaxHeightFixer);

    function dropdownMaxHeightFixer($window) {
        var directive = {
            restrict: 'A',
            link: link
        };

        var win = angular.element($window);
        var body = document.body;
        var html = document.documentElement;

        return directive;

        function link(scope, element, attrs) {
            var heightGap = parseInt(attrs.heightGap || '0');

            scope.$on('$destroy', onDestroy);
            scope.$on('max-height-changed', fixMaxHeight);
            win.on('resize', fixMaxHeight);

            fixMaxHeight();

            function onDestroy() {
                win.off('resize', fixMaxHeight);
            }

            function fixMaxHeight() {
                if (isSmallScreen()) {
                    element.css('max-height', '');
                } else {
                    var documentHeight = getDocumentHeight();
                    var elementTop = getElementTop(element[0]);
                    var maxHeight = documentHeight - elementTop - heightGap;
                    element.css('max-height', maxHeight);
                }
            }
        }

        function getDocumentHeight() {
            return window.innerHeight || html.clientHeight || body.clientHeight;
        }

        function getDocumentWidth() {
            return window.innerWidth || html.clientWidth || body.clientWidth;
        }

        function isSmallScreen() {
            var documentWidth = getDocumentWidth();
            return documentWidth <= 768;
        }

        function getElementTop(element) {
            var box = element.getBoundingClientRect();
            var scrollTop = window.pageYOffset || html.scrollTop || body.scrollTop;
            var clientTop = html.clientTop || body.clientTop || 0;
            var top  = box.top +  scrollTop - clientTop;
            return top;
        }
    }
})();
