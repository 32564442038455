(function ParserApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ParserApi', ParserApi);

    /**
     * Provides an ability to interact with the parser related endpoints of the API
     */
    function ParserApi(ApiHelper, $http) {
        this.gerWorkUa = gerWorkUa;

        function gerWorkUa(workUrl, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('work_ua'),
                params: {
                    work_url: workUrl
                },
                context: context
            };
            return $http(config);
        }
    }
})();
