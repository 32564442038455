(function clipboardSupportModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ClipboardSupportModalController', ClipboardSupportModalController);

    /**
     * A controller of the modal which supports a clipboard operations
     */
    function ClipboardSupportModalController($uibModalInstance, text, context, $scope) {
        var vm = this;

        vm.text = text;

        vm.close = function close() {
            $uibModalInstance.close(context);
        };

        $uibModalInstance.rendered.then(onModalRendered);

        function onModalRendered() {
            $scope.$broadcast('autoselect:select');
        }
    }
})();
