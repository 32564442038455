(function vacanciesListControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('VacanciesListController', VacanciesListController);

    /**
     * A controller for handling a list of vacancies.
     */
    function VacanciesListController(VacanciesApi, $state, $stateParams, vacancies, Notify, $scope, $timeout) {
        var vm = this;
        var currentTab = null;

        vm.dataToggle = false;
        vm.list = [];
        vm.tabsStatus = {};

        vm.activeVacancy = activeVacancy;
        vm.activateDeactivate = activateDeactivate;
        vm.copyVacancy = copyVacancy;
        vm.onTabSelect = onTabSelect;

        activate();

        function activate() {
            currentTab = $stateParams.tab || 'active';
            vm.tabsStatus[currentTab] = true;
            vm.list = vacancies.data;
        }

        function activeVacancy(id) {
            vm.dataToggle = vm.dataToggle === id ? -1 : id;

            // Wait until DOM is updated
            $timeout(adjustElasticTextareas);
        }

        function activateDeactivate(vacancy, status) {
            var initialData = angular.copy(vacancy);
            vacancy.active = status;
            vacancy.date_active = new Date();
            VacanciesApi
                .save(vacancy, { vacancy: vacancy, initialData: initialData })
                .then(onActiveChangeSuccess, onActiveChangeError);
        }

        function adjustElasticTextareas() {
            $scope.$broadcast('elastic:adjust');
        }

        function onActiveChangeSuccess(response) {
            var vacancy = response.config.context.vacancy;
            var title = vacancy.active ? 'Активировано' : 'Деактивировано';
            var action = vacancy.active ? 'активирована' : 'деактивирована';
            Notify.success(title, 'Вакансия успешно ' + action);
        }

        function onActiveChangeError(response) {
            var vacancy = response.config.context.vacancy;
            var action = vacancy.active ? 'активировать' : 'деактивировать';
            Notify.error('Ошибка', 'Не удалось ' + action + ' вакансию');

            var initialData = response.config.context.initialData;
            vacancy.active = initialData.active;
            vacancy.date_active = initialData.date_active;
        }

        function copyVacancy(vacancy) {
            var newVacancy = angular.copy(vacancy);
            delete newVacancy.id;
            newVacancy.title += ' Copy';
            VacanciesApi
                .save(newVacancy)
                .then(onCopySaveSuccess, onCopySaveError);
        }

        function onCopySaveSuccess(response) {
            vm.list.push(response.data);
            Notify.success('Копия создана', 'Копия вакансии успешно сохранена на сервере');
        }

        function onCopySaveError(response) {
            Notify.error('Ошибка', 'Не удалось сохранить копию вакансии');
        }

        function onTabSelect(tab) {
            if (currentTab === tab) {
                return;
            }

            // $location.search('tab', tab) causes problems
            $state.go('.', { tab: tab }, { location: 'replace' });
            currentTab = tab;
        }
    }
})();
