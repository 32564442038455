(function interactionsReportRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(interactionsReportRouting);

    function interactionsReportRouting($stateProvider) {
        var DEFAULT_PERIOD = 7;

        $stateProvider
            .state('report_interactions', {
                parent: 'app',
                url: '/reports/interactions?start&end&hrUsers',
                templateUrl: '/hrpro/app/reports/interactions/index.html',
                data: {
                    pageTitle: 'Взаимодействия',
                    bodyClass: 'reports'
                },
                resolve: {
                    dateStart: function resolveDateStart($stateParams) {
                        if ($stateParams.start) {
                            return new Date($stateParams.start);
                        } else {
                            var end = new Date();
                            if ($stateParams.end) {
                                end = new Date($stateParams.end);
                            }
                            end.setDate(end.getDate() - DEFAULT_PERIOD);
                            return end;
                        }
                    },
                    dateEnd: function resolveDateEnd($stateParams, dateStart) {
                        if ($stateParams.end) {
                            return new Date($stateParams.end);
                        } else {
                            var end = new Date();
                            if (dateStart > end) {
                                end = new Date(dateStart.getTime());
                                end.setDate(end.getDate() + DEFAULT_PERIOD);
                            }
                            return end;
                        }
                    },
                    hrUsersFilter: function resolveHrUsersFilter($stateParams){
                        if ($stateParams.hrUsers) {
                            return $stateParams.hrUsers.split(',');
                        }
                        return [];
                    },
                    hrUsers: function resolveHrUsers(MasterApi, Notify){
                        var promise = MasterApi.getList();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Не удалось загрузить список HR')
                        }
                    },
                    data: function resolveData($stateParams, dateStart, dateEnd, ReportsApi, Notify, dateFilter) {
                        var sessionStartDate = sessionStorage.getItem('startDate');
                        var sessionEndDate = sessionStorage.getItem('endDate');

                        var params = {
                            start: dateFilter(sessionStartDate ? new Date(sessionStartDate) : dateStart, 'yyyy-MM-dd'),
                            end: dateFilter(sessionEndDate ? new Date(sessionEndDate) : dateEnd, 'yyyy-MM-dd'),
                            hrUsers: $stateParams.hrUsers
                        };

                        var promise = ReportsApi.getReport(ReportsApi.TYPE_INTERACTIONS, params);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить отчет');
                        }
                    },
                    currentUser: function resolveUser(MasterApi, Notify){
                        var promise = MasterApi.getMaster();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Не удалось загрузить текущего пользователя')
                        }
                    }, 
                },
                controller: 'InteractionsReportController',
                controllerAs: 'reportVM'
            });
    }
})();
