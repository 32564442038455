(function authRequiredInterceptorServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('AuthRequiredInterceptor', AuthRequiredInterceptor);

    var EVENT_AUTH_REQUIRED = 'AuthRequiredInterceptor:authenticationRequired';

    /**
     * An HTTP interceptor which handles authentication error in responses.
     */
    function AuthRequiredInterceptor($rootScope, $q) {
        this.EVENT_AUTH_REQUIRED = EVENT_AUTH_REQUIRED;

        this.responseError = responseError;

        /**
         * Handles HTTP Unauthorized error
         * @param  {Response} response $http response object
         * @return {Promise} a new response promise
         */
        function responseError(response) {
            if (response.status === 401) {
                $rootScope.$emit(EVENT_AUTH_REQUIRED);
            }
            return $q.reject(response);
        }
    }
})();
