(function uiSelectRequiredDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('uiSelectRequired', uiSelectRequired);

    function uiSelectRequired() {
        var directive = {
            require: 'ngModel',
            link: link
        };

        return directive;

        function link(scope, elm, attrs, ctrl) {
            ctrl.$validators.required = requiredValidator;
        }

        function requiredValidator(modelValue, viewValue) {
            if (modelValue && modelValue.length > 0) {
                return true;
            } else {
                return false;
            }
        }
    }
})();
