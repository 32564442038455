(function httpErrorsFixerInterceptorServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('HttpErrorsFixerInterceptor', HttpErrorsFixerInterceptor);

    var ERR_UNEXPECTED = 'HttpErrorsFixerInterceptor:unexpected-error-occurred';

    /**
     * Fixes HTTP responses in case there is no config object defined for the
     * rejection object. For example, such situations are possible in case of
     * network error, when a response is received partially.
     * Response objects are fixed by adding an empty config object to them, as
     * well as isUnexpectedError flag and unexpectedErrorMessage.
     */
    function HttpErrorsFixerInterceptor($q, $rootScope) {
        this.ERR_UNEXPECTED = ERR_UNEXPECTED;

        this.responseError = responseError;

        function responseError(response) {
            if (!response.config) {
                $rootScope.$emit(ERR_UNEXPECTED, response);

                return $q.reject({
                    config: {},
                    isUnexpectedError: true,
                    errorMessage: response.message,
                    errorStack: response.stack || response.stacktrace || ''
                });
            } else {
                return $q.reject(response);
            }
        }
    }
})();
