(function reportsApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ReportsApi', ReportsApi);

    /**
     * Provides an ability to interact with the reports related endpoints of
     * the API
     */
    function ReportsApi(ApiHelper, $http) {
        this.TYPE_TEST_TASK = 'test-task';
        this.TYPE_OUTDATED_NOTIFICATIONS = 'outdated-notifications';
        this.TYPE_INTERACTIONS = 'interactions';
        this.TYPE_FUNNEL = 'funnel';
        this.TYPE_EXPORT_FUNNEL = 'export-funnel';
        this.TYPE_INTERVIEWER = 'interviewer';
        this.TYPE_EXPORT_INTERVIEWER = 'export-interviewer';

        this.getReport = getReport;
        this.exportReport = exportReport;

        /**
         * Sends a request to the Reports API endpoint in order to retrieve a
         * report data.
         * @param  {String} type a type of report to retrieve. See TYPE_... constants.
         * @param  {Object} params optional list of params of a report
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function getReport(type, params, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('reports/' + type),
                context: context,
                params: params
            };
            return $http(config);
        }

        /**
         * Sends a request to the Reports API endpoint in order to export a
         * report data.
         * @param  {String} type a type of report to retrieve. See TYPE_... constants.
         * @param  {Object} data data of a report
         * @return {Promise} a promise returned by $http service
         */
        function exportReport(context, type, data) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('reports/' + type),
                context: context,
                data: data,
            };

            return $http(config);
        }
    }
})();
