(function notIgnoredMastersFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('notIgnoredMasters', notIgnoredMasters);

    /**
     * A filter for removing from array all masters whose id is present in a
     * list of ignored masters. It is possible to keep an ignored master in a
     * list by passing his id in an array of allowed items.
     *
     * It is needed, for example, to hide from drop down lists placeholder
     * masters, which were added for repairing data consistency during project
     * migration.
     */
    function notIgnoredMasters(IGNORE_MASTERS, notIgnoredItemsFilter) {
        return function filter(input, allowedItems) {
            return notIgnoredItemsFilter(input, IGNORE_MASTERS, allowedItems);
        };
    }
})();
