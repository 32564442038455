// jscs:disable maximumNumberOfLines
/**
 * NOTE: JSCS rule is disabled because of lack of time. This file needs refactoring.
 * For example, a lock management functionality, probably, may be moved to a separate
 * service.
 */
(function applicantsEditControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantsEditController', ApplicantsEditController);

    /**
     * A main controller of an applicant edition page
     */
    function ApplicantsEditController(
        applicant, ConfirmDeletionModal, ApplicantsApi, $state, Notify, APPLICANT_TEMPLATES, tags,
        $scope, $window, $timeout, VacanciesApi, ApplicantHelper, history, $stateParams, $rootScope,
        ConfirmSavingModal, $q, ApplicantWebsocket, lock, Identity, Favicon, MasterApi, ws
    ) {
        var vm = this;

        var LOCK_EMPTY = 0;
        var LOCK_OWNER = 1;
        var LOCK_FOREIGN_TAB = 2;
        var LOCK_FOREIGN_USER = 3;

        var win = angular.element($window);
        var unbindStateChangeStartListener;
        var unbindWsMessageListener;
        var unbindWsDisconnectListener;
        var lockType = LOCK_EMPTY;
        var unbindModelWatcher;
        var lockPromise = null;
        var modelBackup = null;
        var alertChanged = null;
        var alertLocked = null;
        var alertDied = null;
        var promiseVacancies = null;
        var promiseMasters = null;

        vm.activeVacancy = false;
        vm.model = null;
        vm.templates = APPLICANT_TEMPLATES;
        vm.emails = null;
        vm.phones_masked = null;
        vm.phones_unmasked = null;
        vm.skypes = null;
        vm.tags = tags.data.results;
        vm.historyResponse = history;
        vm.cvLoadingPromise = null;
        vm.vacancies = [];
        vm.blocked = false;
        vm.directions = {
            0: 'fa-sign-out',
            1: 'fa-sign-in'
        };

        vm.save = save;
        vm.lockApplicant = lockApplicant;
        vm.unlockApplicant = unlockApplicant;
        vm.isApplicantLocked = isApplicantLocked;
        vm.enableModelWatcher = enableModelWatcher;
        vm.disableModelWatcher = disableModelWatcher;
        vm.loadVacancies = loadVacancies;
        vm.loadMasters = loadMasters;
        vm.openLink = openLink;

        activate();

        win.on('beforeunload', onBeforeUnload);
        $scope.$on('$destroy', onDestroy);
        unbindWsMessageListener = $rootScope.$on(ApplicantWebsocket.EVENT_MESSAGE, onWsMessage);
        unbindWsDisconnectListener = $rootScope.$on(ApplicantWebsocket.EVENT_DISCONNECTED, onWsDisconnect);
        bindStateChangeStartListener();

        function activate() {
            lockType = getLockType(lock);
            initialize();
            enableModelWatcher();
        }

        function enableModelWatcher() {
            if (!unbindModelWatcher) {
                unbindModelWatcher = $scope.$watch('[applicantVM.model, ' +
                    'applicantVM.emails, applicantVM.phones_masked, ' +
                    'applicantVM.phones_unmasked, applicantVM.skypes]',
                    onModelChanged, true);
            }
        }

        function onModelChanged(newValue, oldValue) {
            if (!angular.equals(newValue, oldValue)) {
                if (!lockPromise && lockType !== LOCK_OWNER) {
                    lockPromise = lockApplicant().then(onChangedLockingDone, onChangedLockingDone);
                }
            }
        }

        function onChangedLockingDone() {
            lockPromise = null;
        }

        function disableModelWatcher() {
            if (unbindModelWatcher) {
                unbindModelWatcher();
                unbindModelWatcher = null;
            }
        }

        function initialize() {
            vm.model = applicant.data;
            if (vm.model.birthday) {
                vm.model.birthday = new Date(vm.model.birthday);
            }
            var lastVacancyInteraction = ApplicantHelper.getLastInteractionWithVacancy(vm.model.new_interactions);
            if (lastVacancyInteraction === null) {
                vm.activeVacancy = true;
            }

            modelBackup = angular.copy(vm.model);

            vm.emails = getContacts(ApplicantsApi.CONTACT_TYPE_EMAIL, 2);
            vm.phones_masked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE, 2);
            vm.phones_unmasked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE_FOREIGN, 1);
            vm.skypes = getContacts(ApplicantsApi.CONTACT_TYPE_SKYPE, 1);
            loadVacancies();
            loadMasters();
        }

        function getContacts(type, amount) {
            var array = vm.model.contacts.filter(contactsFilter(type));
            for (var i = array.length; i < amount; i++) {
                array.push({ type: type, value: '' });
            }
            return array;
        }

        function loadVacancies() {
            if (!promiseVacancies) {
                promiseVacancies = VacanciesApi.getListMini();
                promiseVacancies.then(onVacanciesLoadSuccess, onVacanciesLoadError);
            }
            return promiseVacancies;
        }

        function onVacanciesLoadSuccess(response) {
            vm.vacancies = response.data;
        }

        function onVacanciesLoadError(response) {
            Notify.error('Ошибка', 'Невозможно загрузить список вакансий');
        }

        function loadMasters() {
            if (!promiseMasters) {
                promiseMasters = MasterApi.getList();
                promiseMasters.then(undefined, onMastersLoadError);
            }
            return promiseMasters;
        }

        function onMastersLoadError(response) {
            Notify.error('Ошибка', 'Невозможно загрузить список интервьеров');
        }

        function contactsFilter(type) {
            return function filter(element) {
                return element.type === type;
            };
        }

        function bindStateChangeStartListener() {
            unbindStateChangeStartListener = $rootScope.$on('$stateChangeStart', onStateChangeStart);
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid) {
                Notify.warning('Ошибка', 'Перед сохранением карточки кандидата исправьте ошибки');
                return;
            }

            disableModelWatcher();

            if (vm.vacancySelected) {
                vm.model.vacancy = vm.vacancySelected;
            }
            vm.model.contacts = vm.emails.concat(vm.phones_masked, vm.phones_unmasked, vm.skypes);

            // The applicant will be unlocked during the saving process. So let's
            // suppose that save succeeded in order to ignore unlock notification
            // from the web socket
            lockType = LOCK_EMPTY;

            return ApplicantsApi
                .save(vm.model)
                .then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(response) {
            $scope.myForm.$setPristine();

            // The applicant is successfully saved and unlocked. It means that
            // our assumption about the locking state was correct and we
            // can safely clear the lock
            lock = null;

            applicant = response;
            activate();

            if ($stateParams.tab === 'history') {
                // Trigger update of the history tab
                $scope.$broadcast('applicant-tabs:tab-selected', 'history');
            }

            Notify.success('Сохранено', 'Карточка кандидата успешно сохранена на сервере');
        }

        function onSaveError(response) {
            if (vm.vacancySelected) {
                vm.model.vacancy = null;
            }

            if (response.data.error === 'version') {
                Notify.error('Ошибка', 'Карточку кандидата не удалось сохранить на сервере. ' +
                    'Данная версия карточки устарела и может послужить причиной потери данных. ' +
                    'Пожалуйста, <a class="btn btn-danger btn-xs" ' +
                    'href="javascript:window.location.reload(true)">обновите страницу</a>, ' +
                    'чтобы загрузить новую версию карточки.', {
                        allowHtml: true,
                        timeOut: 0,
                        extendedTimeOut: 0,
                        tapToDismiss: false,
                        closeButton: false
                    });

                lockType = LOCK_EMPTY;
                vm.blocked = true;

                // Disables save confirmation message or page reload
                $scope.myForm.$setPristine();
            } else {
                Notify.error('Ошибка', 'Карточку кандидата не удалось сохранить на сервере');

                // The applicant is not saved and unlocked. It means that our assumption
                // about the locking state was incorrect and we have to restore old
                // lockType. LOCK_OWNER is the only lockType for us to be able to initiate
                // the saving process.
                lockType = LOCK_OWNER;
            }

            enableModelWatcher();

            return $q.reject();
        }

        function onBeforeUnload(evt) {
            if ($scope.myForm.$dirty) {
                var message = 'На этой странице есть несохраненные данные.';
                evt.returnValue = message;
                return message;
            }
        }

        function onDestroy() {
            win.off('beforeunload', onBeforeUnload);
            unbindStateChangeStartListener();
            unbindWsMessageListener();
            unbindWsDisconnectListener();
            Notify.closeAll();
            if (ws !== false) {
                ApplicantWebsocket.disconnect();
            }
        }

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if ($scope.myForm.$dirty) {
                unbindStateChangeStartListener();

                event.preventDefault();
                ConfirmSavingModal
                    .open('На странице есть несохраненные данные. Хотите сохранить их перед переходом?', [
                        toState, toParams, fromState, fromParams
                    ])
                    .then(onSaveConfirmed, onSaveRejected);
            }
        }

        function onSaveConfirmed(context) {
            var savePromise = save($scope.myForm);
            if (!savePromise) {
                bindStateChangeStartListener();
                return;
            }

            savePromise.then(function onDelayedSaveSuccess() {
                $state.go(context[0], context[1]);
            }, function onDelayedSaveError() {
                bindStateChangeStartListener();
            });
        }

        function onSaveRejected(context) {
            $state.go(context[0], context[1]);
        }

        function onWsDisconnect(event) {
            if (alertLocked !== null) {
                Notify.close(alertLocked);
                alertLocked = null;
            }
            var msg = 'Соединение с сервером потеряно. Пожалуйста, <a class="btn btn-danger btn-xs" ' +
                'href="javascript:window.location.reload(true)">обновите страницу</a>.';
            Notify.error('Потеряно соединение', msg, {
                allowHtml: true,
                timeOut: 0,
                extendedTimeOut: 0,
                tapToDismiss: false,
                closeButton: false
            });
            unbindWsMessageListener();
            $rootScope.$emit(ApplicantWebsocket.EVENT_MESSAGE, {
                type: ApplicantWebsocket.MESSAGE_DIE
            });
            vm.blocked = true;
        }

        function onWsMessage(event, payload) {
            var handlers = {};
            handlers[ApplicantWebsocket.MESSAGE_LOCKED] = onWsMessageLocked;
            handlers[ApplicantWebsocket.MESSAGE_UNLOCKED] = onWsMessageUnlocked;
            handlers[ApplicantWebsocket.MESSAGE_ATTEMPT] = onWsMessageAttempt;
            handlers[ApplicantWebsocket.MESSAGE_CHANGED] = onWsMessageChanged;
            handlers[ApplicantWebsocket.MESSAGE_DIE] = onWsMessageDie;
            handlers[ApplicantWebsocket.MESSAGE_DELETED] = onWsMessageDeleted;

            if (handlers[payload.type]) {
                handlers[payload.type](payload.message);
            }
        }

        function onWsMessageLocked(message) {
            if (lockType !== LOCK_EMPTY) {
                return;
            }

            lock = message;
            lockType = getLockType(message);
        }

        function onWsMessageUnlocked(message) {
            if (alertLocked !== null) {
                Notify.close(alertLocked);
                alertLocked = null;
            }
            if (alertChanged !== null) {
                return;
            }

            lock = null;
            lockType = getLockType(lock);
            vm.blocked = false;
        }

        function onWsMessageAttempt(message) {
            if (lockType === LOCK_OWNER) {
                Favicon.showNotification();
            }
        }

        function onWsMessageChanged(message) {
            if (alertLocked !== null) {
                Notify.close(alertLocked);
                alertLocked = null;
            }
            if (alertChanged !== null) {
                Notify.close(alertChanged);
            }

            var msg = 'Вы обновили информацию об этом кандидате в другой вкладке.';
            if (lockType === LOCK_FOREIGN_USER) {
                msg = 'Пользователь ' + message.user.first_name + ' ' + message.user.last_name +
                    ' обновил информацию об этом кандидате.';
            }
            msg += ' Пожалуйста, <a class="btn btn-warning btn-xs" ' +
                'href="javascript:window.location.reload(true)">обновите страницу</a>.';

            alertChanged = Notify.warning('Обновлено', msg, {
                allowHtml: true,
                timeOut: 0,
                extendedTimeOut: 0,
                tapToDismiss: false,
                closeButton: false
            });

            vm.blocked = true;
        }

        function onWsMessageDie(message) {
            if (lockType !== LOCK_OWNER) {
                return;
            }

            if (alertDied === null) {
                alertDied = Notify.warning('Внимание',
                    'Процесс редактирования кандидата в этой вкладке был прерван.', {
                        timeOut: 0,
                        extendedTimeOut: 0,
                        onHidden: onAlertDiedHidden
                    });
            }

            lock = null;
            lockType = LOCK_EMPTY;
            $scope.myForm.$setPristine();
        }

        function onWsMessageDeleted(message) {
            if (alertLocked !== null) {
                Notify.close(alertLocked);
                alertLocked = null;
            }
            if (alertChanged !== null) {
                Notify.close(alertChanged);
            }

            var msg = 'Этот кандидат был удален с сервера. ' +
                '<a ui-sref="applicants_list()" class="btn btn-danger btn-xs">' +
                'Вернуться к списку</button>';

            alertChanged = Notify.error('Удалено', msg, {
                timeOut: 0,
                extendedTimeOut: 0,
                tapToDismiss: false,
                closeButton: false,
                allowHtml: true,
                compileMessage: true
            });

            $scope.myForm.$setPristine();
            vm.blocked = true;
        }

        function onAlertDiedHidden() {
            alertDied = null;
        }

        function getLockType(lockResponse) {
            if (lockResponse === null) {
                return LOCK_EMPTY;
            } else if (Identity.isSameUser(lockResponse.user_id)) {
                if (Identity.isSameTab(lockResponse.tab_id)) {
                    return LOCK_OWNER;
                } else {
                    return LOCK_FOREIGN_TAB;
                }
            } else {
                return LOCK_FOREIGN_USER;
            }
        }

        function resolveCanEditPromise(deferred) {
            if (lockType === LOCK_OWNER) {
                vm.blocked = false;
                deferred.resolve();
            } else {
                vm.blocked = true;

                undoModelChanges();

                var isSameUser = lockType === LOCK_FOREIGN_TAB;

                deferred.reject({
                    isSameUser: isSameUser,
                    response: lock
                });

                if (alertLocked === null) {
                    var message;
                    if (isSameUser) {
                        message = 'Этот кандидат уже редактируется вами в другой вкладке. ' +
                            'Вы можете отредактировать его здесь, но это приведет к потере ' +
                            'несохраненных данных в другой вкладке. ' +
                            '<button class="btn btn-info btn-xs" ng-click="extraData.edit()">' +
                            'Разблокировать кандидата</button>';
                    } else {
                        message = 'Этот кандидат уже редактируется пользователем ' +
                            lock.user.first_name + ' ' + lock.user.last_name;
                    }

                    alertLocked = Notify.info('Заблокировано', message, {
                        timeOut: 0,
                        extendedTimeOut: 0,
                        tapToDismiss: false,
                        closeButton: false,
                        allowHtml: true,
                        compileMessage: true,
                        extraData: {
                            edit: forceUnlock
                        }
                    });
                }
            }
        }

        function forceUnlock() {
            ApplicantWebsocket
                .dropLock(vm.model.id)
                .then(onForceUnlockSuccess, onForceUnlockError);
        }

        function onForceUnlockSuccess(response) {
            if (response !== true) {
                Notify.error('Ошибка', 'Не удалось разблокировать кандидата');
                return;
            }

            lock = null;
            lockType = LOCK_EMPTY;
            vm.blocked = false;
            Notify.success('Разблокировано', 'Кандидат успешно разблокирован. Вы можете продолжать редактирование.');
            Notify.close(alertLocked);
            alertLocked = null;
        }

        function onForceUnlockError(reason) {
            Notify.error('Ошибка', 'Не удалось разблокировать кандидата. Превышен лимит ожидания ответа.');
        }

        function lockApplicant() {
            var deferred = $q.defer();

            if (ws === false) {
                // It has to be impossible to change an applicant in case
                // a websocket is not connected
                Notify.error('Ошибка', 'Невозможно заблокировать запись. Сокет не подключен.');
                vm.blocked = true;
                undoModelChanges();
                deferred.reject({ timeout: false });
                return deferred.promise;
            }

            if (lockType === LOCK_EMPTY || lockType === LOCK_FOREIGN_TAB) {
                // We send a request in case of LOCK_FOREIGN_TAB lock in order
                // to notify foreign tab about an editing attempt
                ApplicantWebsocket
                    .putLock(vm.model.id)
                    .then(onLockSuccess, onLockError);
            } else {
                resolveCanEditPromise(deferred);
            }

            return deferred.promise;

            function onLockSuccess(response) {
                lock = response;
                lockType = getLockType(response);
                resolveCanEditPromise(deferred);
            }

            function onLockError() {
                Notify.error('Ошибка', 'Невозможно заблокировать запись. Превышен лимит времени ожидания ответа.');
                deferred.reject({ timeout: true });
            }
        }

        function unlockApplicant() {
            var deferred = $q.defer();

            if (lockType === LOCK_OWNER) {
                lockType = LOCK_EMPTY;
                ApplicantWebsocket
                    .dropLock(vm.model.id)
                    .then(onUnlockSuccess, onUnlockError);
            } else {
                deferred.resolve();
            }

            return deferred.promise;

            function onUnlockSuccess(response) {
                if (response === true) {
                    lock = null;
                    deferred.resolve();
                } else {
                    Notify.error('Ошибка', 'Невозможно разблокировать запись. Произошла ошибка.');
                    deferred.reject();
                }
            }

            function onUnlockError() {
                lockType = LOCK_OWNER;
                Notify.error('Ошибка', 'Невозможно разблокировать запись. Превышен лимит времени ожидания ответа.');
                deferred.reject({ timeout: true });
            }
        }

        function isApplicantLocked() {
            return lockType === LOCK_OWNER;
        }

        function openLink(link) {
            if (!link) {
                return;
            }
            window.open(link, 'blank');
        }

        function undoModelChanges() {
            disableModelWatcher();
            vm.model = angular.copy(modelBackup);
            vm.emails = getContacts(ApplicantsApi.CONTACT_TYPE_EMAIL, 2);
            vm.phones_masked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE, 2);
            vm.phones_unmasked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE_FOREIGN, 1);
            vm.skypes = getContacts(ApplicantsApi.CONTACT_TYPE_SKYPE, 1);
            $scope.myForm.$setPristine();
            enableModelWatcher();
        }
    }
})();
