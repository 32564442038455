(function backToTopDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('backToTop', backToTop);

    function backToTop() {
        var directive = {
            restrict: 'A',
            replace: true,
            templateUrl: 'hrpro/common/views/back-to-top.html',
            link: function link(scope, element, attrs) {
                function scrollTop() {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 200);
                }

                scope.backToTop = scrollTop;

                $(window).on('scroll', onScroll);
                scope.$on('$destroy', onDestroy);

                function onScroll() {
                    if ($(window).scrollTop() > 50) {
                        element.fadeIn();
                    } else {
                        element.fadeOut();
                    }
                }

                function onDestroy() {
                    $(window).off('scroll', onScroll);
                }
            }
        };

        return directive;
    }
})();
