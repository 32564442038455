(function urlParserServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('UrlParser', UrlParser);

    /**
     * UrlParser service provides an ability to parse given urls and access
     * different parts of them.
     */
    function UrlParser() {
        this.parse = parse;

        var parser = document.createElement('a');

        /**
         * Parses a given URL and returns an objects with it's components. It
         * allows to access protocol, hostname, port, pathname, search string,
         * hash, host and separate search parameters.
         * @param  {String} url a URL to parse
         * @return {Object}
         */
        function parse(url) {
            parser.href = url;

            var result = {
                protocol: parser.protocol,
                hostname: parser.hostname,
                port: parser.port,
                pathname: parser.pathname,
                search: parser.search,
                hash: parser.hash,
                host: parser.host
            };

            var params = {};
            var search = result.search.substring(1).split('&');
            for (var i = 0, len = search.length; i < len; i++) {
                var pair = search[i].split('=');
                params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }

            result.searchParams = params;
            return result;
        }
    }
})();
