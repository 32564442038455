(function authTokenAccessorServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('AuthTokenAccessor', AuthTokenAccessor);

    /**
     * A service for accessing API authentication token
     */
    function AuthTokenAccessor($injector) {
        this.getAccessToken = getAccessToken;

        // Breaks a circular dependency
        // AuthService <- AuthTokenAccessor <- AuthTokenInterceptor <- $http <- AuthApi <- AuthService
        // However, we a not allowed to use any AuthService's methods that use $http calls
        //
        // NOTE: I may be useful to create a separate service for storing such
        // kind of data. This way there will not be circular dependencies in
        // $http service.
        var AuthService = null;
        function getAuthService() {
            if (!AuthService) {
                AuthService = $injector.get('AuthService');
            }
            return AuthService;
        }

        /**
         * Returns an API authentication token, which is currently used in the
         * application. It allows different parts of code to access the access
         * token without knowing details of authentication logic.
         */
        function getAccessToken() {
            var authData = getAuthService().getAuthData();
            if (!authData || !authData.token) {
                return null;
            }
            return authData.token;
        }
    }
})();
