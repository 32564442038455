(function applicantsRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(routingConfig);

    function routingConfig($stateProvider) {
        $stateProvider
            .state('applicants_list', {
                parent: 'app',
                url: '/applicants/?search&extended',
                templateUrl: 'hrpro/app/applicants/list.html',
                data: {
                    pageTitle: 'Кандидаты',
                    saveScrollPosition: true
                },
                resolve: {
                    extendedSearchCriteria: function extendedSearchCriteria($stateParams, SearchHelper) {
                        var extended;

                        if ($stateParams.extended) {
                            extended = SearchHelper.serializeCriteriaApi($stateParams.extended);
                        }

                        return extended;
                    },
                    applicants: function resolveApplicants(ApplicantsApi, $stateParams, ScrollPositionHolder,
                        APPLICANTS_LIST, Notify, extendedSearchCriteria
                    ) {
                        var page = ScrollPositionHolder.getData('applicants_list');
                        if (page === undefined) {
                            page = {
                                offset: 0,
                                limit: APPLICANTS_LIST.pageSize * APPLICANTS_LIST.pagesCount
                            };
                        }

                        var extended = extendedSearchCriteria;
                        var promise = ApplicantsApi.getList(undefined, page, $stateParams.search, extended);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Не удалось загрузить список кандидатов');
                        }
                    }
                },
                controller: 'ApplicantsListController',
                controllerAs: 'applicantsVM'
            })

            .state('applicants_create', {
                parent: 'app',
                url: '/applicants/add',
                templateUrl: 'hrpro/app/applicants/create.html',
                resolve: {
                    tags: function resolveTags(TagsApi, Notify) {
                        var promise = TagsApi.getList();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(reponse) {
                            Notify.error('Ошибка', 'Не удалось загрузить список тегов');
                        }
                    },
                    search_channels: function resolveSearchChannels(SearchChannelsApi) {
                        var promise = SearchChannelsApi.getList();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(reponse) {
                            Notify.error('Ошибка', 'Не удалось загрузить список каналов поиска');
                        }
                    },
                },
                data: {
                    pageTitle: 'Создание кандидата'
                },
                controller: 'ApplicantsCreateController',
                controllerAs: 'applicantVM'
            })

            .state('applicants_edit', {
                parent: 'app',
                url: '/applicants/{id:[0-9]+}?tab',
                reloadOnSearch: false,
                templateUrl: 'hrpro/app/applicants/edit.html',
                data: {
                    pageTitle: 'Редактирование кандидата'
                },
                resolve: {
                    applicant: function resolveApplicant(ApplicantsApi, $stateParams, Notify) {
                        var promise = ApplicantsApi.getOne($stateParams.id);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            if (response.status === 404) {
                                Notify.error('Ошибка', 'Такого кандидата нет на сервере');
                            } else {
                                Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
                            }
                        }
                    },
                    tags: function resolveTags(TagsApi) {
                        return TagsApi.getList();
                    },
                    history: function resolveHistory(ApplicantsApi, $stateParams, $q, Notify) {
                        // It is necessary to load history in case a target tab
                        // is History, so it will be available immediately when
                        // the page is shown to the user. In other case this data
                        // will be loaded when the History tab is activated.

                        var currentTab = $stateParams.tab || 'resume';
                        if (currentTab !== 'history') {
                            return $q.when({data: []});
                        }

                        var promise = ApplicantsApi.getHistory($stateParams.id);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
                            return $q.when({data: []});
                        }
                    },
                    ws: function resolveWs(ApplicantWebsocket, Notify) {
                        return ApplicantWebsocket
                            .connect()
                            .then(onConnectSuccess, onConnectError);

                        function onConnectSuccess() {
                            return true;
                        }

                        function onConnectError() {
                            // It has to be possible to view an applicant card if
                            // a websocket is not connected, but without
                            // editing permissions
                            Notify.error('Ошибка', 'Невозможно подключить веб-сокет');
                            return false;
                        }
                    },
                    lock: function resolveLock(ApplicantWebsocket, $stateParams, ws, Notify) {
                        if (ws === false) {
                            // It has to be possible to view an applicant card if
                            // a websocket is not connected, but without
                            // editing permissions
                            Notify.error('Ошибка', 'Невозможно узнать заблокирован ли кандидат');
                            return null;
                        }

                        return ApplicantWebsocket
                            .getLock($stateParams.id)
                            .then(onGetLockSuccess, onGetLockError);

                        function onGetLockSuccess(response) {
                            return response;
                        }

                        function onGetLockError() {
                            Notify.error('Ошибка', 'Невозможно узнать заблокирован ли кандидат');
                            return null;
                        }
                    },
                    subscription: function resolveSubscription(ApplicantWebsocket, $stateParams, $q, ws, Notify) {
                        if (ws === false) {
                            // It has to be possible to view an applicant card if
                            // a websocket is not connected, but without
                            // editing permissions
                            Notify.error('Ошибка', 'Невозможно подписаться на рассылку изменений кандидата');
                            return null;
                        }

                        try {
                            return ApplicantWebsocket.subscribe($stateParams.id);
                        } catch (error) {
                            Notify.error('Ошибка', 'Невозможно подписаться на рассылку изменений кандидата');
                            return null;
                        }
                    }
                },
                controller: 'ApplicantsEditController',
                controllerAs: 'applicantVM'
            });
    }
})();


