(function apiHelperServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApiHelper', ApiHelper);

    /**
     * A helper service, which provides useful operations for interaction with
     * an API endpoint.
     */
    function ApiHelper(API_CONFIG, $filter) {
        var dateFilter = $filter('date');

        /**
         * Constructs a URL for the given API endpoint
         * @param  {String} endpoint name of the endpoint
         * @return {String} url of the given endpoint
         */
        this.buildUrl = function buildUrl(endpoint) {
            return API_CONFIG.basePath + endpoint + '/';
        };

        /**
         * Calls a converter function for all given properties of the model
         * @param  {object} model        a model to convert
         * @param  {string[]} properties properties that have to be converted
         * @param  {function} converter  a converter function to call
         */
        this.massConversion = function massConversion(model, properties, converter) {
            for (var i = properties.length - 1; i >= 0; i--) {
                model[properties[i]] = converter(model[properties[i]]);
            }
        };

        /**
         * Converts Date object to ISO8601 representation for dates: YYYY-MM-DD
         * @param {Date} a date to convert
         * @return {String} date represented in ISO8601 format
         */
        this.toDateIso8601 = function toDateIso8601(date) {
            if (!angular.isDate(date)) {
                return date;
            } else {
                return dateFilter(date, 'yyyy-MM-dd');
            }
        };

        /**
         * Converts dates from ISO8601 representation to Date objects
         * @param {string} a date to convert
         * @return {Date} date represented as Date object
         */
        this.fromDateIso8601 = function fromDateIso8601(date) {
            if (angular.isString(date)) {
                return new Date(date);
            } else {
                return date;
            }
        };

        /**
         * Converts empty strings in model properties to null
         * @param  {Object} model an entity to normalize
         * @return {Object}       [description]
         */
        this.normalizeEmptyStrings = function normalizeEmptyStrings(model) {
            return angular.forEach(model, normalizeIterator);
        };

        /**
         * An iterator function which is intended to change all properties of
         * the object with empty strings to contain null values.
         * @param  {Mixed} value a value of the current property
         * @param  {String} key a name of the current property
         * @param  {Object} obj an original object
         */
        function normalizeIterator(value, key, obj) {
            if (value === '') {
                obj[key] = null;
            }
        }
    }
})();
