(function mainApplicantListItemIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('mainApplicantListItem', mainApplicantListItem);

    /**
     * A directive to represent an item in list of applicants. It implements
     * internal logic and data conversion to simplify processing.
     */
    function mainApplicantListItem($filter, ApplicantMarksModal, ApplicantSkillsModal, InteractionsApi,
        ApplicantsApi
    ) {
        var DATE_FORMAT = 'dd.MM.yy';
        var INTERVIEW_TAB_TYPES = [
            InteractionsApi.TYPE_INTERVIEW,
            InteractionsApi.TYPE_FEEDBACK
        ];
        var dateFilter = $filter('date');

        var directive = {
            restrict: 'A',
            templateUrl: 'hrpro/app/applicants/main-applicant-list-item.html',
            scope: {
                applicant: '='
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.$watch('applicant', function onApplicantChange(newValue, oldValue) {
                if (newValue !== undefined) {
                    init(scope);
                }
            });
        }

        function init(scope) {
            var applicant = scope.applicant;

            scope.phone = '';
            var phones = applicant.contacts.filter(contactsFilterPhone);
            if (phones.length > 0) {
                scope.phone = phones[0].value;
            } else {
                var skypes = applicant.contacts.filter(contactsFilterSkype);
                if (skypes.length > 0) {
                    scope.phone = skypes[0].value;
                }
            }

            var emails = applicant.contacts.filter(contactsFilterEmail);
            scope.email = emails[0] ? emails[0].value : '';

            scope.created = dateFilter(applicant.date_create, DATE_FORMAT);

            scope.interviewMarksShort = [];
            if (applicant.new_interview_marks) {
                scope.interviewMarksShort = applicant.new_interview_marks.slice(0, 4);
            }

            var lastInteraction = applicant.new_interactions;
            scope.lastInteractionTab = 'interactions';
            if (lastInteraction && INTERVIEW_TAB_TYPES.indexOf(lastInteraction.type) !== -1) {
                scope.lastInteractionTab = 'interviews';
            }

            scope.showMarks = showMarks;
            scope.showSkills = showSkills;
            scope.checkSkills = checkSkills;
        }

        function contactsFilterPhone(element, index, array) {
            return element.type === ApplicantsApi.CONTACT_TYPE_PHONE;
        }

        function contactsFilterEmail(element, index, array) {
            return element.type === ApplicantsApi.CONTACT_TYPE_EMAIL;
        }

        function contactsFilterSkype(element, index, array) {
            return element.type === ApplicantsApi.CONTACT_TYPE_SKYPE;
        }

        /**
         * Displays a modal window with marks of the given applicant
         */
        function showMarks(applicant) {
            ApplicantMarksModal.open(applicant);
        }

        /**
         * Displays a modal window with skills of the given applicant
         */
        function showSkills(applicant) {
            ApplicantSkillsModal.open(applicant);
        }

        function checkSkills(applicant) {
            return applicant.work_skills !== null || applicant.language_skills !== null;
        }
    }
})();
