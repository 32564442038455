(function fioWrapperIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('fioWrapper', fioWrapper);

    var DEFAULT_DELAY = 50;

    /**
     * A directive to show name and surname of the given applicant.
     * If name and surname are long, it breaks them into 2 separate lines,
     * so these fields can be word-wrapped independently.
     *
     * NOTE: Probably, this functionality can be implemented with pure CSS,
     * but, unfortunately, I wasn't able to achieve it this way in all target
     * browsers.
     */
    function fioWrapper($window) {
        var win = angular.element($window);

        var directive = {
            restrict: 'A',
            template: '<a ui-sref="applicants_edit({ id: applicant.id })"></a>',
            scope: {
                applicant: '='
            },
            link: link
        };

        var ruler = $('<span>').css({
            visibility: 'hidden',
            'white-space': 'nowrap'
        }).appendTo($('body'));

        return directive;

        function link(scope, element, attrs) {
            scope.$watch('applicant', function onChange(newValue, oldValue) {
                init(scope, element, attrs);
            });
        }

        function init(scope, element, attrs) {
            var updateTimeout = null;

            activate();

            function activate() {
                win.on('resize', updateDelayed);
                scope.$on('$destroy', onDestroy);
                updateDelayed(0);
            }

            function onDestroy() {
                win.off('resize', updateDelayed);
                clearUpdateTimeout();
            }

            function updateDelayed(delay) {
                clearUpdateTimeout();
                if (!delay && delay !== 0) {
                    delay = DEFAULT_DELAY;
                }
                updateTimeout = setTimeout(updateFio, delay);
            }

            function clearUpdateTimeout() {
                if (updateTimeout) {
                    clearTimeout(updateTimeout);
                    updateTimeout = null;
                }
            }

            function updateFio() {
                clearUpdateTimeout();

                var string = (scope.applicant.last_name || '') + ' ' + (scope.applicant.first_name || '');
                ruler.css({
                    'font-size': element.css('font-size'),
                    'font-family': element.css('font-family'),
                    'font-weight': element.css('font-weight')
                });
                ruler.html(string);

                var wrapperWidth = element.width();
                var textWidth = ruler.width();

                if (textWidth > wrapperWidth) {
                    string = string.replace(' ', '<br> ');
                }

                element.find('a').html(string);
            }
        }
    }
})();
