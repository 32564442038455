(function urlFilenameFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('urlFilename', urlFilename);

    /**
     * A filter for extracting a file name from a given URL
     */
    function urlFilename() {
        return function filter(input) {
            if (!input) {
                return '';
            }
            var parts = input.split('/');
            if (!parts.length) {
                return '';
            }
            var filename = parts[parts.length - 1];
            return decodeURIComponent(filename);
        };
    }
})();
