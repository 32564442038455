(function httpErrorsLoggerInterceptorServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('HttpErrorsLoggerInterceptor', HttpErrorsLoggerInterceptor);

    var STORAGE_KEY = 'HttpErrorsLoggerInterceptor';
    var LIMIT = 25;

    function HttpErrorsLoggerInterceptor($log, $q, dateFilter) {
        var storage = null;

        this.responseError = responseError;

        activate();

        function activate() {
            storage = getStorage();
            if (storage === null) {
                $log.error('HttpErrorsLoggerInterceptor: storage is not available');
            } else {
                window.HttpErrorsLoggerInterceptor = {
                    showErrors: showErrors,
                    clearErrors: clearErrors
                };
                $log.info('HttpErrorsLoggerInterceptor is now available on the window object');
            }
        }

        function getStorage() {
            try {
                var storage = window.localStorage,
                    x = '__storage_test__';
                storage.setItem(x, x);
                storage.removeItem(x);
                return storage;
            } catch (e) {
                return null;
            }
        }

        /**
         * Shows history of HTTP errors
         */
        function showErrors() {
            if (storage !== null) {
                var errorsString = storage.getItem(STORAGE_KEY) || '[]';
                var errors = angular.fromJson(errorsString);
                for (var i = 0, len = errors.length; i < len; i++) {
                    $log.log('[', i, ']:', errors[i]);
                }
            } else {
                $log.error('HttpErrorsLoggerInterceptor: storage is not available');
            }
        }

        /**
         * Removes a history of HTTP errors
         */
        function clearErrors() {
            if (storage !== null) {
                storage.removeItem(STORAGE_KEY);
            } else {
                $log.error('HttpErrorsLoggerInterceptor: storage is not available');
            }
        }

        /**
         * Handles HTTP response errors
         * @param  {Response} response $http response object
         * @return {Promise} a new response promise
         */
        function responseError(response) {
            if (storage !== null) {
                var now = new Date();
                var error = {
                    date: dateFilter(now, 'yyyy-MM-ddThh:mm:ss'),
                    response: response
                };

                var errorsString = storage.getItem(STORAGE_KEY) || '[]';
                var errors = angular.fromJson(errorsString);
                errors.push(error);
                if (errors.length > LIMIT) {
                    errors.shift();
                }
                errorsString = angular.toJson(errors);
                storage.setItem(STORAGE_KEY, errorsString);
            }
            return $q.reject(response);
        }
    }
})();
