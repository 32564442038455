(function photoUploadModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('PhotoUploadModal', PhotoUploadModal);

    /**
     * A service for displaying a modal window for uploading a photo of an applicant.
     */
    function PhotoUploadModal($uibModal) {
        this.open = function open(applicant) {
            return $uibModal.open({
                templateUrl: 'hrpro/app/applicants/modals/photo-upload/upload-modal.html',
                controller: 'PhotoUploadModalController',
                controllerAs: 'modal',
                backdrop: 'static',
                keyboard: false
            }).result;
        };
    }
})();
