(function duplicatesListDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('duplicatesList', duplicatesList);

    var INPUT_TIMEOUT = 500;
    var MIN_LENGTH = 3;

    function duplicatesList($timeout) {
        var directive = {
            restrict: 'A',
            templateUrl: function templateUrl(element, attrs) {
                if (!attrs.templateUrl) {
                    throw new Error('A templateURL is not specified');
                } else {
                    return attrs.templateUrl;
                }
            },
            scope: {
                searchMethod: '&',
                model: '=watchModel',
                attributes: '=watchAttributes'
            },
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            // A timeout to reduce an amount of requests when user types
            var timeoutPromise = null;

            // A promise returned by last search call to prevent race condition
            var lastSearchPromise = null;

            var attributesGroup = [];
            for (var i = 0, len = scope.attributes.length; i < len; i++) {
                attributesGroup.push('model.' + scope.attributes[i]);
            }

            scope.$watchGroup(attributesGroup, onModelChange);
            scope.duplicates = [];

            function onModelChange(value) {
                if (timeoutPromise) {
                    $timeout.cancel(timeoutPromise);
                    timeoutPromise = null;
                }

                lastSearchPromise = null;

                // Determine whether we have to perform search
                var attr;
                var attrsLength = [];
                for (var i = 0, len = scope.attributes.length; i < len; i++) {
                    attr = scope.model[scope.attributes[i]];
                    if (attr) {
                        attrsLength.push(attr.length);
                    }
                }

                if (attrsLength.length === 0) {
                    scope.duplicates = [];
                    return;
                } else if (attrsLength.length === 1 && attrsLength[0] < MIN_LENGTH) {
                    scope.duplicates = [];
                    return;
                }

                timeoutPromise = $timeout(onInputTimeout, INPUT_TIMEOUT);
            }

            function onInputTimeout() {
                timeoutPromise = null;
                var promise = scope.searchMethod();
                lastSearchPromise = promise;

                promise.then(function onSearchSuccess(results) {
                    handleSearchResponse(promise, results);
                }, function onSearchError() {
                    handleSearchResponse(promise, []);
                });
            }

            function handleSearchResponse(requestPromise, data) {
                if (requestPromise !== lastSearchPromise) {
                    return;
                }

                scope.duplicates = data;
                lastSearchPromise = null;
            }
        }
    }
})();
