(function defaultValueModelDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('defaultValueModel', defaultValueModel);

    function defaultValueModel($parse) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            scope: {
                defaultValueModel: '='
            },
            link: function link(scope, element, attrs, ngModelCtrl) {
                var isChangedByUser = false;

                element.on('input', onFieldChange);
                scope.$on('$destroy', onDestroy);
                scope.$watch('defaultValueModel', onDefaultChanged);
                ngModelCtrl.$formatters.unshift(onModelChange);

                function onDefaultChanged(newValue) {
                    if (!isChangedByUser) {
                        ngModelCtrl.$setViewValue(newValue);
                        ngModelCtrl.$render();
                    }
                }

                function onDestroy() {
                    element.off('input');
                }

                function onFieldChange(evt) {
                    isChangedByUser = true;
                }

                function onModelChange(value) {
                    if (value !== undefined && value !== '') {
                        isChangedByUser = true;
                    }

                    return value;
                }
            }
        };

        return directive;
    }
})();
