(function applicantMarksModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApplicantMarksModal', ApplicantMarksModal);

    /**
     * A service for displaying a modal window with marks of the given
     * applicant. It is needed to prevent possible code duplication and
     * encapsulate parameters of the modal window.
     */
    function ApplicantMarksModal($uibModal) {
        this.open = function open(applicant) {
            return $uibModal.open({
                templateUrl: 'hrpro/app/applicants/modals/applicant-details/marks-modal.html',
                controller: 'ApplicantDetailsModalController',
                controllerAs: 'modal',
                resolve: {
                    applicant: function resolveApplicant() {
                        return applicant;
                    }
                },
                keyboard: false
            }).result;
        };
    }
})();
