(function vacanciesRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(routingConfig);

    function routingConfig($stateProvider) {
        $stateProvider
            .state('vacancies_list', {
                parent: 'app',
                url: '/vacancies/?tab',
                reloadOnSearch: false,
                templateUrl: 'hrpro/app/vacancies/list.html',
                data: {
                    pageTitle: 'Вакансии'
                },
                resolve: {
                    vacancies: function resolveVacancies(VacanciesApi, Notify) {
                        var promise = VacanciesApi.getList();
                        promise.then(undefined, onLoadError);
                        return promise;

                        function onLoadError() {
                            Notify.error('Ошибка', 'Не удалось загрузить список вакансий');
                        }
                    }
                },
                controller: 'VacanciesListController',
                controllerAs: 'vacanciesVM'
            })

            .state('vacancies_create', {
                parent: 'app',
                url: '/vacancies/add',
                templateUrl: 'hrpro/app/vacancies/create.html',
                data: {
                    pageTitle: 'Создание вакансии'
                },
                controller: 'VacanciesCreateController',
                controllerAs: 'vacancyVM'
            })

            .state('vacancies_edit', {
                parent: 'app',
                url: '/vacancies/{id:[0-9]+}',
                templateUrl: 'hrpro/app/vacancies/edit.html',
                data: {
                    pageTitle: 'Редактирование вакансии'
                },
                resolve: {
                    vacancy: function resolveVacancy(VacanciesApi, $stateParams, Notify) {
                        var promise = VacanciesApi.getOne($stateParams.id);
                        promise.then(undefined, onLoadError);
                        return promise;

                        function onLoadError() {
                            Notify.error('Ошибка', 'Не удалось загрузить информацию про вакансию');
                        }
                    }
                },
                controller: 'VacanciesEditController',
                controllerAs: 'vacancyVM'
            });
    }
})();
