(function applicantWebsocketServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ApplicantWebsocket', ApplicantWebsocket);

    /**
     * A service which is responsible for interaction with an applicant locking
     * WebSocket endpoint.
     */
    function ApplicantWebsocket(WEBSOCKET_CONFIG, $q, $rootScope, CurrentUser, Identity, $timeout) {
        var LOCK_CHANGE_HANDLERS = {
            locked: handleMessageLocked,
            unlocked: handleMessageUnlocked,
            attempt: handleMessageAttempt,
            deleted: handleMessageDeleted
        };

        var service = this;
        var socket = null;

        var eventBaseName = 'ApplicantWebsocket:';
        this.EVENT_CONNECTED = eventBaseName + 'connected';
        this.EVENT_DISCONNECTED = eventBaseName + 'disconnected';
        this.EVENT_MESSAGE = eventBaseName + 'message';
        this.MESSAGE_LOCKED = 'locked';
        this.MESSAGE_UNLOCKED = 'unlocked';
        this.MESSAGE_ATTEMPT = 'attempt';
        this.MESSAGE_CHANGED = 'changed';
        this.MESSAGE_DIE = 'die';
        this.MESSAGE_DELETED = 'deleted';

        this.connect = connect;
        this.disconnect = disconnect;
        this.dropLock = sendDropLock;
        this.getLock = sendGetLock;
        this.putLock = sendPutLock;
        this.subscribe = sendSubscribe;

        function connect() {
            if (socket !== null) {
                return $q.resolve();
            }

            var deferred = $q.defer();

            var path = WEBSOCKET_CONFIG.basePath;
            socket = io.connect(path, {
                'force new connection': true,
                reconnection: WEBSOCKET_CONFIG.reconnection,
                reconnectionDelay: WEBSOCKET_CONFIG.reconnectionDelay,
                reconnectionDelayMax: WEBSOCKET_CONFIG.reconnectionDelayMax,
                reconnectionAttempts: WEBSOCKET_CONFIG.reconnectionAttempts
            });

            socket.addListener('connect', onConnect);
            socket.addListener('error', onError);
            socket.addListener('reconnect_failed', onReconnectFailed);

            return deferred.promise;

            function clearConnectionListeners() {
                socket.removeListener('connect', onConnect);
                socket.removeListener('reconnect_failed', onReconnectFailed);
            }

            function onConnect() {
                clearConnectionListeners();

                socket.addListener('disconnect', onSocketDisconnect);

                deferred.resolve();
                $rootScope.$emit(service.EVENT_CONNECTED);
            }

            function onError(error) {
                clearConnectionListeners();
                socket = null;
                deferred.reject(error);
            }

            function onReconnectFailed(error) {
                clearConnectionListeners();
                socket = null;
                deferred.reject(error);
            }
        }

        function disconnect() {
            if (socket === null) {
                return;
            }

            socket.disconnect();
        }

        function emitMessage(type, data) {
            var payload = { type: type, message: data };
            $rootScope.$apply(emit);

            function emit() {
                $rootScope.$emit(service.EVENT_MESSAGE, payload);
            }
        }

        function handleMessageDeleted(response) {
            if (!Identity.isSameTab(response.tab_id)) {
                emitMessage(service.MESSAGE_DELETED, response);
            }
        }

        function handleMessageLocked(response) {
            if (!Identity.isSameTab(response.tab_id)) {
                emitMessage(service.MESSAGE_LOCKED, response);
            }
        }

        function handleMessageUnlocked(response) {
            if (Identity.isSameTab(response.tab_id)) {
                if (response.external === true) {
                    emitMessage(service.MESSAGE_DIE, response);
                }
            } else {
                if (response.changed === true) {
                    emitMessage(service.MESSAGE_CHANGED, response);
                } else {
                    emitMessage(service.MESSAGE_UNLOCKED, response);
                }
            }
        }

        function handleMessageAttempt(response) {
            if (Identity.isSameTab(response.tab_id)) {
                emitMessage(service.MESSAGE_ATTEMPT, response);
            }
        }

        function onMessageApplicantChanged(response) {
            if (response === 1) {
                return;
            }

            if (!Identity.isSameTab(response.tab_id)) {
                emitMessage(service.MESSAGE_CHANGED, response);
            }
        }

        function onMessageLockChange(response) {
            if (response === 1) {
                return;
            }

            if (LOCK_CHANGE_HANDLERS[response.status]) {
                LOCK_CHANGE_HANDLERS[response.status](response);
            } else {
                throw new Error('Unknown status: ' + response.status);
            }
        }

        function onSocketDisconnect() {
            socket.removeListener('disconnect', onSocketDisconnect);
            socket.removeListener('response_subscribe', onMessageLockChange);
            socket = null;

            $rootScope.$emit(service.EVENT_DISCONNECTED);
        }

        function request(requestType, responseType, params) {
            if (socket === null) {
                throw new Error('Socket is not connected to the server');
            }

            var deferred = $q.defer();

            var timeout = $timeout(onTimeout, WEBSOCKET_CONFIG.timeout);
            socket.addListener(responseType, onResponse);
            socket.emit(requestType, params);

            function onResponse(response) {
                $timeout.cancel(timeout);
                socket.removeListener(responseType, onResponse);
                deferred.resolve(response);
            }

            function onTimeout() {
                $timeout.cancel(timeout);
                socket.removeListener(responseType, onResponse);
                deferred.reject();
            }

            return deferred.promise;
        }

        function sendDropLock(applicantId, force) {
            return request('drop_lock', 'response_drop_lock', {
                applicant_id: applicantId,
                user_id: CurrentUser.getProfile().user_id,
                tab_id: Identity.getTabId(),
                force: force
            });
        }

        function sendGetLock(applicantId) {
            return request('get_lock', 'response_get_lock', applicantId);
        }

        function sendPutLock(applicantId) {
            return request('lock', 'response_lock', {
                applicant_id: applicantId,
                user_id: CurrentUser.getProfile().user_id,
                tab_id: Identity.getTabId()
            });
        }

        function sendSubscribe(applicantId) {
            if (socket === null) {
                throw new Error('Socket is not connected to the server');
            }

            socket.addListener('response_subscribe', onMessageLockChange);
            socket.addListener('response_changing', onMessageApplicantChanged);
            socket.emit('subscribe', applicantId);
        }
    }
})();
