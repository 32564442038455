(function applicantInteractionsActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantInteractionsActionsController', ApplicantInteractionsActionsController);

    /**
     * A controller which is responsible for managing interaction with
     * an applicant.
     */
    function ApplicantInteractionsActionsController(
        $scope, Notify, ConfirmDeletionModal, CommonInteractionsModal, InteractionsApi, ApplicantHelper,
        $rootScope, ApplicantWebsocket
    ) {
        var vm = this;
        var mainVM = null;
        var activeInteraction = null;
        var lastVacancyInteraction = null;
        var isLockedLocally = false;
        var modal = null;
        var unbindWsListener = null;

        vm.api = InteractionsApi;
        vm.applicant = $scope.applicantVM.model;

        vm.remove = remove;
        vm.edit = edit;
        vm.openModal = openModal;

        activate();

        $scope.$watch('applicantVM.model', onModelReady);
        $scope.$on('$destroy', onDestroy);
        unbindWsListener = $rootScope.$on(ApplicantWebsocket.EVENT_MESSAGE, onWsMessage);

        function activate() {
            mainVM = $scope.applicantVM;
        }

        function runWithLocalLock(callback, parameters) {
            isLockedLocally = false;

            if (!mainVM.isApplicantLocked()) {
                mainVM
                    .lockApplicant()
                    .then(function onLockSuccess() {
                        mainVM.disableModelWatcher();
                        isLockedLocally = true;
                        callback.apply(null, parameters);
                    });
            } else {
                mainVM.disableModelWatcher();
                callback.apply(null, parameters);
            }
        }

        function dropLocalLock() {
            if (isLockedLocally) {
                mainVM.enableModelWatcher();
                mainVM.unlockApplicant();
            }
        }

        function edit(interaction) {
            runWithLocalLock(beginEdit, arguments);
        }

        function beginEdit(interaction) {
            activeInteraction = interaction;
            var copy = angular.copy(interaction);

            vm.isOpenModal = true;

            modal = CommonInteractionsModal.open(copy, vm.applicant, mainVM);
            modal.result.then(onEditSuccess, onEditReject);
            modal.closed.then(onModalClosed);
        }

        function onEditSuccess(interaction) {
            interaction = angular.merge(activeInteraction, interaction);

            if (interaction.vacancy) {
                lastVacancyInteraction = ApplicantHelper.getLastInteractionWithVacancy(vm.applicant.new_interactions);
                if (lastVacancyInteraction === interaction) {
                    vm.applicant.vacancy = interaction.vacancy;
                }
            }
            vm.isOpenModal = false;
            dropLocalLock();
        }

        function onEditReject() {
            vm.isOpenModal = false;
            dropLocalLock();
        }

        function openModal(type) {
            runWithLocalLock(beginCreate, arguments);
        }

        function beginCreate(type) {
            var model = InteractionsApi.getModel(type);
            model.applicant = vm.applicant.id;
            model.vacancy = vm.applicant.vacancy;

            modal = CommonInteractionsModal.open(model, vm.applicant, mainVM);
            modal.result.then(onCreateSuccess, onCreateReject);
            modal.closed.then(onModalClosed);
        }

        function onCreateSuccess(interaction) {
            if (interaction.vacancy) {
                vm.applicant.vacancy = interaction.vacancy;
                $scope.applicantVM.activeVacancy = false;
            }
            vm.applicant.new_interactions.push(interaction);
            dropLocalLock();
        }

        function onCreateReject() {
            dropLocalLock();
        }

        function remove(interaction) {
            runWithLocalLock(beginRemove, arguments);
        }

        function beginRemove(interaction) {
            activeInteraction = interaction;

            modal = ConfirmDeletionModal.open('Вы уверены в том, что хотите удалить взаимодействие?');
            modal.result.then(onDeletionConfirmed, onDeletionRejected);
            modal.closed.then(onModalClosed);
        }

        function onDeletionConfirmed() {
            InteractionsApi
                .remove(activeInteraction.id)
                .then(onDeleteSuccess, onDeleteError);
        }

        function onDeletionRejected() {
            dropLocalLock();
        }

        function onDeleteSuccess(response) {
            if (activeInteraction.type === vm.api.TYPE_INTERVIEW) {
                if (activeInteraction.feedback !== null) {
                    var feedbackId = activeInteraction.feedback.id;
                    vm.applicant.new_interactions.forEach(function feedbackCleaner(elem, index, arr) {
                        if (elem.id === feedbackId) {
                            vm.applicant.new_interactions.splice(index, 1);
                        }
                    });
                }
            }

            var index = vm.applicant.new_interactions.indexOf(activeInteraction);

            vm.applicant.new_interactions.splice(index, 1);
            Notify.success('Удалено', 'Взаимодействие успешно удалено с сервера');

            var lastVacancyInteraction = ApplicantHelper.getLastInteractionWithVacancy(vm.applicant.new_interactions);
            if (lastVacancyInteraction === null) {
                $scope.applicantVM.activeVacancy = true;
            } else {
                vm.applicant.vacancy = lastVacancyInteraction.vacancy;
            }

            dropLocalLock();
        }

        function onDeleteError(response) {
            Notify.error('Ошибка', 'Взаимодействие не удалось удалить с сервера');
            dropLocalLock();
        }

        function onDestroy() {
            unbindWsListener();
        }

        function onModalClosed() {
            modal = null;
            vm.isOpenModal = false;
        }

        function onWsMessage(event, message) {
            if (message.type !== ApplicantWebsocket.MESSAGE_DIE) {
                return;
            }

            if (modal !== null) {
                modal.dismiss('die');
            }
        }

        function onModelReady(newValue, oldValue, scope) {
            if (newValue === oldValue) {
                return;
            }

            vm.applicant = $scope.applicantVM.model;
        }
    }
})();

