(function MasterApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('MasterApi', MasterApi);

    /**
     * Provides an ability to interact with the masters related endpoints of the API
     */
    function MasterApi(ApiHelper, $http) {
        this.getList = getList;
        this.getMaster = getMaster;
        this.getModel = getModel;

        function getList(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('masters'),
                context: context
            };
            return $http(config);
        }

        function getMaster(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('master'),
                context: context
            };
            return $http(config);
        }

                /**
         * Constructs a new HrUser model
         * @return {Object}
         */
        function getModel() {
            return {
                hrUsers: []
            };
        }
    }
})();
