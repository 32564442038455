(function calendarRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(routingConfig)
        .run(calendarWorkflowConfig);

    function calendarWorkflowConfig($state, $rootScope, dateFilter) {
        $rootScope.$on('$stateChangeStart', onStateChangeStart);

        /**
         * By default, a calendar for the current date has to be shown
         */
        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if (toState.name === 'calendar') {
                event.preventDefault();
                $state.go('calendar_day', { date: dateFilter(new Date(), 'yyyy-MM-dd') });
            } else if (toState.name === 'calendar_day' || toState.name === 'calendar_week') {
                if (!toParams.date) {
                    event.preventDefault();
                    $state.go(toState.name, {
                        date: dateFilter(new Date(), 'yyyy-MM-dd')
                    }, {
                        location: 'replace'
                    });
                }
            }
        }
    }

    function routingConfig($stateProvider) {
        $stateProvider
            .state('calendar', {
                parent: 'app',
                url: '/calendar',
                templateUrl: 'hrpro/app/calendar/calendar.html',
                controller: 'CalendarController',
                controllerAs: 'calendarVM'
            })

            .state('calendar_day', {
                parent: 'calendar',
                url: '/day/:date',
                templateUrl: 'hrpro/app/calendar/day.html',
                data: {
                    pageTitle: 'Календарь на день'
                },
                resolve: {
                    calendar: function resolveCalendar(CalendarApi, $stateParams, Notify) {
                        var date = new Date();
                        if ($stateParams.date) {
                            date = new Date($stateParams.date);
                        }

                        var promise = CalendarApi.getDate(date);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить календарь на выбранную дату');
                        }
                    }
                },
                controller: 'CalendarDayController',
                controllerAs: 'dayVM'
            })

            .state('calendar_week', {
                parent: 'calendar',
                url: '/week/:date',
                templateUrl: 'hrpro/app/calendar/week.html',
                data: {
                    pageTitle: 'Календарь на неделю'
                },
                resolve: {
                    calendar: function resolveCalendar(CalendarApi, $stateParams, Notify) {
                        var date = new Date();
                        if ($stateParams.date) {
                            date = new Date($stateParams.date);
                        }

                        var promise = CalendarApi.getWeek(date);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить календарь на выбранную дату');
                        }
                    }
                },
                controller: 'CalendarWeekController',
                controllerAs: 'weekVM'
            });
    }
})();
