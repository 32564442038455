(function vacanciesApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('TagsApi', TagsApi);

    /**
     * Provides an ability to interact with the tags related endpoints on the API
     */
    function TagsApi(ApiHelper, $http) {
        this.getList = getList;

        function getList(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('tags'),
                context: context
            };
            return $http(config);
        }
    }
})();
