(function autoselectDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('autoselect', autoselect);

    function autoselect() {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, element, attrs, ngModel) {
            scope.$on('autoselect:select', select);

            function select() {
                var input = element[0];
                input.focus();
                input.setSelectionRange(0, input.value.length);
            }
        }
    }
})();
