(function confirmSavingModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ConfirmSavingModalController', ConfirmSavingModalController);

    /**
     * A controller of the modal which a request to confirm saving operation
     */
    function ConfirmSavingModalController($uibModalInstance, message, context, $scope) {
        var vm = this;

        vm.message = message;

        vm.save = function save() {
            $uibModalInstance.close(context);
        };

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss(context);
        };
    }
})();
