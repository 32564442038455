(function interactionsReportControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('InteractionsReportController', InteractionsReportController);

    function InteractionsReportController(dateStart, dateEnd, data, hrUsers, hrUsersFilter, currentUser, $state, dateFilter, MasterApi) {
        var vm = this;

        vm.model = null;
        vm.data = null;
        vm.dates = null;
        vm.hrUsers = hrUsers.data.filter(user => user.is_active && !user.groups.find(group => group.name === 'Interviewer'));
        vm.is_staff = currentUser.data[0].is_staff;
        vm.blocked = false;

        vm.build = build;

        activate();

        function activate() {
            var sessionStartDate = sessionStorage.getItem('startDate');
            var sessionEndDate = sessionStorage.getItem('endDate');

            vm.model = MasterApi.getModel();
            vm.data = data.data;

            vm.dates = {
                start: sessionStartDate ? new Date(sessionStartDate) : dateStart,
                end: sessionEndDate ? new Date(sessionEndDate) : dateEnd
            };
            vm.model.hrUsers = vm.hrUsers.filter(function(item) {
                return hrUsersFilter.includes(item.id.toString());
            });
        }

        function build() {
            var users = [];
            for (var i = 0; i < vm.model.hrUsers.length; i++) {
                users.push(vm.model.hrUsers[i].id);
            }

            sessionStorage.setItem('startDate', vm.dates.start);
            sessionStorage.setItem('endDate', vm.dates.end);

            $state.go('report_interactions', {
                start: dateFilter(new Date(sessionStorage.getItem('startDate')), 'yyyy-MM-dd'),
                end: dateFilter(new Date(sessionStorage.getItem('endDate')), 'yyyy-MM-dd'),
                hrUsers: users.join(',')
            }, { reload: true });
        }
    }
})();
