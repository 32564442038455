(function interviewListItemDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('interviewListItem', interviewListItem);

    /**
     * A directive which handles a row in a list of interviews with an applicant
     */
    function interviewListItem(Notify, clipboard, CalendarApi, InteractionsApi, $rootScope,
        ClipboardSupportModal, CalendarHelper
    ) {
        var directive = {
            restrict: 'A',
            templateUrl: 'hrpro/app/applicants/interview-list-item.html',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            scope.masters = [];
            scope.skills = [];
            scope.requiredFeedback = scope.interview.feedback !== null;

            scope.copyMarks = function copyMarks() {
                var text = '';
                var marks = scope.interview.marks;
                for (var i = 0, len = marks.length; i < len; i++) {
                    text += marks[i].skill_name + ': '  + marks[i].mark + '\n';
                }
                try {
                    clipboard.copyText(text);
                    Notify.success('Скопировано', 'Оценки успешно скопированы');
                } catch (error) {
                    ClipboardSupportModal.open(text);
                }
            };

            scope.onInterviewChange = function onInterviewChange() {
                handleInterviewChange(scope);
            };

            scope.$watch('interviewsVM.masters.length', initMasters);
            scope.$watch('interviewsVM.skills.length', initSkills);
        }

        function initMasters(newValue, oldValue, scope) {
            if (newValue === undefined) {
                return;
            }

            // This block of code is needed to initialize ui-select correctly.
            // ui-select doesn't exclude already selected items from the list
            // of available options if they came from another source
            scope.masters = scope.interviewsVM.masters.slice();
            for (var i = 0, mLen = scope.masters.length; i < mLen; i++) {
                for (var j = 0, iLen = scope.interview.master.length; j < iLen; j++) {
                    if (scope.masters[i].id === scope.interview.master[j].id) {
                        scope.masters[i] = scope.interview.master[j];
                        break;
                    }
                }
            }
        }

        function initSkills(newValue, oldValue, scope) {
            if (newValue === undefined) {
                return;
            }

            // This block of code is needed to initialize ui-select correctly.
            // ui-select doesn't exclude already selected items from the list
            // of available options if they came from another source.
            // Also, by replacing models in such way it becomes easier to
            // select existing skills from the list.

            scope.skills = [];

            var skill;
            var initialSkills = scope.interviewsVM.skills;
            var marks = scope.interview.marks;
            for (var i = 0, len = initialSkills.length; i < len; i++) {
                skill = findMarkBySkill(marks, initialSkills[i]);
                if (skill === null) {
                    skill = {
                        interview: scope.interview.id,
                        skill: initialSkills[i].id,
                        skill_name: initialSkills[i].name,
                        weight: initialSkills[i].weight,
                        mark: 0
                    };
                }
                scope.skills.push(skill);
            }
        }

        function findMarkBySkill(marks, skill) {
            for (var i = 0, len = marks.length; i < len; i++) {
                if (marks[i].skill === skill.id) {
                    return marks[i];
                }
            }
            return null;
        }

        function handleInterviewChange(scope) {
            var form = scope.interviewForm;
            var interview = scope.interview;
            var interviews = scope.interviewsVM.interviews;

            form.interviewDate.$setValidity('intersection', true);
            form.interviewDate.$setValidity('googleEvent', true);
            form.interviewDate.$setValidity('request', true);

            if (interview.held) {
                return;
            }

            // Check intersections with other interviews of the same applicant.
            // It is necessary to accomplish this locally, because the server
            // may not know of all changes which the user has already made, but
            // not saved yet.
            for (var i = interviews.length - 1; i >= 0; i--) {
                if (isInteractionIntersection(interviews[i], interview, true)) {
                    form.interviewDate.$setValidity('intersection', false);
                    form.interviewDate.intersection = interviews[i];
                    return;
                }
            }

            // Check intersections with other users
            CalendarApi
                .getDate(interview.date, {
                    interview: interview,
                    form: form
                })
                .then(onDateGetSuccess, onDateGetError);
            CalendarApi
                .getGoogleDateEvents(interview.date, {
                    interview: interview,
                    form: form
                })
                .then(onGoogleDateEventsGetSuccess, onGoogleDateEventsGetError);
        }

        function onDateGetSuccess(response) {
            var interview = response.config.context.interview;
            var form = response.config.context.form;
            var interactions = response.data.notification;

            for (var i = 0, len = interactions.length; i < len; i++) {
                if (isInteractionIntersection(interactions[i], interview, false)) {
                    form.interviewDate.$setValidity('intersection', false);
                    form.interviewDate.intersection = interactions[i];
                    return;
                }
            }
        }

        function onDateGetError(response) {
            var form = response.config.context.form;
            form.interviewDate.$setValidity('request', false);
            Notify.error('Ошибка', 'Невозможно проверить дату собеседования');
        }

        function onGoogleDateEventsGetSuccess(response) {
            var interview = response.config.context.interview;
            var form = response.config.context.form;
            var events = response.data.events;

            for (var i = 0, len = events.length; i < len; i++) {
                if (isEventIntersection(events[i], interview)) {
                    form.interviewDate.$setValidity('googleEvent', false);
                    form.interviewDate.googleEvent = events[i];
                    return;
                }
            }
        }

        function onGoogleDateEventsGetError(response) {
            var form = response.config.context.form;
            form.interviewDate.$setValidity('request', false);
            Notify.error('Ошибка', 'Невозможно проверить дату собеседования в Google календаре');
        }

        function isInteractionIntersection(interaction, target, checkSameApplicant) {
            if (interaction.type !== InteractionsApi.TYPE_INTERVIEW) {
                return false;
            }

            if (interaction.held === true) {
                return false;
            }

            if (interaction.id === target.id) {
                return false;
            }

            var interactionDate = new Date(interaction.date);
            if (interactionDate.getTime() !== target.date.getTime()) {
                return false;
            }

            if(interaction.room.id !== target.room) {
                return false;
            }

            if (interaction.applicant_id === target.applicant) {
                // If checkSameApplicant is true, we have to check intersections
                // between interviews with the same applicant. In this case
                // several interviews with the same time are not allowed to
                // be assigned to the same user. This is an intersection.
                // If checkSameApplicant is false, we have already checked
                // intersections between interviews of the same applicant. In
                // this case we just don't care about this interview - it will
                // be changed during the next Save operation anyway.
                return checkSameApplicant === true;
            }

            var i, ilen, j, jlen, master;
            for (i = 0, ilen = target.master.length; i < ilen; i++) {
                if (interaction.master_name !== undefined) {
                    // Testing against remote data from calendar
                    master = (target.master[i].first_name || '') + ' ' + (target.master[i].last_name || '');
                    for (j = 0, jlen = interaction.master_name.length; j < jlen; j++) {
                        if (master === interaction.master_name[j]) {
                            return true;
                        }
                    }
                } else {
                    // Testing against local data
                    for (j = 0, jlen = interaction.master.length; j < jlen; j++) {
                        if (target.master[i].id === interaction.master[j].id) {
                            return true;
                        }
                    }
                }
            }

            return false;
        }

        function isEventIntersection(event, target) {
            if (event.room.id !== target.room) {
                return false;
            }

            var startTime = new Date(event.start.date + ' ' + event.start.time + 'Z');
            var endTime = new Date(event.end.date + ' ' + event.end.time + 'Z');
            var targetTime = new Date(target.date);
            var hour = targetTime.getHours();
            var minute = targetTime.getMinutes();
            var timeSpans = CalendarHelper.getTimeSpan(startTime, endTime);
            var i, ilen = timeSpans.length;
            for(i = 0; i < ilen; i++) {
                if(timeSpans[i].date === hour && timeSpans[i].time === minute) {
                    return true;
                }
            }
            return false;
        }
    }
})();
