(function cvUploadModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('CvUploadModalController', CvUploadModalController);

    /**
     * A controller of the modal which loads a CV of the current applicant
     */
    function CvUploadModalController($uibModalInstance, FileUploadApi, Notify, $scope) {
        var vm = this;

        vm.progress = null;
        vm.file = null;

        vm.onFileChange = onFileChange;

        vm.close = function close() {
            $uibModalInstance.dismiss('cancel');
        };

        function onFileChange(file) {
            if (!file) {
                return;
            }

            vm.progress = 0;

            FileUploadApi
                .uploadCV(file)
                .then(onCvLoadSuccess, onCvLoadError, onCvLoadProgress);
        }

        function onCvLoadSuccess(response) {
            vm.progress = null;
            $uibModalInstance.close(response);
        }

        function onCvLoadError(response) {
            vm.progress = null;
            Notify.error('Ошибка', 'При загрузке резюме на сервер произошла ошибка');
        }

        function onCvLoadProgress(event) {
            var progress = parseInt(100.0 * event.loaded / event.total);
            vm.progress = progress;
        }
    }
})();
