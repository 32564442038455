(function currentUserFieldDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('currentUserField', currentUserField);

    /**
     * Directive for displaying a field from a profile of the current user
     */
    function currentUserField(CurrentUser, $rootScope) {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var field = attrs.currentUserField;

            $rootScope.$on(CurrentUser.EVENT_PROFILE_CHANGED, showUserField);

            showUserField();

            function showUserField() {
                var profile = CurrentUser.getProfile();
                if (profile) {
                    element.html(profile[field]);
                }
            }
        }
    }
})();
