(function notifyServiceIIFE() {
    'use strict';

    // This service is a wrapper around the angular-toastr module

    angular
        .module('hrpro')
        .service('Notify', Notify);

    /**
     * Notify service provides an ability to show notification messages in UI.
     * It wraps angular-toastr service and extends its functionality in order
     * to make it possible to use angular stuff in notification title and
     * message.
     */
    function Notify(toastr, $compile) {
        this.success = function success(title, message, options) {
            return createNotification('success', title, message, options);
        };

        this.warning = function warning(title, message, options) {
            return createNotification('warning', title, message, options);
        };

        this.error = function error(title, message, options) {
            return createNotification('error', title, message, options);
        };

        this.info = function info(title, message, options) {
            return createNotification('info', title, message, options);
        };

        this.close = function close(notification) {
            toastr.clear(notification);
        };

        this.closeAll = function closeAll() {
            toastr.clear();
        };

        function createNotification(type, title, message, options) {
            options = options || {};

            var initialOnShown = options.onShown;

            options.onShown = function onShown(toast) {
                if (options.compileTitle) {
                    $compile(toast.el.find('.toast-title').contents())(toast.scope);
                }

                if (options.compileMessage) {
                    $compile(toast.el.find('.toast-message').contents())(toast.scope);
                }

                if (angular.isFunction(initialOnShown)) {
                    initialOnShown(toast);
                }
            };

            return toastr[type](message, title, options);
        }
    }
})();
