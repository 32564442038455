(function searchResultsHolderIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('SearchResultsHolder', SearchResultsHolder);

    var EVENT_UPDATE = 'SearchResultsHolder:update';
    var EVENT_RESET = 'SearchResultsHolder:reset';

    /**
     * A service for storing data related to search results.
     */
    function SearchResultsHolder($rootScope) {
        this.EVENT_UPDATE = EVENT_UPDATE;
        this.EVENT_RESET = EVENT_RESET;

        var resultsCount = null;

        this.setResultsCount = function setResultsCount(count) {
            resultsCount = count;
            if (count !== null) {
                $rootScope.$emit(this.EVENT_UPDATE, resultsCount);
            } else {
                $rootScope.$emit(this.EVENT_RESET);
            }
        };

        this.getResultsCount = function getResultsCount() {
            return resultsCount;
        };
    }
})();
