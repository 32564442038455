(function InterviewInteractionModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('InterviewInteractionModalController', InterviewInteractionModalController);

    /**
     * A controller of the modal window which creates a new Interview interaction.
     */
    function InterviewInteractionModalController(
        $uibModalInstance, Notify, InteractionsApi, model, applicant, mainVM, $scope, CalendarApi,
        CalendarHelper, CurrentUser, ROOMS
    ) {
        var vm = this;

        vm.data = {};
        vm.activeMasters = [];
        vm.vacancy = null;
        vm.masters = [];
        vm.rooms = ROOMS;
        vm.room = vm.rooms[0];
        vm.vacancies = [];
        vm.interviews = [];
        vm.timeRows = [];
        vm.googleEvents = [];
        vm.activeRow = null;
        vm.requestPromise = null;
        vm.loadingDate = null;

        vm.save = save;
        vm.cancel = cancel;
        vm.isTimeAvailable = isTimeAvailable;
        vm.selectTime = selectTime;

        $scope.$watch('modal.data.date', onDateChange);
        $scope.$watch('modal.activeMasters', onActiveMastersChange);
        $scope.$watch('modal.room', onRoomChange);

        activate();

        function activate() {
            mainVM.loadMasters().then(onMastersLoadSuccess);
            mainVM.loadVacancies().then(onVacanciesLoadSuccess);

            vm.data = model;
            vm.vacancy = vm.data.vacancy;
        }

        function onMastersLoadSuccess(response) {
            var data = [];
            for (var i = 0; i < response.data.length; i++) {
                for (var j = 0; j < response.data[i].groups.length; j++) {
                    if (response.data[i].groups[j].name == 'Interviewer') {
                        data.push(response.data[i]);
                    };
                };
            };
            vm.masters = data;
        }

        function onVacanciesLoadSuccess(response) {
            vm.vacancies = response.data;
        }

        function cancel() {
            $uibModalInstance.dismiss('cancel');
        }

        function onActiveMastersChange(newValue, oldValue) {
            if (vm.activeRow) {
                removeTimeRow(vm.activeRow);
                vm.activeRow = null;
            }
        }

        function onDateChange(newValue, oldValue) {
            vm.timeRows = [];
            vm.activeRow = null;
            if (newValue) {
                vm.loadingDate = vm.data.date;
                vm.loadingGoogleDate = vm.data.date;
                CalendarApi
                    .getDate(vm.data.date, { date: vm.loadingDate })
                    .then(onCalendarLoadSuccess, onCalendarLoadError);
                CalendarApi
                    .getGoogleDateEvents(vm.data.date, { date: vm.loadingGoogleDate })
                    .then(onGoogleCalendarLoadSuccess, onGoogleCalendarLoadError);
            }
        }

        function onRoomChange(newValue, oldValue) {
            if (vm.activeRow) {
                removeTimeRow(vm.activeRow);
                vm.activeRow = null;
            }
            updateTimeRows();
            CalendarHelper.linkGoogleEvents(vm.timeRows, vm.googleEvents, vm.room.id);
        }

        function onCalendarLoadSuccess(response) {
            var context = response.config.context;
            if (context.date !== vm.loadingDate) {
                // Prevent race condition
                return;
            }

            var interactions = response.data.notification;
            vm.interviews = interactions.filter(interviewFilter);
            vm.timeRows = CalendarHelper.getFilledTimeArray(vm.interviews.filter(roomFilter), vm.data.date);
            vm.loadingDate = null;
            if(vm.loadingGoogleDate === null) {
                CalendarHelper.linkGoogleEvents(vm.timeRows, vm.googleEvents, vm.room.id);
            }
        }

        function onCalendarLoadError(response) {
            var context = response.config.context;
            if (context.date !== vm.loadingDate) {
                // Prevent race condition
                return;
            }

            Notify.error('Ошибка', 'Невозможно загрузить календарь на выбранную дату.');
            vm.loadingDate = null;
        }

        function onGoogleCalendarLoadSuccess(response) {
            var context = response.config.context;
            if (context.date !== vm.loadingGoogleDate) {
                // Prevent race condition
                return;
            }

            vm.googleEvents = response.data.events;
            vm.loadingGoogleDate = null;
            if(vm.loadingDate === null) {
                CalendarHelper.linkGoogleEvents(vm.timeRows, vm.googleEvents, vm.room.id);
            }
        }

        function onGoogleCalendarLoadError(response) {
            var context = response.config.context;
            if (context.date !== vm.loadingGoogleDate) {
                // Prevent race condition
                return;
            }

            Notify.error('Ошибка', 'Невозможно загрузить Google календарь на выбранную дату.');
            vm.loadingGoogleDate = null;
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid || !vm.activeRow) {
                Notify.warning('Ошибка', 'Перед сохранением собеседования исправьте ошибки');
                return;
            }

            vm.data.master = [];
            for (var i = 0, len = vm.activeMasters.length; i < len; i++) {
                vm.data.master.push({ id: vm.activeMasters[i].id });
            }
            vm.data.vacancy = vm.vacancy;
            vm.data.room = vm.room.id;

            var targetTime = vm.activeRow.time.split(':');
            vm.data.date.setHours(parseInt(targetTime[0]));
            vm.data.date.setMinutes(parseInt(targetTime[1]));

            vm.requestPromise = InteractionsApi
                .save(vm.data)
                .then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(response) {
            vm.requestPromise = null;
            Notify.success('Сохранено', 'Взаимодействие успешно сохранено!');
            angular.merge(vm.data, response.data);
            $uibModalInstance.close(vm.data);
        }

        function onSaveError(response) {
            vm.requestPromise = null;
            Notify.error('Ошибка', 'При попытке сохранения взаимодействия произошла ошибка');
        }

        function isTimeAvailable(timeRow) {
            if (!vm.activeMasters || !vm.activeMasters.length || !vm.data.date) {
                return false;
            }

            for (var i = 0, len = vm.timeRows.length; i < len; i++) {
                if (vm.timeRows[i].time !== timeRow.time) {
                    continue;
                }

                if (vm.timeRows[i].interaction === null || vm.timeRows[i].interaction.held) {
                    continue;
                }

                if (hasActiveInterviewer(vm.timeRows[i].interaction)) {
                    return false;
                }

                if (vm.timeRows[i].interaction.applicant_id === applicant.id) {
                    return false;
                }

                if (vm.room.id !== 2 && vm.timeRows[i].interaction.room.id === vm.room.id) {
                    return false;
                }
            }

            for(var j=0, k = vm.googleEvents.length; j < k; j++) {
                if (vm.googleEvents[j].start.time !== timeRow.time) {
                    continue;
                }
                if (vm.googleEvents[j].room.id !== vm.room.id || vm.room.id === 2) {
                    continue;
                }
                return false;
            }

            return true;
        }

        function selectTime(timeRow) {
            if (!isTimeAvailable(timeRow)) {
                return true;
            }

            if (vm.activeRow) {
                removeTimeRow(vm.activeRow);
                vm.activeRow = null;
            }

            if (timeRow.interaction === null) {
                vm.activeRow = timeRow;
            } else {
                vm.activeRow = angular.merge({}, timeRow);
                var index = vm.timeRows.indexOf(timeRow);
                vm.timeRows.splice(index + 1, 0, vm.activeRow);
            }

            vm.activeRow.interaction = {
                applicant_name: (applicant.last_name || '') + ' ' + (applicant.first_name || ''),
                master_name: [],
                created_by: CurrentUser.getProfile()
            };
            var name;
            for (var i = 0, len = vm.activeMasters.length; i < len; i++) {
                name = (vm.activeMasters[i].first_name || '') + ' ' + (vm.activeMasters[i].last_name || '');
                vm.activeRow.interaction.master_name.push(name);
            }
        }

        function hasActiveInterviewer(interaction) {
            var name, i, j, mLen, iLen;
            for (i = 0, mLen = vm.activeMasters.length; i < mLen; i++) {
                name = (vm.activeMasters[i].first_name || '') + ' ' + (vm.activeMasters[i].last_name || '');

                for (j = 0, iLen = interaction.master_name.length; j < iLen; j++) {
                    if (interaction.master_name[j] === name) {
                        return true;
                    }
                }
            }
            return false;
        }

        function removeTimeRow(timeRow) {
            var count = countTimeRows(timeRow.time);
            if (count === 1) {
                timeRow.interaction = null;
            } else {
                var index = vm.timeRows.indexOf(timeRow);
                if (index > -1) {
                    vm.timeRows.splice(index, 1);
                }
            }
        }

        function countTimeRows(time) {
            var count = 0;
            for (var i = 0, len = vm.timeRows.length; i < len; i++) {
                if (vm.timeRows[i].time === time) {
                    count++;
                }
            }
            return count;
        }

        function updateTimeRows() {
            if(vm.timeRows.length === 0 || vm.interviews.length === 0) {
                return;
            }
            vm.timeRows = CalendarHelper.getFilledTimeArray(vm.interviews.filter(roomFilter), vm.data.date);
        }

        function interviewFilter(interaction, index, array) {
            return interaction.type === InteractionsApi.TYPE_INTERVIEW;
        }

        function roomFilter(interaction, index, array) {
            return interaction.room.id === vm.room.id;
        }
    }
})();
