(function confirmDeletionModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ConfirmDeletionModal', ConfirmDeletionModal);

    /**
     * A service for displaying a modal window with a request for deletion
     * confirmation. It is needed to prevent possible code duplication and
     * encapsulate parameters of the modal window.
     */
    function ConfirmDeletionModal($uibModal) {
        this.open = function open(message) {
            return $uibModal.open({
                templateUrl: 'hrpro/common/views/confirm-deletion-modal.html',
                controller: 'ConfirmDeletionModalController',
                controllerAs: 'modal',
                resolve: {
                    message: function resolveMessage() {
                        return message;
                    }
                },
                windowClass: 'modal-danger',
                backdrop: 'static',
                keyboard: false
            });
        };
    }
})();
