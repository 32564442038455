(function moduleConstantsIIFE() {
    'use strict';

    var module = angular.module('hrpro');

    /**
     * Constants of the application. If needed any of these constants can be
     * overriden by the HRPRO_LOCAL_CONSTANTS property of the window object.
     */
    var defaultConstants = {
        API_CONFIG: {
            basePath: '/api/'
        },
        WEBSOCKET_CONFIG: {
            basePath: '/locking',
            timeout: 5000,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            reconnectionAttempts: 5
        },
        APPLICANT_TEMPLATES: [
            {url: 'hrpro/app/applicants/templates/applicant-form.html'},
            {url: 'hrpro/app/applicants/templates/save-button.html'},
            {url: 'hrpro/app/applicants/templates/photo-block.html'},
            {url: 'hrpro/app/applicants/templates/resume-tab.html'},
            {url: 'hrpro/app/applicants/templates/interaction-tab.html'},
            {url: 'hrpro/app/applicants/templates/skills-tab.html'},
            {url: 'hrpro/app/applicants/templates/experience-tab.html'},
            {url: 'hrpro/app/applicants/templates/education-tab.html'},
            {url: 'hrpro/app/applicants/templates/information-tab.html'},
            {url: 'hrpro/app/applicants/templates/interview-tab.html'},
            {url: 'hrpro/app/applicants/templates/history-tab.html'},
            {url: 'hrpro/app/applicants/templates/save-delete-button-tab.html'}
        ],
        APPLICANTS_LIST: {
            pageSize: 30,
            pagesCount: 3,
            scrollPaddingTop: 1500,
            scrollPaddingBottom: 1500
        },
        FORMATS: {
            date: 'dd MMMM yyyy',
            dateShort: 'dd.MM.yyyy',
            datetime: 'dd MM yyyy hh:mm',
            dateIso: 'yyyy-MM-dd'
        },
        PATTERNS: {
            email: /^[^\.].{0,63}@[^\(\)<>{};@\\,!#$&'"*+\/=?^`\s|~]{1,255}$/,
            skype: /^[a-zA-Z][a-zA-Z0-9\.,\-_]{5,32}(\s+[a-zA-Z][a-zA-Z0-9\.,\-_]{5,32})*$/,
            dateIso: /^\d{4}-\d{2}-\d{2}$/
        },
        NAMES: {
            month: [
                'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
                'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
            ]
        },
        ROOMS: [
            {
                id: 0,
                title: 'Митинг-рум'
            },
            {
                id: 1,
                title: 'Учебка'
            },
            {
                id: 2,
                title: 'Переговорка #305'
            },
            {
                id: 3,
                title: 'Переговорка #306'
            },
            {
                id: 4,
                title: 'Кабинет #217'
            },
            {
                id: 5,
                title: 'Другое место'
            }
        ],
        IGNORE_MASTERS: [],
        IGNORE_VACANCIES: [],
        DISABLE_DEBUG_INFO: true
    };
    var localConstants = window.HRPRO_LOCAL_CONSTANTS || {};
    var constants = angular.merge({}, defaultConstants, localConstants);

    angular.forEach(constants, function constantsIter(value, key)  {
        module.constant(key, value);
    });

    /**
     * Values of the application. If needed any of these values can be
     * overriden by the HRPRO_LOCAL_VALUES property of the window object.
     */
    var defaultValues = {
    };
    var localValues = window.HRPRO_LOCAL_VALUES || {};
    var values = angular.merge({}, defaultValues, localValues);

    angular.forEach(values, function valuesIter(value, key)  {
        module.value(key, value);
    });
})();
