(function scrollPositionHolderServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ScrollPositionHolder', ScrollPositionHolder);

    /**
     * A service for storing data related to a scrolling position on different
     * pages of the application.
     */
    function ScrollPositionHolder() {
        var positions = {};
        var positionsData = {};

        this.getData = function getData(state) {
            return positionsData[state];
        };

        this.getPosition = function getPosition(state) {
            return positions[state];
        };

        this.setData = function setData(state, data) {
            positionsData[state] = data;
        };

        this.setPosition = function setPosition(state, position) {
            positions[state] = position;
        };
    }
})();
