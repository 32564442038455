(function capsLockTrackerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('capsLockTracker', capsLockTracker);

    /**
     * This code is based on the following example:
     * https://learn.javascript.ru/task/capslock-warning-field
     */
    function capsLockTracker($document) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        return directive;

        function getChar(event) {
            if (event.which === null) {
                if (event.keyCode < 32) {
                    return null;
                }

                // IE
                return String.fromCharCode(event.keyCode);
            }

            if (event.which !== 0 && event.charCode !== 0) {
                if (event.which < 32) {
                    return null;
                }

                // other
                return String.fromCharCode(event.which);
            }

            // non-letter key
            return null;
        }

        function link(scope, element, attrs, ngModel) {
            scope.$on('$destroy', onDestroy);
            element.on('keypress', onKeypress);

            ngModel.focussed = element[0] === $document.activeElement;

            function onDestroy() {
                element.off('keypress', onKeypress);
            }

            function onKeypress(event) {
                var chr = getChar(event);
                if (chr === null) {
                    return;
                }

                if (chr.toLowerCase() === chr.toUpperCase()) {
                    // This symbols does not depend on register. It can not
                    // be used to determine Caps Lock status
                    return;
                }

                if ((chr.toLowerCase() === chr && event.shiftKey) ||
                    (chr.toUpperCase() === chr && !event.shiftKey)) {
                    ngModel.capsLockActive = true;
                } else {
                    ngModel.capsLockActive = false;
                }
            }
        }
    }
})();
