(function applicantsSearchDirectiveIIFE() {
    angular
        .module('hrpro')
        .directive('applicantsSearch', applicantsSearch);

    function applicantsSearch($state, $location, $rootScope, $filter, SearchResultsHolder, ApplicantsApi, Notify,
        ScrollPositionHolder, TagsApi, VacanciesApi, SearchHelper, FileSaver, Blob, $stateParams
    ) {
        var STATE = 'applicants_list';

        var tagsPromise = TagsApi.getList();
        var vacanciesPromise = VacanciesApi.getList();

        var directive = {
            restrict: 'A',
            templateUrl: 'hrpro/common/views/applicants-search/applicants-search.html',
            replace: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var tmpPositionData;
            var locationParams = $location.search();
            var unbindResetListener;
            var unbindResultsListener;
            var unsubscribers;

            scope.CRITERIA_TYPES = SearchHelper.CRITERIA_TYPES;
            scope.INTERACTION_TYPES = SearchHelper.INTERACTION_TYPES;
            scope.VACANCIES = [];
            scope.TAGS = [];

            scope.search = locationParams.search;
            scope.criteriaList = SearchHelper.parseCriteriaInternal(
                locationParams.extended, scope.VACANCIES, scope.TAGS
            );

            scope.foundCount = SearchResultsHolder.getResultsCount();
            scope.totalCount = null;
            scope.state = $state;

            scope.onSubmit = onSubmit;
            scope.onSubmitAdvanced = onSubmitAdvanced;
            scope.advancedSearch = advancedSearch;
            scope.advancedSearchExport = advancedSearchExport;
            scope.addCriteria = addCriteria;
            scope.removeCriteria = removeCriteria;
            scope.clearCriteriaList = clearCriteriaList;
            scope.isRevertAvailable = isRevertAvailable;
            scope.revertCriteriaList = revertCriteriaList;

            unsubscribers = [
                $rootScope.$on(SearchResultsHolder.EVENT_RESET, onSearchClear),
                $rootScope.$on(SearchResultsHolder.EVENT_UPDATE, onSearchResultsCount)
            ];

            scope.$on('$destroy', onDestroy);

            getTotalCount();
            tagsPromise.then(onTagsLoadSuccess);
            vacanciesPromise.then(onVacanciesSuccess);


            $(document).on('click', outsideSearchClick);

            function outsideSearchClick(e) {
                if (!$(e.target).closest('.advanced-search').length) {
                    scope.$apply(function apply() {
                        scope.dropdowns.advanced = false;
                    });
                }
            }

            function addCriteria(criteriaType) {
                if (!$checkMultiUseCriteria(criteriaType, scope.criteriaList)) {
                    Notify.warning('Внимание', 'Данный фильтр можно применить только один раз');
                    return;
                }
                var criteria = { type: criteriaType };
                scope.criteriaList.push(criteria);
                scope.criteriaListForm.$setDirty();
            }

            function removeCriteria(e, criteria) {
                e.stopPropagation();
                var index = scope.criteriaList.indexOf(criteria);
                if (index > -1) {
                    scope.criteriaList.splice(index, 1);
                }
                scope.criteriaListForm.$setDirty();
            }

            function advancedSearch() {
                var query = SearchHelper.serializeCriteriaInternal(scope.criteriaList);

                tmpPositionData = ScrollPositionHolder.getData(STATE);
                ScrollPositionHolder.setData(STATE, undefined);
                $state
                    .go(STATE, { search: scope.search, extended: query })
                    .then(onStateChangeSuccess, onStateChangeError);
            }

            function advancedSearchExport() {
                var query = SearchHelper.serializeCriteriaInternal(scope.criteriaList);
                query = SearchHelper.serializeCriteriaApi(query);

                ApplicantsApi.export(null, scope.search, query).success(function (data, status, headers) {
                    var disposition = headers('Content-Disposition');
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches !== null && matches[1]) {
                        var filename = matches[1].replace(/['"]/g, '');
                        var blobData = new Blob([data], { type: 'text/plain;charset=utf-8' });
                        FileSaver.saveAs(blobData, filename);
                    }
                });
            }

            function clearCriteriaList() {
                scope.criteriaList = [];
                scope.criteriaListForm.$setDirty();
            }

            function isRevertAvailable() {
                return $stateParams.extended !== undefined && scope.criteriaListForm && scope.criteriaListForm.$dirty;
            }

            function revertCriteriaList() {
                scope.criteriaList = SearchHelper.parseCriteriaInternal(
                    locationParams.extended, scope.VACANCIES, scope.TAGS
                );
                scope.criteriaListForm.$setPristine();
            }

            function onSearchClear(event) {
                scope.search = null;
                scope.criteriaList = [];
                scope.foundCount = null;
                scope.totalCount = null;
                getTotalCount();

                scope.criteriaListForm.$setPristine();
            }

            function onSearchResultsCount(event, count) {
                scope.foundCount = count;
                getTotalCount();

                // Update extended criteria for new search if available
                locationParams = $location.search();
                scope.criteriaList = SearchHelper.parseCriteriaInternal(
                    locationParams.extended, scope.VACANCIES, scope.TAGS

                );
                scope.criteriaListForm.$setPristine();
            }

            function getTotalCount() {
                ApplicantsApi
                    .getTotalCount()
                    .then(onTotalCountLoadSuccess, onTotalCountLoadError);
            }

            function onTotalCountLoadSuccess(response) {
                scope.totalCount = response.data.count;
            }

            function onTotalCountLoadError(response) {
                Notify.error('Ошибка', 'Невозможно загрузить с сервера общее количество кандидатов');
            }

            function onSubmit(event) {
                if (event.keyCode === 13) {
                    tmpPositionData = ScrollPositionHolder.getData(STATE);
                    ScrollPositionHolder.setData(STATE, undefined);

                    $state
                        .go(STATE, { search: scope.search, extended: null })
                        .then(onStateChangeSuccess, onStateChangeError);
                }
            }

            function onSubmitAdvanced(event) {
                if (event.keyCode === 13) {
                    scope.advancedSearch();
                }
            }


            function onStateChangeSuccess() {
                // Reset applicants list's scroll position on new search
                ScrollPositionHolder.setPosition(STATE, 0);

                // Close an extended search panel if it was opened
                scope.dropdowns.advanced = false;
            }

            function onStateChangeError() {
                ScrollPositionHolder.setData(STATE, tmpPositionData);
            }

            function onDestroy() {
                $(document).off('click', outsideSearchClick);
                unsubscribers.forEach(function unsubscribe(unsubscribe) {
                    unsubscribe();
                });
            }

            function onTagsLoadSuccess(response) {
                scope.TAGS = response.data.results;
                SearchHelper.updateCriteriaExternalData(scope.criteriaList, 'tags', scope.TAGS);
            }

            function onVacanciesSuccess(response) {
                scope.VACANCIES = response.data;
                SearchHelper.updateCriteriaExternalData(scope.criteriaList, 'interview', scope.VACANCIES);
                SearchHelper.updateCriteriaExternalData(scope.criteriaList, 'vacancy', scope.VACANCIES);
            }

            function $checkMultiUseCriteria(criteria, criteriaList) {
                if (criteria.multiUse) {
                    return true;
                }
                return !$filter('filter')(criteriaList, function filter(obj) {
                                return obj.type.name === criteria.name;
                            }).length;
            }
        }
    }
})();
