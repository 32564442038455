(function testTasksListReportFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('testTasksListReportSorter', testTasksListReportSorter);

    /**
     * A filter for custom sorting of the test tasks report table. It provides a
     * second level of sorting for the last interaction status column.
     */
    function testTasksListReportSorter(orderByFilter) {
        return function filter(input, predicate, reverse) {
            if (predicate === 'last_interaction.status') {
                if (reverse === true) {
                    predicate = ['-last_interaction.status', '-history[0].date_notification'];
                    reverse = false;
                } else {
                    predicate = ['last_interaction.status', '-history[0].date_notification'];
                }
            }
            return orderByFilter(input, predicate, reverse);
        };
    }
})();
