(function pageTitleDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('pageTitle', pageTitle);

    /**
     * Directive for setting correct page title when route changes successfully
     */
    function pageTitle($rootScope, $timeout) {
        return {
            link: function pageTitleLink(scope, element) {
                var listener = function pageTitleListener(event, toState, toParams, fromState, fromParams) {
                    var title = 'HRPro';
                    if (toState.data && toState.data.pageTitle) {
                        title += ' | ' + toState.data.pageTitle;
                    }
                    $timeout(function onTimeout() {
                        element.text(title);
                    });
                };

                $rootScope.$on('$stateChangeSuccess', listener);
            }
        };
    }
})();
