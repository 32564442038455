(function identityServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('Identity', Identity);

    /**
     * A service which handles identity of the current tab in a browser.
     */
    function Identity(CurrentUser) {
        var idTab;

        this.getTabId = getTabId;
        this.isSameTab = isSameTab;
        this.isSameUser = isSameUser;

        function getTabId() {
            if (idTab === undefined) {
                idTab = Date.now().toString();
            }
            return idTab;
        }

        function isSameTab(id) {
            var tab = getTabId();
            return tab === id;
        }

        function isSameUser(userId) {
            var profile = CurrentUser.getProfile();
            if (angular.isString(userId)) {
                userId = parseInt(userId);
            }
            return profile.user_id === userId;
        }
    }
})();
