(function applicantsListControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantsListController', ApplicantsListController);

    /**
     * A controller which handles a page with a list of applicants.
     */
    function ApplicantsListController(applicants, ApplicantsApi, SearchResultsHolder, Notify, $stateParams,
        $scope, ScrollPositionHolder, $q, APPLICANTS_LIST, extendedSearchCriteria
    ) {
        var vm = this;
        var STATE_NAME = 'applicants_list';

        var cacheData = [];
        var cacheOffset = 0;

        vm.foundCount = null;

        vm.loader = loader;
        vm.displayData = [];
        vm.displayOffset = 0;
        vm.displayScroll = 0;
        vm.listConfig = null;

        activate();

        function activate() {
            var stateSavedData = ScrollPositionHolder.getData(STATE_NAME);

            vm.listConfig = APPLICANTS_LIST;
            vm.displayScroll = ScrollPositionHolder.getPosition(STATE_NAME) || 0;
            vm.displayOffset = stateSavedData ? stateSavedData.offset : 0;
            vm.displayData = applicants.data.results;

            cacheOffset = vm.displayOffset;
            cacheData = angular.copy(vm.displayData);

            vm.foundCount = applicants.data.count;

            if ($stateParams.search || $stateParams.extended) {
                SearchResultsHolder.setResultsCount(vm.foundCount);
            } else {
                SearchResultsHolder.setResultsCount(null);
            }

            $scope.$on('$destroy', onDestroy);
        }

        function onDestroy() {
            ScrollPositionHolder.setData(STATE_NAME, {
                offset: vm.displayOffset,
                limit: vm.displayData.length
            });
        }

        function loader(offset, limit) {
            if (limit > offset) {
                return $q.reject();
            }
            if (offset >= cacheOffset && offset + limit <= cacheOffset + cacheData.length) {
                return getDataCache(offset, limit);
            } else {
                return getDataXhr(offset, limit);
            }
        }

        function getDataCache(offset, limit) {
            var startIndex = offset - cacheOffset;
            var stopIndex = startIndex + limit;
            var data = cacheData.slice(startIndex, stopIndex);

            if (isContinuousSequence(data)) {
                return $q.when(data);
            } else {
                return getDataXhr(offset, limit);
            }
        }

        /**
         * Determines whether a given sequence has no undefined values
         */
        function isContinuousSequence(sequence) {
            for (var i = sequence.length - 1; i >= 0; i--) {
                if (sequence[i] === undefined) {
                    return false;
                }
            }
            return true;
        }

        function getDataXhr(offset, limit) {
            var page = {
                offset: offset,
                limit: limit
            };
            return ApplicantsApi
                .getList(page, page, $stateParams.search, extendedSearchCriteria)
                .then(onLoadSuccess, onLoadError);
        }

        function onLoadSuccess(response) {
            var data = response.data.results;
            var offset = response.config.context.offset;

            // Adjast size of the cache at the start
            var i, len;
            for (i = offset; i < cacheOffset; i++) {
                cacheData.unshift(undefined);
            }
            cacheOffset = Math.min(offset, cacheOffset);

            // Adjast size of the cache at the end
            len = offset + data.length - cacheOffset - cacheData.length;
            for (i = 0; i < len; i++) {
                cacheData.push(undefined);
            }

            // Cache received data
            var spliceArgs = [offset - cacheOffset, data.length].concat(data);
            Array.prototype.splice.apply(cacheData, spliceArgs);

            return data;
        }

        function onLoadError(response) {
            Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
            return $q.reject(response);
        }
    }
})();
