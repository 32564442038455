(function outdatedNotificationsReportControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('OutdatedNotificationsReportController', OutdatedNotificationsReportController);

    function OutdatedNotificationsReportController(dateStart, notifications, $state, dateFilter, InteractionsApi) {
        var vm = this;

        vm.TYPES = [
            'Собеседование',
            'Обучение',
            'Тестовое задание',
            'Звонок (устаревшее)',
            'Самообучение',
            'Принят на работу',
            'Отказ',
            'Инфо от кандидата (устаревшее)',
            'Уволен',
            'Feedback',
            'Коммуникация'
        ];

        vm.data = null;
        vm.dateStart = null;

        vm.build = build;
        vm.getTargetTab = getTargetTab;
        vm.getTypeName = getTypeName;

        activate();

        function activate() {
            vm.data = notifications.data;
            vm.dateStart = dateStart;
        }

        function build() {
            $state.go('report_outdated_notifications', {
                start: dateFilter(vm.dateStart, 'yyyy-MM-dd')
            }, { reload: true });
        }

        function getTargetTab(interaction) {
            if (interaction.type === InteractionsApi.TYPE_INTERVIEW) {
                return 'interviews';
            } else {
                return 'interactions';
            }
        }

        function getTypeName(row) {
            return vm.TYPES[row.interaction.type];
        }
    }
})();
