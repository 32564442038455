(function CalendarApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('CalendarApi', CalendarApi);

    /**
     * Provides an ability to interact with the calendar related endpoints of
     * the API
     */
    function CalendarApi(ApiHelper, $http, dateFilter) {
        this.getDate = getDate;
        this.getWeek = getWeek;
        this.getGoogleDateEvents = getGoogleDateEvents;
        this.getGoogleWeekEvents = getGoogleWeekEvents;

        function getDate(date, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('calendar'),
                params: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                },
                context: context
            };
            return $http(config);
        }

        function getWeek(date, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('calendar'),
                params: {
                    week: dateFilter(date, 'yyyy-MM-dd')
                },
                context: context
            };
            return $http(config);
        }

        function getGoogleDateEvents(date, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('google-calendar'),
                params: {
                    year: date.getFullYear(),
                    month: date.getMonth() + 1,
                    day: date.getDate()
                },
                context: context
            };
            return $http(config);
        }

        function getGoogleWeekEvents(date, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('google-calendar'),
                params: {
                    week: dateFilter(date, 'yyyy-MM-dd')
                },
                context: context
            };
            return $http(config);
        }
    }
})();
