(function confirmSavingModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ConfirmSavingModal', ConfirmSavingModal);

    /**
     * A service for displaying a modal window with a request for saving
     * confirmation. It is needed to prevent possible code duplication and
     * encapsulate parameters of the modal window.
     */
    function ConfirmSavingModal($uibModal) {
        this.open = function open(message, context) {
            return $uibModal.open({
                templateUrl: 'hrpro/common/views/confirm-saving-modal.html',
                controller: 'ConfirmSavingModalController',
                controllerAs: 'modal',
                resolve: {
                    message: function resolveMessage() {
                        return message;
                    },
                    context: function resolveContext() {
                        return context;
                    }
                },
                windowClass: 'modal-info',
                backdrop: 'static',
                keyboard: false
            }).result;
        };
    }
})();
