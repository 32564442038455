(function pluralFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('plural', plural);

    /**
     * A filter to provide an ability to pluralize Russian words.
     * See: http://unicode.org/repos/cldr-tmp/trunk/diff/supplemental/language_plural_rules.html
     */
    function plural() {
        function inRange(value, min, max) {
            return value >= min && value <= max;
        }

        return function filter(input, one, few, many, other) {
            input = Math.abs(input);
            var mod10 = input % 10,
                mod100 = input % 100;

            if (mod10 === 1 && mod100 !== 11) {
                return one;
            } else if (inRange(mod10, 2, 4) && !inRange(mod100, 12, 14)) {
                return few;
            } else if (mod10 === 0 || inRange(mod10, 5, 9) || inRange(mod100, 11, 14)) {
                return many;
            } else {
                return other;
            }
        };
    }
})();
