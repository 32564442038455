(function authTokenInterceptorServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .provider('AuthTokenInterceptor', AuthTokenInterceptorProvider);

    /**
     * An HTTP interceptor which adds authentication token to API requests
     * if possible.
     */
    function AuthTokenInterceptorProvider() {
        var tokenAccessorServiceName;

        this.setTokenAccessor = setTokenAccessor;
        this.getTokenAccessor = getTokenAccessor;
        this.$get = AuthTokenInterceptor;

        /**
         * Sets access token accessor service to use in order to retrieve the
         * access token which has to be added to HTTP requests. This service
         * must have a "getAccessToken" method in order to make it possible
         * this interceptor to use it.
         * @param {String} tokenAccessorName name of the token accessor service
         */
        function setTokenAccessor(tokenAccessorName) {
            tokenAccessorServiceName = tokenAccessorName;
        }

        /**
         * Returns a name of the service which is used to get access token
         * @return {String} a name of the service
         */
        function getTokenAccessor() {
            return tokenAccessorServiceName;
        }

        function AuthTokenInterceptor($injector) {
            this.request = request;
            this.getTokenAccessor = getTokenAccessor;

            var tokenAccessor = $injector.get(tokenAccessorServiceName);
            if (!angular.isFunction(tokenAccessor.getAccessToken)) {
                throw new TypeError('Token accessor must have a "getAccessToken" method');
            }

            /**
             * Adds access token to all HTTP requests if possible
             * @param  {Object} config HTTP request configuration
             * @return {Object} modified HTTP request configuration
             */
            function request(config) {
                var token = tokenAccessor.getAccessToken();
                if (token) {
                    config.headers.Authorization = 'Token ' + token;
                }

                return config;
            }

            return this;
        }
    }
})();
