(function interviewerReportControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('InterviewerReportController', InterviewerReportController);

    function InterviewerReportController(dateStart, dateEnd, data, currentUser, $state, dateFilter, MasterApi, ReportsApi, FileSaver) {
        var now = null;
        var vm = this;

        vm.model = null;
        vm.data = null;
        vm.dates = null;
        vm.is_staff = currentUser.data[0].is_staff;
        vm.blocked = false;
        vm.headerTitles = {
            vacancy: 'Направление',
            interviewer: 'Интервьюер',
            task_done: 'Проверенные тестовые',
        }

        vm.build = build;
        vm.saveReport = saveReport;

        activate();

        function activate() {
            now = new Date();

            vm.model = MasterApi.getModel();
            vm.data = data.data;

            vm.dates = {
                start: dateStart,
                end: dateEnd
            };
        }

        function build() {

            $state.go('report_interviewer', {
                start: dateFilter(vm.dates.start, 'yyyy-MM-dd'),
                end: dateFilter(vm.dates.end, 'yyyy-MM-dd'),
            }, { reload: true });
        }

        function saveReport() {
            var headerTitles = {
                'vacancy_title': vm.headerTitles.vacancy,
                'interviewer_title': vm.headerTitles.interviewer,
                'task_done_title': vm.headerTitles.task_done,
            }
            vm.data.header_titles = headerTitles;
            ReportsApi.exportReport(null, ReportsApi.TYPE_EXPORT_INTERVIEWER, vm.data).success(function (data, status, headers) {
                var currentDate = new Date();
                var time = `${currentDate.getHours().toString()}_${currentDate.getMinutes().toString()}_${currentDate.getSeconds().toString()}`
                var filename = `${time}_interviewer_report.csv`;
                var blobData = new Blob([data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blobData, filename);
            });
        }
    }
})();
