(function ageFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('age', age);

    /**
     * A filter to calculate a difference in years between the given date and now
     * @see: http://stackoverflow.com/questions/4060004/calculate-age-in-javascript/7091965#7091965
     */
    function age() {
        return function filter(input) {
            if (!input) {
                return '';
            }

            var birthDate = angular.isString(input) ? new Date(input) : input;

            if (!angular.isDate(birthDate)) {
                return '';
            }

            var today = new Date();
            if (today <= birthDate) {
                return 0;
            }

            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        };
    }
})();
