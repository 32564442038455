(function photoUploadModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('PhotoUploadModalController', PhotoUploadModalController);

    /**
     * A controller of the modal which loads a photo of the current applicant
     */
    function PhotoUploadModalController($uibModalInstance, FileUploadApi, Notify, $scope) {
        var vm = this;

        vm.progress = null;
        vm.file = null;
        vm.link = null;

        vm.onFileChange = onFileChange;
        vm.onLinkChange = onLinkChange;

        vm.close = function close() {
            $uibModalInstance.dismiss('cancel');
        };

        function onFileChange(file) {
            if (!file) {
                return;
            }

            vm.progress = 0;

            FileUploadApi
                .uploadPhotoFile(file)
                .then(onPhotoLoadSuccess, onPhotoLoadError, onPhotoLoadProgress);
        }

        function onLinkChange() {
            if (!vm.link) {
                return;
            }

            vm.progress = 0;

            FileUploadApi
                .uploadPhotoUrl(vm.link)
                .then(onPhotoLoadSuccess, onPhotoLoadError, onPhotoLoadProgress);
        }

        function onPhotoLoadSuccess(response) {
            vm.progress = null;
            $uibModalInstance.close(response);
        }

        function onPhotoLoadError(response) {
            vm.progress = null;
            Notify.error('Ошибка', 'При загрузке фотографии на сервер произошла ошибка');
        }

        function onPhotoLoadProgress(event) {
            var progress = parseInt(100.0 * event.loaded / event.total);
            vm.progress = progress;
        }
    }
})();
