(function infiniteTabletDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('infiniteTable', infiniteTable);

    var DEFAULT_PAGE_SIZE = 20;
    var DEFAULT_SCROLL_BUFFER_TOP = 500;
    var DEFAULT_SCROLL_BUFFER_BOTTOM = 500;
    var MAX_PAGES_IN_DOM = 3;

    /**
     * A minimal delay between simultaneous scrolling events which indicates that
     * scrolling is finished (in case of automatic scrolling on Mac or IPad).
     * This values is taken in an empirical way (max was somewhat about 108 ms).
     */
    var DOM_APPLY_SCROLL_DELAY = 150;

    /**
     * Bidirectional infinite scroll. The code is based on this article:
     * http://www.bennadel.com/blog/1803-creating-a-bidirectional-infinite-scroll-page-with-jquery-and-coldfusion.htm
     *
     * Directive arguments:
     *
     * infinite-table-loader                function(offset:int, limit:int): Promise
     *     A function which will be called to retrieve a new piece of data. The directive will
     *     pass needed offset and amount of elements. This function has to return a promise and either
     *     resolve it with an array of elements or reject it in case of loading failure. In case of
     *     rejected promise or if the resulting array has fewer items than expected, the directive
     *     will stop calling the loader for bigger offsets. This value cannot be changed after
     *     initialization.
     *
     * infinite-table-data                  array=[]
     *     A data that is currently displayed on the screen. This parameter can be used to initialize
     *     the directive with some prefetched data. The directive updates this array in runtime.
     *
     * infinite-table-offset                int=0
     *     An offset of the data, that is currently displayed on the screen. This parameter can be
     *     used to initialize the directive with some prefetched data. The directive updates this
     *     value in runtime.
     *
     * infinite-table-page-size             int=20
     *     An amount of items to display in one "page" of the list. The directive will ask the loader
     *     to fetch exectly this amount of items. This value cannot be changed after initialization.
     *
     * infinite-table-scroll                int=0
     *     A position of the scroll bar. This parameter can be used to initialize the directive with
     *     some prefetched data. The directive doesn't update this value in runtime.
     *
     * infinite-table-scroll-buffer-top     int=500
     *     An offset from top of the element. The directive will try to load a new set of items
     *     at the top when this point is below the top of the window.
     *
     * infinite-table-scroll-buffer-bottom  int=500
     *     An offset from bottom of the element. The directive will try to load a new set of items
     *     at the bottom when this point is above the bottom of the window.
     *
     * The directive only renders a list of items, but doesn't load, change or store it by itself.
     * So, if needed, caching mechanism has to be implemented in the loader.
     */
    function infiniteTable($window, $compile, $parse, $timeout) {
        var win = angular.element($window);

        var directive = {
            restrict: 'A',
            terminal: true,
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var accessorData = $parse(attrs.infiniteTableData);
            var accessorLoader = $parse(attrs.infiniteTableLoader);
            var accessorOffset = $parse(attrs.infiniteTableOffset);
            var accessorPageSize = $parse(attrs.infiniteTablePageSize);
            var accessorScroll = $parse(attrs.infiniteTableScroll);
            var accessorScrollBufferTop = $parse(attrs.infiniteTableScrollBufferTop);
            var accessorScrollBufferBottom = $parse(attrs.infiniteTableScrollBufferBottom);

            var data = null;
            var loader = null;
            var loadingPromise = null;
            var offset = null;
            var pageSize = null;
            var scrollBufferTop = null;
            var scrollBufferBottom = null;
            var template = null;
            var terminatorOffset = null;
            var domWaitingTimeout = null;
            var domApplyingTimeout = null;
            var lastScrollTimestamp = null;
            var pendingChunk = null;
            var pendingItems = null;
            var itemsScopes = {};

            activate();

            function activate() {
                template = element.html();
                element.empty();

                loader = accessorLoader(scope);
                if (!angular.isFunction(loader)) {
                    throw new Error('infinite-table-storage: loader has to be a function');
                }

                pageSize = parseInt(accessorPageSize(scope) || DEFAULT_PAGE_SIZE);
                offset = parseInt(accessorOffset(scope) || 0);
                data = accessorData(scope) || [];
                scrollBufferTop = parseInt(accessorScrollBufferTop(scope) || DEFAULT_SCROLL_BUFFER_TOP);
                scrollBufferBottom = parseInt(accessorScrollBufferBottom(scope) || DEFAULT_SCROLL_BUFFER_BOTTOM);

                lastScrollTimestamp = new Date();

                scope.$on('$destroy', onDestroy);

                if (data.length) {
                    applyInitialData();
                } else {
                    win.on('scroll resize', onScroll);
                    tryToLoadNewItems();
                }
            }

            function applyInitialData() {
                var chunkData = null;
                var chunkDOM = null;
                var domElements = [];
                for (var i = 0; i < data.length; i += pageSize) {
                    chunkData = data.slice(i, i + pageSize);
                    chunkDOM = createChunkDOM(chunkData, offset + i);
                    domElements = domElements.concat(chunkDOM);
                }
                element.append(domElements);

                applyWhenDomUpdated(applyInitialScroll);
            }

            /**
             * Calls a given function when DOM is initialized and child nodes are
             * added to the element
             * @param  {Function()} method a method to call when DOM is ready
             */
            function applyWhenDomUpdated(method) {
                if (element.height() > 0) {
                    domWaitingTimeout = null;
                    method();
                    return;
                }
                domWaitingTimeout = $timeout(applyWhenDomUpdated, 0, true, method);
            }

            function applyInitialScroll() {
                var position = parseInt(accessorScroll(scope) || 0);
                $window.scroll(0, position);

                // Check if we still need to load some data
                win.on('scroll resize', onScroll);
                tryToLoadNewItems();
            }

            /**
             * Initializes DOM for an item in the list and compiles it in order
             * to make it possible for angular to work with this element
             * @param  {object} extraScopeParams A map of scope parameters to
             *   pass to an item's scope.
             * @return angular.element wrapper for constructed HTML element
             */
            function createListItem(itemIndex, extraScopeParams) {
                var itemScope = scope.$new(false);
                angular.extend(itemScope, extraScopeParams);

                // For some reason it is impossible to store child's scope using
                // element.data() method. This method (as getter) returns
                // undefined in the $destroy event handler.
                itemsScopes['scope-' + itemIndex] = itemScope;

                var item = angular.element(template);
                item.attr('data-infinite-table-index', itemIndex);

                $compile(item)(itemScope);
                return item;
            }

            /**
             * Completely destroys a given list item, including destruction of its scope
             */
            function destroyListItem(itemElement) {
                var item = angular.element(itemElement);
                var itemIndex = item.attr('data-infinite-table-index');

                var itemScope = itemsScopes['scope-' + itemIndex];
                itemScope.$destroy();

                delete itemsScopes['scope-' + itemIndex];
                item.remove();
            }

            function onDestroy() {
                win.off('scroll resize', onScroll);

                angular.forEach(element.children(), destroyListItem);
                element.empty();

                template = null;
                data = null;

                if (domWaitingTimeout !== null) {
                    $timeout.cancel(domWaitingTimeout);
                    domWaitingTimeout = null;
                }

                if (domApplyingTimeout !== null) {
                    $timeout.cancel(domApplyingTimeout);
                    domApplyingTimeout = null;

                    angular.forEach(pendingChunk, destroyListItem);
                    pendingChunk = null;
                    pendingItems = null;
                }

                itemsScopes = null;
            }

            function onScroll(evt) {
                lastScrollTimestamp = new Date();

                if (loadingPromise === null) {
                    tryToLoadNewItems();
                }
            }

            /**
             * Checks whether it is necessary to load new item at the top or bottom
             * of the list. Initiates a loading process in case it is needed.
             */
            function tryToLoadNewItems() {
                var isLoadNeeded = isMoreListItemsNeeded();
                if (isLoadNeeded.top && offset > 0) {
                    getMoreListItems('top');
                } else if (isLoadNeeded.bottom) {
                    getMoreListItems('bottom');
                }
            }

            /**
             * Determines whether it is needed to load more list items at the
             * top and bottom of the list.
             * @return {{top:bool,bottom:bool}}
             */
            function isMoreListItemsNeeded() {
                var result = {
                    top: false,
                    bottom: false
                };

                var viewTop = win.scrollTop();
                var viewBottom = viewTop + win.height();

                var containerTop = element.offset().top;
                var containerBottom = Math.floor(containerTop + element.height());

                if ((containerTop + scrollBufferTop) >= viewTop) {
                    result.top = true;
                }

                if ((containerBottom - scrollBufferBottom) <= viewBottom) {
                    result.bottom = true;
                }

                return result;
            }

            /**
             * Tries to fetch more list items at the given part of the list.
             * @param  {string} targetArea indicates a target part of the list,
             *   which requires new items. It can be either "top" or "bottom",
             *   all other values are interpreted as "bottom".
             */
            function getMoreListItems(targetArea) {
                if (loadingPromise !== null) {
                    // Loading is in progress already, so let's wait untill it
                    // it finishes. Probably, a new loading will not be needed
                    // after the current request is done.
                    return;
                }

                var nextOffset;
                if (targetArea === 'top') {
                    nextOffset = Math.max(offset - pageSize, 0);
                } else {
                    nextOffset = offset + data.length;
                }

                if (nextOffset === terminatorOffset) {
                    // We have already tried to load data with this offset and
                    // got no data in response. So, we assume that this offset
                    // indicates the end of the data list and no new data can
                    // be fetched.
                    return;
                }

                loadingPromise = loader(nextOffset, pageSize);
                loadingPromise.then(onLoadSuccess, onLoadError);

                function onLoadSuccess(items) {
                    if (data === null) {
                        // directive is destroyed, no actions needed in this case
                        loadingPromise = null;
                        return;
                    }

                    if (nextOffset >= offset) {
                        // Detect last chunk of the list. If it is a last portion
                        // of the list, we'll not send requests to load next items
                        if (!items.length) {
                            return onLoadError();
                        } else if (items.length < pageSize) {
                            terminatorOffset = nextOffset + items.length;
                        }
                    }

                    pendingItems = items;
                    pendingChunk = createChunkDOM(items, nextOffset);

                    // Wait for DOM initialization
                    domApplyingTimeout = $timeout(applyChunkOnScrollFinish, 0, true, targetArea);
                }

                function onLoadError() {
                    terminatorOffset = nextOffset;
                    loadingPromise = null;
                }
            }

            /**
             * Creates compiled DOM for the given list items
             * @param {object[]} items an array of items to create DOM for
             * @param {int} offset a value of an offset parameter that was used
             *   to load a given set of items
             * @return an array of angular.element wrappers for the given items
             */
            function createChunkDOM(items, offset) {
                var chunk = [];
                var itemElement;
                for (var i = 0, len = items.length; i < len; i++) {
                    itemElement = createListItem(offset + i, { $item: items[i] });
                    itemElement.data('infinite-table-offset', offset);
                    chunk.push(itemElement);
                }
                return chunk;
            }

            /**
             * Applies a pending DOM chunk after when scrolling finishes. It is
             * intended to reduce rendering lags and "jumpy" behavior on Mac
             * and IPad (it looks like the DOM is frozen during scrolling in
             * these devices).
             */
            function applyChunkOnScrollFinish(targetArea) {
                domApplyingTimeout = null;

                var currentTimestamp = new Date();
                var timePast = currentTimestamp - lastScrollTimestamp;
                if (timePast > DOM_APPLY_SCROLL_DELAY) {
                    applyPendingChunk(targetArea);
                } else {
                    var timeLeft = DOM_APPLY_SCROLL_DELAY - timePast;
                    domApplyingTimeout = $timeout(applyChunkOnScrollFinish, timeLeft, true, targetArea);
                }
            }

            /**
             * Applies a pending DOM representation of loaded list items to a specified
             * part of the list while keeping a scroll position. It also keeps
             * a constant amount of list items on the page by deleting last items
             * from the opposite part of the list. When new items are added,
             * it tries to load a new portion of the list if it is necessary.
             * @param  {string} targetArea indicates a target part of the list to apply
             *   given DOM pieces
             */
            function applyPendingChunk(targetArea) {
                var viewTop = win.scrollTop();

                if (targetArea === 'top') {
                    element.prepend(pendingChunk);
                    Array.prototype.unshift.apply(data, pendingItems);
                    offset -= pendingItems.length;

                    var chunkHeight = getChunkHeight(pendingChunk);
                    win.scrollTop(viewTop + chunkHeight);

                    if (element.children().length > pageSize * MAX_PAGES_IN_DOM) {
                        // Remove last part of the list to keep a constant amount of
                        // items in the list
                        var lastChunk = getLastChunk();
                        angular.forEach(lastChunk, destroyListItem);
                        data.splice(data.length - lastChunk.length, lastChunk.length);
                    }
                } else {
                    element.append(pendingChunk);
                    Array.prototype.push.apply(data, pendingItems);

                    if (element.children().length > pageSize * MAX_PAGES_IN_DOM) {
                        // Remove first part of the list to keep a constant amount of
                        // items in the list
                        var firstChunk = getFirstChunk();
                        var firstChunkHeight = getChunkHeight(firstChunk);

                        angular.forEach(firstChunk, destroyListItem);
                        data.splice(0, firstChunk.length);
                        offset += firstChunk.length;

                        win.scrollTop(viewTop - firstChunkHeight);
                    }
                }

                if (accessorOffset.assign) {
                    accessorOffset.assign(scope, offset);
                }

                pendingChunk = null;
                pendingItems = null;

                // Chunk loading is done
                loadingPromise = null;

                // Try to load more data if it is still needed
                tryToLoadNewItems();
            }

            /**
             * Calculates a height of a set of angular.element wrappers for
             * loaded list items
             */
            function getChunkHeight(chunk) {
                var height = 0;
                for (var i = 0, len = chunk.length; i < len; i++) {
                    height += chunk[i].height();
                }
                return height;
            }

            /**
             * Pushes an items to a given chunk in case it matches a given offset
             * @param  {angular.element[]} chunk an array of element to push a
             *   given element to
             * @param  {angular.element} item an angular.element wrapper for a
             *   loaded list item
             * @param  {int} chunkOffset a target value of the offset paarmeter
             *   a given item has to match in order to be added to the chunk
             * @return {bool} indicates whether an item was successfully added
             *   to the chunk
             */
            function putToChunk(chunk, item, chunkOffset) {
                item = angular.element(item);
                if (item.data('infinite-table-offset') !== chunkOffset) {
                    return false;
                }
                chunk.push(item);
                return true;
            }

            /**
             * Retrieves a first portion of items which are displayed on the page
             * @return {angular.element[]}
             */
            function getFirstChunk() {
                var children = element.children();
                var child = angular.element(children[0]);
                var offset = child.data('infinite-table-offset');

                var chunk = [];
                for (var i = 0, len = children.length; i < len; i++) {
                    if (!putToChunk(chunk, children[i], offset)) {
                        break;
                    }
                }
                return chunk;
            }

            /**
             * Retrieves a last portion of items which are displayed on the page
             * @return {angular.element[]}
             */
            function getLastChunk() {
                var children = element.children();
                var child = angular.element(children[children.length - 1]);
                var offset = child.data('infinite-table-offset');

                var chunk = [];
                for (var i = children.length - 1; i >= 0; i--) {
                    if (!putToChunk(chunk, children[i], offset)) {
                        break;
                    }
                }
                return chunk;
            }
        }
    }
})();
