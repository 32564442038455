(function appControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('AppController', AppController);

    /**
     * Main application controller. It affects all pages in the application.
     */
    function AppController($state, $location, AuthService, ScrollPositionHolder) {
        var vm = this;

        vm.logout = logout;
        vm.goToMainPage = goToMainPage;

        activate();

        function activate() {
            vm.state = $state;
        }

        function logout() {
            AuthService.logout();
            $state.go('login');
            ScrollPositionHolder.setPosition('applicants_list', 0);
            ScrollPositionHolder.setData('applicants_list', undefined);
        }

        function goToMainPage() {
            $location.search('search', null);
            $location.search('extended', null);
            ScrollPositionHolder.setPosition('applicants_list', 0);
            ScrollPositionHolder.setData('applicants_list', undefined);
            $state.go('applicants_list', {}, { reload: true });
        }
    }
})();
