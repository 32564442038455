(function CommonInteractionsModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('CommonInteractionsModal', CommonInteractionsModal);

    /**
     * A service for displaying a modal window for creating or changing
     * interactions of any type. It is needed to prevent possible code duplication
     * and encapsulate parameters of the modal window.
     */
    function CommonInteractionsModal($uibModal, InteractionsApi) {
        this.open = function open(model, applicant, mainVM) {
            var template;
            var controller = 'CommonInteractionsModalController';

            switch (model.type) {
                case InteractionsApi.TYPE_CALL:
                    template = 'hrpro/app/applicants/modals/interactions/common-interaction-modal.html';
                    break;
                case InteractionsApi.TYPE_SELF_EDUCATION:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-self-education-modal.html';
                    break;
                case InteractionsApi.TYPE_IS_WORK:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-iswork-modal.html';
                    break;
                case InteractionsApi.TYPE_INFORMATION:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-information-modal.html';
                    break;
                case InteractionsApi.TYPE_FIRED:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-fired-modal.html';
                    break;
                case InteractionsApi.TYPE_REFUSE:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-refuse-modal.html';
                    break;
                case InteractionsApi.TYPE_TEST_TASK:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-test-task-modal.html';
                    controller = 'TestTaskInteractionModalController';
                    break;
                case InteractionsApi.TYPE_INTERVIEW:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-interview-modal.html';
                    controller = 'InterviewInteractionModalController';
                    break;
                case InteractionsApi.TYPE_STUDY:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-education-modal.html';
                    break;
                case InteractionsApi.TYPE_COMMUNICATION:
                    template = 'hrpro/app/applicants/modals/interactions/common-interaction-modal.html';
                    break;
                case InteractionsApi.TYPE_RESEARCH:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-research-modal.html';
                    break;
                case InteractionsApi.TYPE_PRIMARY_SCREENING:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-primary-screening-modal.html';
                    break;
                case InteractionsApi.TYPE_NOTE:
                    template = 'hrpro/app/applicants/modals/interactions/interaction-note-modal.html';
                    break;
                default:
                    throw new Error('Unknown interaction type: ' + model.type);
            }

            return $uibModal.open({
                templateUrl: template,
                controller: controller,
                controllerAs: 'modal',
                backdrop: 'static',
                resolve: {
                    model: function resolveModel() {
                        return model;
                    },
                    applicant: function resolveApplicant() {
                        return applicant;
                    },
                    mainVM: function resolveMainVM() {
                        return mainVM;
                    }
                },
                keyboard: false
            });
        };
    }
})();

