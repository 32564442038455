(function applicantSaveActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantSaveActionsController', ApplicantSaveActionsController);

    /**
     * A controller which handles save and remove operations for an applicant.
     */
    function ApplicantSaveActionsController($scope, ApplicantsApi, ConfirmDeletionModal, Notify, $state, $rootScope,
        ApplicantWebsocket, $q
    ) {
        var vm = this;
        var mainVM = null;
        var isLockedLocally = false;
        var modal = null;
        var unbindWsListener = null;
        var savePromise = null;
        var wasSaved = false;

        this.isSaveButtonEnabled = isSaveButtonEnabled;
        this.save = save;
        this.remove = remove;

        $scope.$on('$destroy', onDestroy);
        unbindWsListener = $rootScope.$on(ApplicantWebsocket.EVENT_MESSAGE, onWsMessage);

        activate();

        function activate() {
            mainVM = $scope.applicantVM;
        }

        function runWithLocalLock(callback, parameters) {
            isLockedLocally = false;

            if (!mainVM.isApplicantLocked()) {
                mainVM
                    .lockApplicant()
                    .then(function onLockSuccess() {
                        isLockedLocally = true;
                        callback.apply(null, parameters);
                    });
            } else {
                callback.apply(null, parameters);
            }
        }

        function dropLocalLock() {
            if (isLockedLocally) {
                mainVM.unlockApplicant();
            }
        }

        function isSaveButtonEnabled(disableSaved) {
            if ($scope.myForm.$pristine || savePromise !== null || (disableSaved && wasSaved)) {
                return false;
            } else {
                return true;
            }
        }

        function save(formModel) {
            savePromise = mainVM
                .save(formModel)
                .then(onSaveResolved, onSaveRejected);
        }

        function onSaveResolved(data) {
            savePromise = null;
            if (data) {
                wasSaved = true;
            }
            return data;
        }

        function onSaveRejected(data) {
            savePromise = null;
            return $q.reject(data);
        }

        function remove() {
            runWithLocalLock(beginRemove, arguments);
        }

        function beginRemove() {
            var msg = 'Вы уверены в том, что хотите удалить кандидата ' + mainVM.model.last_name + '?';
            modal = ConfirmDeletionModal.open(msg);
            modal.result.then(onDeletionConfirmed, onDeletionRejected);
            modal.closed.then(onModalClosed);
        }

        function onDeletionConfirmed() {
            ApplicantsApi
                .remove(mainVM.model.id)
                .then(onDeleteSuccess, onDeleteError);
        }

        function onDeletionRejected() {
            dropLocalLock();
        }

        function onDeleteSuccess(response) {
            dropLocalLock();
            Notify.success('Удалено', 'Карточка кандидата успешно удалена на сервере');
            $scope.myForm.$setPristine();
            $state.go('applicants_list');
        }

        function onDeleteError(response) {
            dropLocalLock();
            Notify.error('Ошибка', 'Карточку кандидата не удалось удалить на сервере');
        }

        function onDestroy() {
            unbindWsListener();
        }

        function onModalClosed() {
            modal = null;
        }

        function onWsMessage(event, message) {
            if (message.type !== ApplicantWebsocket.MESSAGE_DIE) {
                return;
            }

            if (modal !== null) {
                modal.dismiss('die');
            }
        }
    }
})();
