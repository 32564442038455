(function cvUploadModalServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('CvUploadModal', CvUploadModal);

    /**
     * A service for displaying a modal window for uploading a CV of an applicant.
     */
    function CvUploadModal($uibModal) {
        this.open = function open(applicant) {
            return $uibModal.open({
                templateUrl: 'hrpro/app/applicants/modals/cv-upload/upload-modal.html',
                controller: 'CvUploadModalController',
                controllerAs: 'modal',
                backdrop: 'static',
                keyboard: false
            }).result;
        };
    }
})();
