(function fileUploadApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('FileUploadApi', FileUploadApi);

    /**
     * Provides an ability to interact with files related endpoints of the API
     */
    function FileUploadApi(ApiHelper, Upload, $http) {
        this.uploadPhotoFile = uploadPhotoFile;
        this.uploadPhotoUrl = uploadPhotoUrl;
        this.removePhoto = removePhoto;
        this.uploadCV = uploadCV;
        this.removeCV = removeCV;

        /**
         * Sends a request to the Photo API endpoint in order to upload
         * a photo to the server
         * @param  {File} file a file that has to be uploaded
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function uploadPhotoFile(file, context) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('photo'),
                data: {
                    photo: file
                },
                context: context
            };
            return Upload.upload(config);
        }

        function uploadPhotoUrl(file, context) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('photo'),
                data: {
                    photoUrl: file
                },
                context: context
            };
            return Upload.upload(config);
        }

        /**
         * Sends a request to the Photo API endpoint in order to remove
         * a photo from the server
         * @param  {Integer} id a unigue identifier of the photo to remove
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function removePhoto(id, context) {
            var config = {
                method: 'delete',
                url: ApiHelper.buildUrl('photo/' + id),
                context: context
            };
            return $http(config);
        }

        /**
         * Sends a request to the Attachments API endpoint in order to upload
         * a CV to the server
         * @param  {File} file a file that has to be uploaded
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function uploadCV(file, context) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('attachments'),
                data: {
                    attachments: file
                },
                context: context
            };
            return Upload.upload(config);
        }

        /**
         * Sends a request to the Attachments API endpoint in order to remove
         * a CV from the server
         * @param  {Integer} id a unique identifier of the cv to remove
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function removeCV(id, context) {
            var config = {
                method: 'delete',
                url: ApiHelper.buildUrl('attachments/' + id),
                context: context
            };
            return $http(config);
        }
    }
})();
