(function clipboardSupportModalIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('ClipboardSupportModal', ClipboardSupportModal);

    /**
     * A service for displaying a modal window with a text that was not copied
     * correctly by script, so the user will be able to copy it manually. It is
     * needed to prevent possible code duplication and encapsulate parameters
     * of the modal window.
     */
    function ClipboardSupportModal($uibModal) {
        this.open = function open(text, context) {
            return $uibModal.open({
                templateUrl: 'hrpro/common/views/clipboard-support-modal.html',
                controller: 'ClipboardSupportModalController',
                controllerAs: 'modal',
                resolve: {
                    text: function resolveMessage() {
                        return text;
                    },
                    context: function resolveContext() {
                        return context;
                    }
                },
                windowClass: 'modal-warning',
                backdrop: 'static',
                keyboard: false
            }).result;
        };
    }
})();
