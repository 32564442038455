(function applicantCvActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantCvActionsController', ApplicantCvActionsController);

    /**
     * A controller which is responsible for uploading and removing a CV of
     * an applicant.
     */
    function ApplicantCvActionsController($scope, Notify, CvUploadModal, FileUploadApi, ConfirmDeletionModal,
        $rootScope, ApplicantWebsocket
    ) {
        var vm = this;
        var mainVM = null;
        var isLockedLocally = false;
        var modal = null;
        var unbindWsListener = null;

        vm.uploadCv = uploadCv;
        vm.removeCv = removeCv;

        activate();

        $scope.$on('$destroy', onDestroy);
        unbindWsListener = $rootScope.$on(ApplicantWebsocket.EVENT_MESSAGE, onWsMessage);

        function activate() {
            mainVM = $scope.applicantVM;
        }

        function runWithLocalLock(callback, parameters) {
            isLockedLocally = false;

            if (!mainVM.isApplicantLocked()) {
                mainVM
                    .lockApplicant()
                    .then(function onLockSuccess() {
                        isLockedLocally = true;
                        callback.apply(null, parameters);
                    });
            } else {
                callback.apply(null, parameters);
            }
        }

        function dropLocalLock() {
            if (isLockedLocally) {
                mainVM.unlockApplicant();
            }
        }

        function getApplicant() {
            return mainVM.model;
        }

        function invalidateForm() {
            $scope.myForm.$setDirty();
        }

        function uploadCv() {
            runWithLocalLock(beginUploadCv);
        }

        function beginUploadCv() {
            CvUploadModal
                .open()
                .then(onUploadSuccess, onUploadReject);
        }

        function onUploadSuccess(response) {
            var applicant = getApplicant();
            applicant.attachments = [ response.data ];

            invalidateForm();

            Notify.success('Загружено', 'Резюме кандидата успешно загружено на сервер');

            // We don't have to drop a local lock in this case because
            // CV uploading requires the user to click a save button
        }

        function onUploadReject() {
            dropLocalLock();
        }

        function removeCv() {
            runWithLocalLock(beginRemoveCv);
        }

        function beginRemoveCv() {
            modal = ConfirmDeletionModal.open('Вы уверены в том, что хотите удалить резюме?');
            modal.result.then(onDeletionConfirmed, onDeletionRejected);
            modal.closed.then(onModalClosed);
        }

        function onDeletionConfirmed() {
            var applicant = getApplicant();
            FileUploadApi
                .removeCV(applicant.attachments[0].id)
                .then(onDeleteSuccess, onDeleteError);
        }

        function onDeletionRejected() {
            dropLocalLock();
        }

        function onDeleteSuccess(response) {
            var applicant = getApplicant();

            // CV removes immediately, so we don't have to lock model when this
            // field changes
            mainVM.disableModelWatcher();
            applicant.attachments = [];
            mainVM.enableModelWatcher();

            Notify.success('Удалено', 'Резюме успешно удалено с сервера');
            dropLocalLock();
        }

        function onDeleteError(response) {
            Notify.error('Ошибка', 'Резюме не удалось удалить с сервера');
            dropLocalLock();
        }

        function onDestroy() {
            unbindWsListener();
        }

        function onModalClosed() {
            modal = null;
        }

        function onWsMessage(event, message) {
            if (message.type !== ApplicantWebsocket.MESSAGE_DIE) {
                return;
            }

            if (modal !== null) {
                modal.dismiss('die');
            }
        }
    }
})();
