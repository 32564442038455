(function vacanciesCreateControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('VacanciesCreateController', VacanciesCreateController);

    /**
     * A main controller on a new vacancy creation page
     */
    function VacanciesCreateController(VacanciesApi, Notify, $state) {
        var vm = this;

        vm.CURRENCY_CHOICE = VacanciesApi.CURRENCY_CHOICE;
        vm.EXPERIENCE_CHOICES = VacanciesApi.EXPERIENCE_CHOICES;
        vm.EDUCATION_CHOICES = VacanciesApi.EDUCATION_CHOICES;

        vm.template = 'hrpro/app/vacancies/template/common-form.html';
        vm.vacancy = null;

        vm.save = save;

        activate();

        function activate() {
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid) {
                Notify.warning('Ошибка', 'Перед сохранением вакансии исправьте ошибки');
                return;
            }
            VacanciesApi
                .save(vm.vacancy)
                .then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess() {
            Notify.success('Сохранено', 'Данные успешно сохранены на сервер');
            $state.go('vacancies_list');
        }

        function onSaveError() {
            Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
        }
    }
})();
