(function loginRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(routingConfig);

    function routingConfig($stateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'hrpro/app/login/login.html',
                data: {
                    pageTitle: 'Авторизация',
                    bodyClass: 'login-page'
                },
                controller: 'LoginController',
                controllerAs: 'loginVM'
            });
    }
})();
