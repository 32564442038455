(function funnelReportControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('FunnelReportController', FunnelReportController);

    function FunnelReportController(
        dateStart, dateEnd, data, vacancies, vacanciesFilter, currentUser, $state, dateFilter, MasterApi, FileSaver, ReportsApi) {
        var now = null;
        var vm = this;

        vm.model = null;
        vm.data = null;
        vm.dates = null;
        vm.vacancies = vacancies.data;
        vm.is_staff = currentUser.data[0].is_staff;
        vm.blocked = false;

        vm.build = build;
        vm.saveReport = saveReport;

        vm.headerConfig = {
            vacancy: 'Вакансия',
            positive_research: 'Ресерч',
            negative_research: 'Отказов после ресерча',
            positive_primary_screening: 'Первичный скрининг',
            negative_primary_screening: 'Не подходит нам после скрининга',
            test_task: 'Кол-во отправленных тестовых',
            test_task_done: 'Кол-во проверенных тестовых и пример кода',
            interview: 'Кол-во собеседований',
            refuse_company: 'Отказала компания',
            refuse_candidat: 'Отказался кандидат ',
            is_work: 'Кол-во принятых на работу',
            is_fired: 'Из них покинули команду в течение 3х месяцев',
        }

        activate();

        function activate() {
            now = new Date();

            vm.model = MasterApi.getModel();
            vm.data = data.data;

            vm.dates = {
                start: dateStart,
                end: dateEnd
            };
            vm.model.vacancies = vm.vacancies.filter(function(item) {
                return vacanciesFilter.includes(item.id.toString());
            });
        }

        function build() {
            var users = [];
            for (var i = 0; i < vm.model.vacancies.length; i++) {
                users.push(vm.model.vacancies[i].id);
            }

            $state.go('report_funnel', {
                start: dateFilter(vm.dates.start, 'yyyy-MM-dd'),
                end: dateFilter(vm.dates.end, 'yyyy-MM-dd'),
                vacancies: users.join(',')
            }, { reload: true });
        }

        function saveReport() {
            var headerTitles = {
                'vacancy_title': vm.headerConfig.vacancy,
                'positive_research_title': vm.headerConfig.positive_research,
                'negative_research_title': vm.headerConfig.negative_research,
                'positive_primary_screening_title': vm.headerConfig.positive_primary_screening,
                'negative_primary_screening_title': vm.headerConfig.negative_primary_screening,
                'test_task_title': vm.headerConfig.test_task,
                'test_task_done_title': vm.headerConfig.test_task_done,
                'interview_title': vm.headerConfig.interview,
                'refuse_company_title': vm.headerConfig.refuse_company,
                'refuse_candidat_title': vm.headerConfig.refuse_candidat,
                'is_work_title': vm.headerConfig.is_work,
                'is_fired_title': vm.headerConfig.is_fired,
            }
            vm.data.header_titles = headerTitles;
            ReportsApi.exportReport(null, ReportsApi.TYPE_EXPORT_FUNNEL, vm.data).success(function (data, status, headers) {
                var currentDate = new Date();
                var time = `${currentDate.getHours().toString()}_${currentDate.getMinutes().toString()}_${currentDate.getSeconds().toString()}`
                var filename = `${time}_funnel_report.csv`;
                var blobData = new Blob([data], { type: 'text/plain;charset=utf-8' });
                FileSaver.saveAs(blobData, filename);
            });
        }
    }
})();
