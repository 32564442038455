(function monthNameFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('monthName', monthName);

    /**
     * A filter to display a name of the date's month
     */
    function monthName(NAMES) {
        return function filter(input) {
            if (!input) {
                return '';
            }

            var date = angular.isString(input) ? new Date(input) : input;

            if (!angular.isDate(date)) {
                return '';
            }

            return NAMES.month[ date.getMonth() ];
        };
    }
})();
