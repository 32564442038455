(function focusTrackerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('focusTracker', focusTracker);

    function focusTracker($document) {
        var directive = {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        return directive;

        function link(scope, element, attrs, ngModel) {
            scope.$on('$destroy', onDestroy);
            element.on('focus', onFocus);
            element.on('blur', onBlur);

            ngModel.focussed = element[0] === $document.activeElement;

            function onDestroy() {
                element.off('focus', onFocus);
                element.off('blur', onBlur);
            }

            function onFocus() {
                ngModel.focussed = true;
            }

            function onBlur() {
                ngModel.focussed = false;
            }
        }
    }
})();
