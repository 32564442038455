(function applicantsCreateControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantsCreateController', ApplicantsCreateController);

    /**
     * A main controller of a new applicant creation page.
     */
    function ApplicantsCreateController(
        $scope, ApplicantsApi, $state, Notify, APPLICANT_TEMPLATES, tags, search_channels, $window, $timeout, VacanciesApi, ParserApi,
        $q
    ) {
        var vm = this;

        vm.model = null;
        vm.emails = null;
        vm.activeVacancy = true;
        vm.phones_masked = null;
        vm.phones_unmasked = null;
        vm.skypes = null;
        vm.templates = APPLICANT_TEMPLATES;
        vm.tags = tags.data.results;
        vm.search_channels = search_channels.data.results;
        vm.cvLoadingPromise = null;
        vm.work_url = '';

        vm.parseWorkUa = parseWorkUa;
        vm.isApplicantLocked = isApplicantLocked;
        vm.disableModelWatcher = angular.noop;
        vm.enableModelWatcher = angular.noop;
        vm.searchDuplicates = searchDuplicates;
        vm.save = save;

        activate();

        function activate() {
            vm.model = ApplicantsApi.getModel();
            vm.model.direction = 0;
            vm.emails = getContacts(ApplicantsApi.CONTACT_TYPE_EMAIL, 2);
            vm.phones_masked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE, 2);
            vm.phones_unmasked = getContacts(ApplicantsApi.CONTACT_TYPE_PHONE_FOREIGN, 1);
            vm.skypes = getContacts(ApplicantsApi.CONTACT_TYPE_SKYPE, 1);
            getVacancies();
        }

        function getContacts(type, amount) {
            var array = [];
            for (var i = 0; i < amount; i++) {
                array.push({ type: type, value: '' });
            }
            return array;
        }
        function getVacancies(context) {
            VacanciesApi
                .getListMini(context)
                .then(function success(response) {
                    vm.vacancies = response.data;
                });
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid || !vm.model.search_channels) {
                Notify.warning('Ошибка', 'Перед сохранением карточки кандидата исправьте ошибки');
                return;
            }

            vm.model.contacts = vm.emails.concat(vm.phones_masked, vm.phones_unmasked, vm.skypes);
            return ApplicantsApi
                .save(vm.model)
                .then(onSaveSuccess, onSaveError);
        }

        function onSaveSuccess(response) {
            Notify.success('Сохранено', 'Карточка кандидата успешно сохранена на сервере');
            $state.go('applicants_edit', { id: response.data.id });
        }

        function onSaveError(response) {
            Notify.error('Ошибка', 'Карточку кандидата не удалось сохранить на сервере');
        }

        function parseWorkUa() {
            ParserApi
                .gerWorkUa(vm.work_url)
                .then(onSuccessParse, onErrorParse);
        }

        function onSuccessParse(response) {
            if (response.data.error !== undefined) {
                Notify.error('Ошибка', 'Во время парсинга произошла ошибка. Проверьте введенную ссылку.');
                return;
            }

            var direction = vm.model.direction;
            vm.model = [];
            vm.model = response.data;
            vm.model.birthday = new Date(vm.model.birthday);
            vm.model.direction = direction;
            $scope.myForm.$setDirty(true);
        }

        function onErrorParse() {
            Notify.error('Ошибка', 'Во время парсинга произошла ошибка');
        }

        function searchDuplicates() {
            var name = vm.model.first_name || '';
            var surname = vm.model.last_name || '';
            var patronymic = vm.model.middle_name || '';
            var query = surname + ' ' + name + ' ' + patronymic;

            var criteria = {
                name: [query.trim()]
            };
            return ApplicantsApi
                .getList(undefined, undefined, undefined, criteria)
                .then(onSearchDuplicatesSuccess);
        }

        function onSearchDuplicatesSuccess(response) {
            return response.data.results;
        }

        function isApplicantLocked() {
            // A new applicant can only be locked by the current user
            return true;
        }
    }
})();
