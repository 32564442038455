(function dropdownScrollWatcherIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('dropdownScrollWatcher', dropdownScrollWatcher);

    function dropdownScrollWatcher() {
        var win = angular.element(window);

        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var topPosition = parseInt(attrs.topPosition);

            scope.$on('$destroy', onDestroy);
            win.on('scroll', fixPosition);

            function onDestroy() {
                win.off('scroll', fixPosition);
            }

            function fixPosition() {
                if (element.height() + topPosition <= win.height()) {
                    // Small panel in big window is always on the top
                    element.css('top', topPosition + win.scrollTop());
                } else if (element.offset().top - win.scrollTop() > topPosition) {
                    // Large panel in a small window scrolls up with a window but stops on the top
                    element.css('top', win.scrollTop() + topPosition);
                } else if (element.offset().top + element.outerHeight() < win.height() + win.scrollTop()) {
                    // Large panel in a small window scrolls down with a window but always remains visible
                    element.css('top', win.height() + win.scrollTop() - element.outerHeight());
                }
            }
        }

        function isSmallScreen() {
            return false;
        }
    }
})();
