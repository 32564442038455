(function fixedTableHeaderDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('fixedTableHeader', fixedTableHeader);

    function fixedTableHeader() {
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            var tableFixed;

            $(window).on('resize', resizeFixed);
            $(window).on('scroll', scrollFixed);

            scope.$on('$destroy', function onDestroy() {
                $(window).off('resize', resizeFixed);
                $(window).off('scroll', scrollFixed);
            });

            init();

            function init() {
                tableFixed = element.clone();
                tableFixed.find('tbody').remove();
                tableFixed.addClass('fixed');
                tableFixed.insertBefore(element);

                resizeFixed();
            }

            function resizeFixed() {
                tableFixed.find('th').each(function iterator(index) {
                    $(this).css('width', element.find('th').eq(index).outerWidth() + 'px');
                    $(this).css('min-width', element.find('th').eq(index).outerWidth() + 'px');
                });
            }

            function scrollFixed() {
                var offset = $(window).scrollTop(),
                    tableOffsetTop = element.offset().top - element.find('thead').height(),
                    tableOffsetBottom = tableOffsetTop + element.height() - element.find('thead').height();

                if (tableOffsetTop < 0) {
                    tableOffsetTop = 0;
                }

                if (offset <= tableOffsetTop || offset >= tableOffsetBottom) {
                    tableFixed.hide();
                } else if (offset >= tableOffsetTop && offset <= tableOffsetBottom && tableFixed.is(':hidden')) {
                    tableFixed.show();
                }

                resizeFixed();
            }
        }
    }
})();
