(function outdatedNotificationsReportRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(outdatedNotificationsReportRouting);

    function outdatedNotificationsReportRouting($stateProvider) {
        var DEFAULT_PERIOD = 7;

        $stateProvider
            .state('report_outdated_notifications', {
                parent: 'app',
                url: '/reports/outdated-notifications?start',
                templateUrl: '/hrpro/app/reports/outdated-notifications/index.html',
                data: {
                    pageTitle: 'Устаревшие напоминания',
                    bodyClass: 'reports'
                },
                resolve: {
                    dateStart: function resolveDateStart($stateParams) {
                        if ($stateParams.start) {
                            return new Date($stateParams.start);
                        } else {
                            var end = new Date();
                            end.setDate(end.getDate() - DEFAULT_PERIOD);
                            return end;
                        }
                    },
                    notifications: function resolveNotifications(dateStart, ReportsApi, Notify, dateFilter) {
                        var params = {
                            start: dateFilter(dateStart, 'yyyy-MM-dd')
                        };

                        var promise = ReportsApi.getReport(ReportsApi.TYPE_OUTDATED_NOTIFICATIONS, params);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить отчет');
                        }
                    }
                },
                controller: 'OutdatedNotificationsReportController',
                controllerAs: 'reportVM'
            });
    }
})();
