(function vacanciesApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('VacanciesApi', VacanciesApi);

    /**
     * Provides an ability to interact with the vacancies related endpoints
     * of the API
     */
    function VacanciesApi(ApiHelper, $http) {
        this.CURRENCY_CHOICE = [
            {id: 0, name: 'UAH'},
            {id: 1, name: 'USD'}
        ];

        this.EXPERIENCE_CHOICES = [
            {id: 0, name: 'Не имеет значения'},
            {id: 1, name: 'От 1 года'},
            {id: 2, name: 'От 2 лет'},
            {id: 3, name: 'От 5 лет'}
        ];

        this.EDUCATION_CHOICES = [
            {id: 0, name: 'Не имеет значения'},
            {id: 1, name: 'Среднее'},
            {id: 2, name: 'Cреднее специальное'},
            {id: 3, name: 'Неоконченное высшее'},
            {id: 4, name: 'Высшее'}
        ];

        this.getList = getList;
        this.getListMini = getListMini;
        this.create = create;
        this.save = save;
        this.remove = remove;
        this.getOne = getOne;

        function getList(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('vacancy'),
                context: context
            };
            return $http(config);
        }

        function getOne(id, context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('vacancy/' + id),
                context: context
            };
            return $http(config);
        }

        function getListMini(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('vacancy_mini'),
                context: context
            };
            return $http(config);
        }

        function create() {
        }

        function remove(id, context) {
            var config = {
                method: 'delete',
                url: ApiHelper.buildUrl('vacancy/' + id),
                context: context
            };
            return $http(config);
        }

        function save(data, context) {
            var url, method;

            if (data.id) {
                url = ApiHelper.buildUrl('vacancy/' + data.id);
                method = 'patch';
            } else {
                url = ApiHelper.buildUrl('vacancy');
                method = 'post';
            }
            var config = {
                method: method,
                url: url,
                context: context,
                data: data
            };

            return $http(config);
        }
    }
})();
