(function interactionsApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('InteractionsApi', InteractionsApi);

    /**
     * Provides an ability to interact with the interactions related endpoints
     * of the API
     */
    function InteractionsApi(ApiHelper, $http) {
        this.TYPE_INTERVIEW = 0;
        this.TYPE_STUDY = 1;
        this.TYPE_TEST_TASK = 2;
        this.TYPE_CALL = 3;
        this.TYPE_SELF_EDUCATION = 4;
        this.TYPE_IS_WORK = 5;
        this.TYPE_REFUSE = 6;
        this.TYPE_INFORMATION = 7;
        this.TYPE_FIRED = 8;
        this.TYPE_FEEDBACK = 9;
        this.TYPE_COMMUNICATION = 10;
        this.TYPE_RESEARCH = 11;
        this.TYPE_PRIMARY_SCREENING = 12;
        this.TYPE_NOTE = 13;

        this.getModel = getModel;
        this.remove = remove;
        this.save = save;

        function getModel(type) {
            return {
                type: type,
                applicant: 0
            };
        }

        function remove(id, context) {
            var config = {
                method: 'delete',
                url: ApiHelper.buildUrl('newinteractions/' + id),
                context: context
            };
            return $http(config);
        }

        function save(data, context) {
            var url, method;

            if (data.id) {
                url = ApiHelper.buildUrl('newinteractions/' + data.id);
                method = 'patch';
            } else {
                url = ApiHelper.buildUrl('newinteractions');
                method = 'post';
            }
            var config = {
                method: method,
                url: url,
                context: context,
                data: data
            };

            return $http(config).then(onSaveSuccess);
        }

        function onSaveSuccess(response) {
            var dates = ['date_create', 'date', 'date_start', 'date_end'];
            ApiHelper.massConversion(response.data, dates, ApiHelper.fromDateIso8601);
            return response;
        }
    }
})();

