(function testTaskInteractionModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('TestTaskInteractionModalController', TestTaskInteractionModalController);

    /**
     * A controller of the modal window which creates and changes Test task interactions.
     */
    function TestTaskInteractionModalController($uibModalInstance, model, applicant, mainVM, Notify,
        InteractionsApi, ApplicantHelper, $scope
    ) {
        var vm = this;

        vm.STATUS_TEST_TASK = ['Отправлено', 'На проверке', 'Проверено'];

        vm.vacancies = [];
        vm.masters = [];
        vm.requestPromise = null;
        vm.data = null;

        vm.close = closeModal;
        vm.save = save;

        activate();

        function activate() {
            mainVM.loadMasters().then(onMastersLoadSuccess);
            mainVM.loadVacancies().then(onVacanciesLoadSuccess);

            vm.data = model;

            if (angular.isObject(vm.data.master)) {
                vm.data.master = vm.data.master.id;
            }

            if (!vm.data.id) {
                vm.data.master = getLastSelectedMaster();
            }

            $uibModalInstance.rendered.then(onModalRendered);
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }

        function onMastersLoadSuccess(response) {
            var data = [];
            for (var i = 0; i < response.data.length; i++) {
                for (var j = 0; j < response.data[i].groups.length; j++) {
                    if (response.data[i].groups[j].name == 'Interviewer') {
                        data.push(response.data[i]);
                    };
                };
            };
            vm.masters = data;
        }

        function onVacanciesLoadSuccess(response) {
            vm.vacancies = response.data;
        }

        function getLastSelectedMaster() {
            var interactions = applicant.new_interactions.slice();
            ApplicantHelper.sortInteractionsByDate(interactions);

            for (var i = interactions.length - 1; i >= 0; i--) {
                if (interactions[i].type !== InteractionsApi.TYPE_TEST_TASK) {
                    continue;
                }

                if (interactions[i].status > 0) {
                    if (angular.isObject(interactions[i].master)) {
                        return interactions[i].master.id;
                    } else {
                        return interactions[i].master;
                    }
                }
            }

            return undefined;
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid) {
                Notify.warning('Ошибка', 'Перед сохранением карточки кандидата исправьте ошибки');
                return;
            }

            if (vm.data.status === '0') {
                // Sent test tasks don't have interviewers
                delete vm.data.master;
            }

            vm.requestPromise = InteractionsApi
                .save(vm.data)
                .then(onSuccess, onError);
        }

        function onSuccess(response) {
            vm.requestPromise = null;
            Notify.success('Сохранено', 'Взаимодействие успешно сохранено!');
            angular.merge(vm.data, response.data);
            $uibModalInstance.close(vm.data);
        }

        function onError(response) {
            vm.requestPromise = null;
            Notify.error('Ошибка', 'При попытке сохранения взаимодействия произошла ошибка');
        }

        function onModalRendered() {
            $scope.$broadcast('elastic:adjust');
        }
    }
})();
