(function moduleRoutingIIFE() {
    'use strict';

    angular.module('hrpro')
        .config(routingConfig);

    function routingConfig($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise(function defaultRouteConfig($injector, $location) {
            // A workaround to prevent an infine loop caused by preventing
            // a first unauthenticated transition
            // Issue: https://github.com/angular-ui/ui-router/issues/600
            var $state = $injector.get('$state');
            $state.go('applicants_list');
        });

        $stateProvider
            .state('app', {
                abstract: true,
                templateUrl: 'hrpro/common/views/app.html',
                resolve: {
                    currentUser: function resolveCurrentUser(CurrentUser) {
                        return CurrentUser.loadProfile();
                    }
                }
            });
    }
})();
