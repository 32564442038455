(function moduleConfigIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(httpErrorsHandlingConfig)
        .run(httpUnexpectedErrorsNotifier)
        .config(authConfig)
        .run(workflowControlConfig)
        .run(scrollPositionTrackerConfig)
        .run(authErrorHandlerConfig)
        .config(notificationsConfig)
        .config(datepickerConfig)
        .config(uiSelectOptionsConfig)
        .run(constantsPublisherConfig)
        .config(debugModeConfig);

    function httpErrorsHandlingConfig($httpProvider) {
        // These interceptors have to be mentioned in reverse order because of
        // execution order of response interceptors is reversed. So, the
        // errors fixer will be executed before the errors logger.
        //
        // Errors fixer is intended to normalize a rejection object which is
        // passed to response rejection handlers (it is possible to get an
        // object without a config property). This operation is needed in order
        // to prevent complaints of the loading bar module.
        //
        // Logger will log all HTTP-related errors to the localStorage and
        // provide access to them in console later.
        $httpProvider.interceptors.push('HttpErrorsLoggerInterceptor');
        $httpProvider.interceptors.push('HttpErrorsFixerInterceptor');
    }

    function httpUnexpectedErrorsNotifier($rootScope, Notify, HttpErrorsFixerInterceptor) {
        $rootScope.$on(HttpErrorsFixerInterceptor.ERR_UNEXPECTED, onError);

        function onError(event, error) {
            Notify.error('Непредвиденная ошибка',
                'При обращении к серверу произошла непредвиденная ошибка. ' +
                'Пожалуйста, свяжитесь с разработчиком.', {
                    timeOut: 0,
                    extendedTimeOut: 0
                }
            );
        }
    }

    function authConfig($httpProvider, AuthTokenInterceptorProvider) {
        $httpProvider.interceptors.push('AuthRequiredInterceptor');
        $httpProvider.interceptors.push('AuthTokenInterceptor');

        AuthTokenInterceptorProvider.setTokenAccessor('AuthTokenAccessor');
    }

    function uiSelectOptionsConfig(uiSelectConfig, $provide) {
        uiSelectConfig.theme = 'hrpro/common/views/ui-select';

        // Note: it is kinda temporary fix for memory leaking problem in
        // ui-select component. It is based of the following PR:
        // https://github.com/angular-ui/ui-select/pull/1365,
        // which is nether merged nor commented at the moment.
        // This fix has to be removed when memory leaking issue is solved in
        // this package.
        $provide.decorator('uiSelectChoicesDirective', function decorator($delegate) {
            var directive = $delegate[0];
            var originalCompileFn = directive.compile;

            directive.compile = function compile(tElement, tAttrs) {
                var originalLinkFn = originalCompileFn(tElement, tAttrs);

                return function link(scope, element, attrs, $select, transcludeFn) {
                    var choices = element.querySelectorAll('.ui-select-choices-row');

                    originalLinkFn(scope, element, attrs, $select, transcludeFn);

                    scope.$on('$destroy', onDestroy);

                    function onDestroy() {
                        choices.remove();
                    }
                };
            };

            return $delegate;
        });
    }

    function workflowControlConfig($state, $rootScope, AuthService, ReturnState, $uibModalStack) {
        $rootScope.$on('$stateChangeStart', onStateChangeStart);
        $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);
        $rootScope.$on('$stateChangeError', onStateChangeError);

        // Prevents a direct access to application if the user is not authenticated
        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            if (toState.name !== 'login' && toState.name !== 'register') {
                if (!AuthService.isAuthenticated()) {
                    event.preventDefault();
                    ReturnState.setState(toState, toParams);
                    $state.go('login');
                }
            }
        }

        // Close modal windows on state change
        function onStateChangeSuccess(event, toState, toParams, fromState, fromParams) {
            // $uibModalStack service is kinda internal and not listed in public
            // documentation, but it provides a nice way to close modal windows
            // externally when it is needed. It is the easies found way to achieve
            // such behavior.
            $uibModalStack.dismissAll('state change');
        }

        // Redirect users from incorrect urls to the main page
        function onStateChangeError(event, toState, toParams, fromState, fromParams, error) {
            if (fromState.name === '' && error.status === 404) {
                $state.go('applicants_list');
            }
        }
    }

    function scrollPositionTrackerConfig($rootScope, $timeout, $window, $state, ScrollPositionHolder) {
        $rootScope.$on('$stateChangeStart', onStateChangeStart);
        $rootScope.$on('$stateChangeSuccess', onStateChangeSuccess);

        function onStateChangeStart(event, toState, toParams, fromState, fromParams) {
            // We have to save a position of the scroll before the browser
            // begins navigation because Forward navigation causes page to
            // scroll to top

            // Note: it looks like the issue can be fixed with
            // history.scrollRestoration = 'manual', but this option is not
            // supported in all target browsers at the moment. See:
            // https://www.chromestatus.com/feature/5657284784947200
            // https://developer.mozilla.org/en-US/docs/Web/API/History
            var state = $state.current;
            if (fromState.data && fromState.data.saveScrollPosition && toState.name !== fromState.name) {
                ScrollPositionHolder.setPosition(fromState.name, $window.pageYOffset);
            }
        }

        function onStateChangeSuccess(event, toState, toParams, fromState, fromParams) {
            var position = ScrollPositionHolder.getPosition(toState.name);
            if (position === undefined) {
                // Scroll page to top if there is no scroll position saved
                $timeout(scrollToTop);
            } else {
                // If scroll position is saved, then target state's controller
                // has to restore it correctly itself
            }
        }

        function scrollToTop() {
            $window.scroll(0, 0);
        }
    }

    function authErrorHandlerConfig($rootScope, $state, AuthRequiredInterceptor) {
        var eventName = AuthRequiredInterceptor.EVENT_AUTH_REQUIRED;
        $rootScope.$on(eventName, function onAuthRequired() {
            $state.go('login');
        });
    }

    function notificationsConfig(toastrConfig) {
        angular.extend(toastrConfig, {
            closeButton: true,
            closeHtml: '<button>&times;</button>',
            extendedTimeOut: 2000,
            progressBar: true,
            timeOut: 5000,
            positionClass: 'toast-bottom-right'
        });
    }

    function datepickerConfig(uibDatepickerConfig, uibDatepickerPopupConfig, FORMATS, $provide) {
        uibDatepickerConfig.startingDay = 1;
        uibDatepickerConfig.templateUrl = 'hrpro/common/views/datepicker-calendar.html';

        uibDatepickerPopupConfig.datepickerPopup = FORMATS.date;
        uibDatepickerPopupConfig.datepickerTemplateUrl = 'hrpro/common/views/datepicker-calendar.html';
        uibDatepickerPopupConfig.showButtonBar = false;
        uibDatepickerPopupConfig.onOpenFocus = false;
        uibDatepickerPopupConfig.altInputFormats = ['yyyy'];
        uibDatepickerPopupConfig.placement = 'bottom-left';

        $provide.decorator('uibDatepickerDirective', function decorator($delegate, uibDatepickerConfig) {
            var directive = $delegate[0];

            directive.templateUrl = function templateUrl(element, attrs) {
                return attrs.templateUrl ||
                    uibDatepickerConfig.templateUrl ||
                    'uib/template/datepicker/datepicker.html';
            };

            return $delegate;
        });
    }

    function constantsPublisherConfig($rootScope, FORMATS, PATTERNS) {
        $rootScope.CONST = {
            FORMATS: FORMATS,
            PATTERNS: PATTERNS
        };
    }

    function debugModeConfig($compileProvider, DISABLE_DEBUG_INFO) {
        if (DISABLE_DEBUG_INFO) {
            $compileProvider.debugInfoEnabled(false);
        }
    }
})();
