(function currentYearDirectiveIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('currentYear', currentYear);

    function currentYear() {
        var directive = {
            restrict: 'A',
            replace: true,
            template: '<span ng-bind="::currentYear"></span>',
            link: function link(scope, element, attrs) {
                var currentDate = new Date();
                scope.currentYear = currentDate.getFullYear();
            }
        };

        return directive;
    }
})();
