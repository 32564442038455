(function vacanciesEditControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('VacanciesEditController', VacanciesEditController);

    /**
     * A main controller on a vacancy changing page
     */
    function VacanciesEditController(vacancy, VacanciesApi, $stateParams, Notify, ConfirmDeletionModal, $state) {
        var vm = this;

        vm.CURRENCY_CHOICE = VacanciesApi.CURRENCY_CHOICE;
        vm.EXPERIENCE_CHOICES = VacanciesApi.EXPERIENCE_CHOICES;
        vm.EDUCATION_CHOICES = VacanciesApi.EDUCATION_CHOICES;

        vm.template = 'hrpro/app/vacancies/template/common-form.html';
        vm.vacancy = null;

        vm.save = save;
        vm.remove = remove;

        activate();

        function activate() {
            vm.vacancy = vacancy.data;
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid) {
                Notify.warning('Ошибка', 'Перед сохранением вакансии исправьте ошибки');
                return;
            }
            VacanciesApi
                .save(vm.vacancy)
                .then(onSaveSuccess, onSaveError);
        }

        function remove() {
            ConfirmDeletionModal
                .open('Вы уверены в том, что хотите удалить вакансию?')
                .result
                .then(onDeletionConfirmed);
        }

        function onDeletionConfirmed() {
            VacanciesApi
                .remove($stateParams.id)
                .then(onDeleteSuccess, onDeleteError);
        }

        function onSaveSuccess() {
            Notify.success('Сохранено', 'Данные успешно сохранены на сервер');
            $state.go('vacancies_list');
        }

        function onSaveError() {
            Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
        }

        function onDeleteSuccess(response) {
            Notify.success('Удалено', 'Вакансия успешно удалена с сервера');
            $state.go('vacancies_list');
        }

        function onDeleteError(response) {
            Notify.error('Ошибка', 'Вакансию не удалось удалить с сервера');
        }
    }
})();
