(function testTasksListReportRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(testTasksListReportRouting);

    function testTasksListReportRouting($stateProvider) {
        var DEFAULT_PERIOD = 7;

        $stateProvider
            .state('report_test_tasks_list', {
                parent: 'app',
                url: '/reports/test-tasks-list?start&end',
                templateUrl: '/hrpro/app/reports/test-tasks-list/index.html',
                data: {
                    pageTitle: 'Тестовые задания',
                    bodyClass: 'reports'
                },
                resolve: {
                    dateStart: function resolveDateStart($stateParams) {
                        if ($stateParams.start) {
                            return new Date($stateParams.start);
                        } else {
                            var end = new Date();
                            if ($stateParams.end) {
                                end = new Date($stateParams.end);
                            }
                            end.setDate(end.getDate() - DEFAULT_PERIOD);
                            return end;
                        }
                    },
                    dateEnd: function resolveDateEnd($stateParams, dateStart) {
                        if ($stateParams.end) {
                            return new Date($stateParams.end);
                        } else {
                            var end = new Date();
                            if (dateStart > end) {
                                end = new Date(dateStart.getTime());
                                end.setDate(end.getDate() + DEFAULT_PERIOD);
                            }
                            return end;
                        }
                    },
                    data: function resolveData(dateStart, dateEnd, ReportsApi, Notify, dateFilter) {
                        var sessionStartDate = sessionStorage.getItem('startDate');
                        var sessionEndDate = sessionStorage.getItem('endDate');

                        var params = {
                            start: dateFilter(sessionStartDate ? new Date(sessionStartDate) : dateStart, 'yyyy-MM-dd'),
                            end: dateFilter(sessionEndDate ? new Date(sessionEndDate) : dateEnd, 'yyyy-MM-dd')
                        };

                        var promise = ReportsApi.getReport(ReportsApi.TYPE_TEST_TASK, params);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить отчет');
                        }
                    }
                },
                controller: 'TestTasksListReportController',
                controllerAs: 'reportVM'
            });
    }
})();
