(function confirmDeletionModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ConfirmDeletionModalController', ConfirmDeletionModalController);

    var TIMEOUT = 3;
    var TICK_DURATION = 1;

    /**
     * A controller of the modal which a request to confirm deletion operation
     */
    function ConfirmDeletionModalController($uibModalInstance, message, $scope) {
        var vm = this;

        vm.timeout = TIMEOUT;
        vm.message = message;

        vm.remove = function remove() {
            if (vm.timeout <= 0) {
                $uibModalInstance.close('remove');
            }
        };

        vm.cancel = function cancel() {
            $uibModalInstance.dismiss('cancel');
        };

        var timer = setInterval(onTimerTick, TICK_DURATION * 1000);

        function onTimerTick() {
            $scope.$apply(updateTimeout);
            if (vm.timeout <= 0) {
                clearInterval(timer);
            }
        }

        function updateTimeout() {
            vm.timeout -= TICK_DURATION;
        }
    }
})();
