(function funnelReportRoutingIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .config(funnelReportRouting);

    function funnelReportRouting($stateProvider) {
        var DEFAULT_PERIOD = 30;

        $stateProvider
            .state('report_funnel', {
                parent: 'app',
                url: '/reports/funnel?start&end&vacancies',
                templateUrl: '/hrpro/app/reports/funnel/index.html',
                data: {
                    pageTitle: 'Воронка',
                    bodyClass: 'reports'
                },
                resolve: {
                    dateStart: function resolveDateStart($stateParams) {
                        if ($stateParams.start) {
                            return new Date($stateParams.start);
                        } else {
                            var end = new Date();
                            if ($stateParams.end) {
                                end = new Date($stateParams.end);
                            }
                            end.setDate(end.getDate() - DEFAULT_PERIOD);
                            return end;
                        }
                    },
                    dateEnd: function resolveDateEnd($stateParams, dateStart) {
                        if ($stateParams.end) {
                            return new Date($stateParams.end);
                        } else {
                            var end = new Date();
                            if (dateStart > end) {
                                end = new Date(dateStart.getTime());
                                end.setDate(end.getDate() + DEFAULT_PERIOD);
                            }
                            return end;
                        }
                    },
                    vacanciesFilter: function resolveVacanciesFilter($stateParams){
                        if ($stateParams.vacancies) {
                            return $stateParams.vacancies.split(',');
                        }
                        return [];
                    },
                    vacancies: function resolveVacancies(VacanciesApi, Notify){
                        var promise = VacanciesApi.getList();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Не удалось загрузить список вакансий')
                        }
                    },
                    data: function resolveData($stateParams, dateStart, dateEnd, ReportsApi, Notify, dateFilter) {
                        var params = {
                            start: dateFilter(dateStart, 'yyyy-MM-dd'),
                            end: dateFilter(dateEnd, 'yyyy-MM-dd'),
                            vacancies: $stateParams.vacancies
                        };

                        var promise = ReportsApi.getReport(ReportsApi.TYPE_FUNNEL, params);
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Невозможно загрузить отчет');
                        }
                    },
                    currentUser: function resolveUser(MasterApi, Notify){
                        var promise = MasterApi.getMaster();
                        promise.then(undefined, onError);
                        return promise;

                        function onError(response) {
                            Notify.error('Ошибка', 'Не удалось загрузить текущего пользователя')
                        }
                    }, 
                },
                controller: 'FunnelReportController',
                controllerAs: 'reportVM'
            });
    }
})();
