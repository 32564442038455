(function trustAsResourceUrlFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('trustAsResourceUrl', trustAsResourceUrl);

    function trustAsResourceUrl($sce) {
        return function filter(input) {
            return $sce.trustAsResourceUrl(input);
        };
    }
})();
