(function CommonInteractionsModalControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('CommonInteractionsModalController', CommonInteractionsModalController);

    /**
     * A controller of modal windows for creating and changing interactions of almost any type.
     */
    function CommonInteractionsModalController(
        $uibModalInstance, Notify, $scope, InteractionsApi, model, applicant, mainVM
    ) {
        var vm = this;

        vm.STATUS_TEST_TASK = ['Отправлено', 'На проверке', 'Проверено'];
        vm.NAME_INTERACTION = [
            'Собеседование',
            'Принят на обучение',
            'Тестовое задание',
            'Звонок',
            'Самообучение',
            'Принят на работу',
            'Отказ',
            'Информация',
            'Уволен',
            'Feedback',
            'Коммуникация'
        ];
        vm.vacancies = [];
        vm.requestPromise = null;
        vm.data = null;
        vm.nameModal = '';

        vm.chosenStatus = null;

        vm.close = closeModal;
        vm.save = save;

        activate();

        function activate() {
            mainVM.loadVacancies().then(onVacanciesLoadSuccess);

            vm.nameModal = vm.NAME_INTERACTION[model.type];
            vm.data = model;
            if (vm.data.date) {
                vm.data.date = new Date(vm.data.date);
            }
            if (vm.data.date_start) {
                vm.data.date_start = new Date(vm.data.date_start);
            }
            if (vm.data.date_end) {
                vm.data.date_end = new Date(vm.data.date_end);
            }

            $uibModalInstance.rendered.then(onModalRendered);
        }

        function onVacanciesLoadSuccess(response) {
            vm.vacancies = response.data;
        }

        function save(formModel) {
            formModel.$setSubmitted();
            if (!formModel.$valid) {
                Notify.warning('Ошибка', 'Перед сохранением карточки кандидата исправьте ошибки');
                return;
            }
            vm.requestPromise = InteractionsApi
                .save(vm.data)
                .then(onSuccess, onError);
        }

        function onSuccess(response) {
            vm.requestPromise = null;
            Notify.success('Сохранено', 'Взаимодействие успешно сохранено!');
            angular.merge(vm.data, response.data);
            $uibModalInstance.close(vm.data);
        }

        function onError(response) {
            vm.requestPromise = null;
            Notify.error('Ошибка', 'При попытке сохранения взаимодействия произошла ошибка');
        }

        function closeModal() {
            $uibModalInstance.dismiss('cancel');
        }

        function onModalRendered() {
            $scope.$broadcast('elastic:adjust');
        }
    }
})();
