(function notIgnoredItemsFilterIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .filter('notIgnoredItems', notIgnoredItems);

    /**
     * A filter to remove from array all items with id, that is listed in a
     * given array of restricted items. However, if an id of an item is present
     * also in a list of allowed items, this item will not be removed from the
     * source array regardless of restrictions.
     */
    function notIgnoredItems() {
        return function filter(input, restrictedItems, allowedItems) {
            if (!angular.isArray(input)) {
                return input;
            }

            if (!restrictedItems) {
                restrictedItems = [];
            }

            if (!allowedItems) {
                allowedItems = [];
            } else if (!angular.isArray(allowedItems)) {
                allowedItems = [ allowedItems ];
            }
            allowedItems = allowedItems.map(idAccessor);

            var result = input.slice();
            for (var i = result.length - 1; i >= 0; i--) {
                if (restrictedItems.indexOf(result[i].id) !== -1 && allowedItems.indexOf(result[i].id) === -1) {
                    result.splice(i, 1);
                }
            }

            return result;
        };

        function idAccessor(item) {
            return item.id !== undefined ? item.id : item;
        }
    }
})();
