(function authApiServiceIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .service('AuthApi', AuthApi);

    /**
     * Provides an ability to interact with the authentication related endpoints
     * of the API
     */
    function AuthApi(ApiHelper, $http) {
        this.login = login;
        this.logout = logout;

        /**
         * Sends a request to the Login API endpoint
         * @param  {String} username username to send to endpoint
         * @param  {String} password password to send to endpoint
         * @param  {Object} context  optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function login(username, password, context) {
            var config = {
                method: 'post',
                url: ApiHelper.buildUrl('login'),
                data: {
                    username: username,
                    password: password
                },
                context: context
            };
            return $http(config);
        }

        /**
         * Sends a request to the Logout API endpoint
         * @param  {Object} context optional context object to pass to config
         * @return {Promise} a promise returned by $http service
         */
        function logout(context) {
            var config = {
                method: 'get',
                url: ApiHelper.buildUrl('logout'),
                context: context
            };
            return $http(config);
        }
    }
})();
