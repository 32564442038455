(function loginControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('LoginController', LoginController);

    /**
     * A controller to handle a login page
     */
    function LoginController(AuthService, $state, Notify, ReturnState) {
        var vm = this;

        vm.username = '';
        vm.password = '';
        vm.rememberMe = false;

        vm.login = login;

        activate();

        function activate() {
        }

        function login() {
            AuthService
                .login(vm.username, vm.password, vm.rememberMe)
                .then(onLoginSuccess, onLoginError);
        }

        function onLoginSuccess(response) {
            if (ReturnState.hasState()) {
                ReturnState
                    .restoreState()
                    .then(onReturnStateSuccess);
            } else {
                $state.go('applicants_list');
            }
        }

        function onReturnStateSuccess() {
            ReturnState.clearState();
        }

        function onLoginError(response) {
            if (response.status === 400) {
                Notify.warning('Ошибка', 'Невозможно войти с указанными логином и паролем');
            } else {
                Notify.error('Ошибка', 'При запросе на сервер произошла ошибка');
            }
        }
    }
})();
