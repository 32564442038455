(function visibleRowsTrackerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .directive('visibleRowsTracker', visibleRowsTracker);

    function visibleRowsTracker($window, $document, $timeout) {
        var win = angular.element($window);
        var directive = {
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element, attrs) {
            win.on('popstate', onPopState);
            $document.on('scroll', onScroll);
            scope.$on('$destroy', onDestroy);

            function onScroll(evt) {
                var rows = element.find('tr');
                var index = binarySearch(rows, undefined, rowVisibleComparator);
                scope.$emit('visible-rows-tracker:index-changed', index);
            }

            function onPopState(evt) {
                // We have to turn off a scrolling event listener because
                // the forward navigation causes a page to scroll to top.
                // This last scroll event is excess for the algorithm and
                // causes its incorrect work.
                //
                // Note: it looks like the issue can be fixed with
                // history.scrollRestoration = 'manual', but this option is not
                // supported in all target browsers at the moment. See:
                // https://www.chromestatus.com/feature/5657284784947200
                // https://developer.mozilla.org/en-US/docs/Web/API/History
                $document.off('scroll', onScroll);
            }

            function onDestroy() {
                win.off('popstate', onPopState);
                $document.off('scroll', onScroll);
            }
        }

        /**
         * Splits elements into 3 categories: rows which are above browser's
         * view port (-1), rows which are below browser's view port (1) and
         * rows which are in the browser's view port (0) at the moment.
         */
        function rowVisibleComparator(row, criteria) {
            var rect = row.getBoundingClientRect();
            if (rect.bottom < 0) {
                return -1;
            } else if (rect.top > $window.innerHeight) {
                return 1;
            }
            return 0;
        }
    }

    /**
     * Searches a first occurrence of element in an array. Original version of
     * the code is taken here:
     * http://www.dweebd.com/javascript/binary-search-an-array-in-javascript/
     * @param Array array an array to search an item in
     * @param any find an element to find in the array
     * @param function comparator a function to determine relations between
     *   elements. This function has to have the following signature
     *   function(item:any, criteria:any):int, where item is an element of the
     *   given array and criteria is a value which we are searching for. This
     *   function has to return a number < 0 in case the item is less then
     *   criteria, > 0 in case the item is greater then criteria and = 0 in
     *   case the item and criteria are identical
     * @return int an index of the element or -1 in case it cannot be found
     */
    function binarySearch(array, find, comparator) {
        var low = 0,
            high = array.length - 1,
            i, comparison;

        while (low <= high) {
            i = Math.floor((low + high) / 2);
            comparison = comparator(array[i], find);
            if (comparison < 0) {
                low = i + 1;
            } else if (comparison > 0) {
                high = i - 1;
            } else {
                return i;
            }
        }

        return -1;
    }
})();
