(function applicantPhotoActionsControllerIIFE() {
    'use strict';

    angular
        .module('hrpro')
        .controller('ApplicantPhotoActionsController', ApplicantPhotoActionsController);

    /**
     * A controller which is responsible for uploading and removing a photo of
     * an applicant.
     */
    function ApplicantPhotoActionsController($scope, Notify, PhotoUploadModal, FileUploadApi, ConfirmDeletionModal,
        $rootScope, ApplicantWebsocket
    ) {
        var vm = this;
        var mainVM = null;
        var isLockedLocally = false;
        var modal = null;
        var unbindWsListener = null;

        vm.uploadPhoto = uploadPhoto;
        vm.removePhoto = removePhoto;

        activate();

        $scope.$on('$destroy', onDestroy);
        unbindWsListener = $rootScope.$on(ApplicantWebsocket.EVENT_MESSAGE, onWsMessage);

        function activate() {
            mainVM = $scope.applicantVM;
        }

        function runWithLocalLock(callback, parameters) {
            isLockedLocally = false;

            if (!mainVM.isApplicantLocked()) {
                mainVM
                    .lockApplicant()
                    .then(function onLockSuccess() {
                        isLockedLocally = true;
                        callback.apply(null, parameters);
                    });
            } else {
                callback.apply(null, parameters);
            }
        }

        function dropLocalLock() {
            if (isLockedLocally) {
                mainVM.unlockApplicant();
            }
        }

        function getApplicant() {
            return mainVM.model;
        }

        function invalidateForm() {
            $scope.myForm.$setDirty();
        }

        function uploadPhoto() {
            runWithLocalLock(beginUploadPhoto);
        }

        function beginUploadPhoto() {
            PhotoUploadModal
                .open()
                .then(onUploadSuccess, onUploadReject);
        }

        function onUploadSuccess(response) {
            var applicant = getApplicant();
            applicant.photo = [ response.data ];

            invalidateForm();

            Notify.success('Загружено', 'Фото кандидата успешно загружено на сервер');

            // We don't have to drop a local lock in this case because
            // photo uploading requires the user to click a save button
        }

        function onUploadReject() {
            dropLocalLock();
        }

        function removePhoto() {
            runWithLocalLock(beginRemovePhoto);
        }

        function beginRemovePhoto() {
            modal = ConfirmDeletionModal.open('Вы уверены в том, что хотите удалить фото?');
            modal.result.then(onDeletionConfirmed, onDeletionRejected);
            modal.closed.then(onModalClosed);
        }

        function onDeletionConfirmed() {
            var applicant = getApplicant();
            FileUploadApi
                .removePhoto(applicant.photo[0].id)
                .then(onDeleteSuccess, onDeleteError);
        }

        function onDeletionRejected() {
            dropLocalLock();
        }

        function onDeleteSuccess(response) {
            var applicant = getApplicant();

            // Photo removes immediately, so we don't have to lock model when this
            // field changes
            mainVM.disableModelWatcher();
            applicant.photo = [];
            mainVM.enableModelWatcher();

            // invalidateForm();

            Notify.success('Удалено', 'Фото успешно удалено с сервера');
            dropLocalLock();
        }

        function onDeleteError(response) {
            Notify.error('Ошибка', 'Фото не удалось удалить с сервера');
            dropLocalLock();
        }

        function onDestroy() {
            unbindWsListener();
        }

        function onModalClosed() {
            modal = null;
        }

        function onWsMessage(event, message) {
            if (message.type !== ApplicantWebsocket.MESSAGE_DIE) {
                return;
            }

            if (modal !== null) {
                modal.dismiss('die');
            }
        }
    }
})();
